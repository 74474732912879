import React, { useState, useContext } from "react"
import { navigate } from "@reach/router"
import Helmet from "react-helmet"
import FormData from "form-data"
import { format, set } from "date-fns"
import "react-day-picker/dist/style.css"
import { useBeforeUnload } from "react-use"

import { saleKeys } from "services/constants"
import { getStockTakes } from "services/stock-take/stock-take"
import { convertSalesFile, createSales } from "services/sales"
import { convertToTimeZoneUTCString, isNumber } from "services/helpers"
import { showError } from "services/toast"

import Header from "components/dashboard/Header/Header"
import EditableImportForm from "components/common/EditableImportForm/EditableImportForm"
import EditableImportHeader from "../../common/EditableImportHeader/EditableImportHeader"
import EditableImportTable from "components/common/EditableImportTable/EditableImportTable"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import { AsideContext } from "context/aside/AsideContext"
import { ModalContext } from "context/modal/ModalContext"

//@ts-ignore
import * as styles from "./ImportSales.module.css"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

const storedKeysStatic = {}
const storedRequiredKeysStatic = {}
saleKeys.forEach((itm: any) => {
  storedKeysStatic[itm.value] = ""
  if (itm.required) {
    storedRequiredKeysStatic[itm.value] = ""
  }
})

const ImportSales = () => {
  const [salesData, setSalesData] = useState([])
  const [hiddenIndexes, setHiddenIndexes] = useState({})
  const [storedKeys, setStoredKeys] = useState(storedKeysStatic)
  const [stockDates, setStockDates] = useState<Date[]>([])
  const [stockLabels, setStockLabels] = useState([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [removeFirstRow, setRemoveFirstRow] = useState(false)
  const [formattingHasError, setFormattingHasError] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  })
  const permissionObj = usePermissions("Sales") as Permission

  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)
  const { organization }: { organization: any } = useContext(GlobalStateContext)

  useBeforeUnload(isUploading || salesData.length > 0, "Are you sure?")

  // KEYS

  const emptyStoredKeys = Object.keys(storedKeys).filter(
    (key) => !storedKeys[key].length
  )

  const reversedStoredKeys = {}
  Object.keys(storedKeys).forEach((key) => {
    if (storedKeys[key] !== "") {
      reversedStoredKeys[storedKeys[key]] = key
    }
  })

  // HANDLERS

  const uploadFile = async (ev) => {
    const formData = new FormData()
    setIsUploading(true)
    formData.append("file", ev.currentTarget.files[0])
    const uploadResult = await convertSalesFile(formData)

    setIsUploading(false)

    if (uploadResult.status === 400) {
      showError(uploadResult.message, { closeOnClick: true, autoClose: false })
    } else {
      if (Array.isArray(uploadResult.rows)) {
        setSalesData(uploadResult.rows)
      }
    }
  }

  const onImport = (data) => {
    modal.showModal(ConfirmModal, {
      title: "Import sales",
      text: `You’re about to import ${data.length} sales. Do you want to proceed?`,
      confirmButtonText: "Import",
      onConfirm: () => handleImport(data),
    })
  }

  const getStockTakeDatesAndLabels = async () => {
    const request = await getStockTakes({
      status: "COMPLETED",
      sort: "createdAt,desc",
      size: 99,
    })

    const newStockDates = request.content.map(
      (stockItem) => new Date(stockItem.stockTakeReport.completedAt)
    )

    const newStockLabels = request.content.map((stockItem) => {
      return {
        label: stockItem.stockTakeReport.name,
        value: new Date(stockItem.stockTakeReport.completedAt),
      }
    })

    setStockDates(newStockDates)
    setStockLabels(newStockLabels)

    return { newStockDates, newStockLabels }
  }

  const handleImport = async (data) => {
    if (!selectedPeriod.from || !selectedPeriod.to) {
      return
    }

    const dateFrom = set(selectedPeriod.from, {
      hours: 0,
      minutes: 0,
    })
    const dateTo = set(selectedPeriod.to, {
      hours: 23,
      minutes: 59,
      seconds: 59,
    })

    const saleRecord = await createSales({
      from: convertToTimeZoneUTCString(dateFrom, organization?.address?.zoneId),
      to: convertToTimeZoneUTCString(dateTo, organization?.address?.zoneId),
      items: data.map((data) => {
        const newItem = {} as any
        Object.keys(storedKeys).map((key) => {
          if (key === "soldQty") {
            newItem[key] = Number(data[storedKeys[key]])
          } else if (key === "totalValue") {
            newItem[key] = Number(
              // eslint-disable-next-line no-useless-escape
              data[storedKeys[key]].replace(/[^0-9\.-]+/g, "")
            )
          } else {
            newItem[key] = data[storedKeys[key]]
          }
        })

        if (!newItem.hasOwnProperty("totalValue")) {
          //Assigns value of 0 to totalValue if not provided
          newItem.totalValue = 0
        } else if (!isNumber(newItem.totalValue)) {
          //Assigns value of 0 to totalValue if provided value is not a number
          newItem.totalValue = 0
        }

        if (!newItem.hasOwnProperty("soldQty")) {
          //Assigns value of 0 to soldQty if not provided
          newItem.soldQty = 0
        } else if (!isNumber(newItem.soldQty)) {
          //Assigns value of 0 to soldQty if provided value is not a number
          newItem.soldQty = 0
        }

        return newItem
      }),
    })

    if (saleRecord.status === 400) {
      showError(saleRecord.message, {
        closeOnClick: true,
        autoClose: false,
      })
    } else if (saleRecord.status >= 300) {
      showError(
        "Importing your sales data can take some time due to the high volume of data. You can leave this screen now and come back to it in the next few minutes.",
        {
          closeOnClick: true,
          autoClose: false,
        }
      )
    } else {
      navigate("/dashboard/sales")
    }
  }

  const toggleDatePicker = async () => {
    let selectedStockDates = stockDates
    let selectedStockLabels = stockLabels
    // @ts-ignore
    if (!selectedStockDates.length > 0) {
      const { newStockDates, newStockLabels } =
        await getStockTakeDatesAndLabels()
      selectedStockDates = newStockDates
      selectedStockLabels = newStockLabels
    }
    // @ts-ignore
    aside.showAside(AsideCalendar, {
      headerText: "Select a period",
      selectedPeriod,
      highlightDays: selectedStockDates,
      highlightDaysLabels: selectedStockLabels,
      onConfirm: (data) => {
        setSelectedPeriod(data)
        aside.hideAside()
      },
    })
  }

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  const verifyField = (key, item = "") => {
    // Verify the field to make sure there's no errors
    let error = ""

    if (reversedStoredKeys[key]) {
      if (
        item.length < 1 &&
        storedRequiredKeysStatic.hasOwnProperty(reversedStoredKeys[key])
      ) {
        error = "Field is required"
      }

      if (
        reversedStoredKeys[key] === "soldQty" &&
        !isNumber(item) &&
        item.length > 0
      ) {
        error = "Field is not a number"
      }
    }

    return error
  }

  return (
    <>
      <Helmet>
        <title>Import Sales</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Import Sales" />

        <div className={styles.content}>
          {salesData.length < 1 && (
            <EditableImportForm
              isUploading={isUploading}
              disabled={!permissionObj?.permissions.modify}
              onUpload={uploadFile}
              infoText={
                <div>
                  <p className="text-sm text-gray-700 my-6">
                    Upload information about your sales:
                  </p>
                  <ul className="text-sm text-gray-700 mb-6 list-inside list-disc">
                    <li>Product Name*</li>
                    <li>POS ID*</li>
                    <li>Sales Value*</li>
                    <li>Quantity Sold*</li>
                  </ul>
                  <p className="text-sm text-gray-700 my-6 italic">
                    Note: Only items with set up POS IDs will be imported and
                    reflected into relevant reports ie stock discrepancy report.
                    The file imported needs to be an{" "}
                    <strong>itemised summary sales report</strong> for the
                    selected period between two finalised stocktakes providing
                    total quantities of items sold. Example: Item name: Coke;
                    POS ID: 12346; Quantity sold: 50; Sales value: 100.
                  </p>
                </div>
              }
            />
          )}

          {salesData.length > 0 && (
            <div className="flex flex-col w-full h-full">
              <EditableImportHeader
                setImportData={setSalesData}
                keys={saleKeys}
                storedKeysStatic={storedKeysStatic}
                setStoredKeys={setStoredKeys}
                emptyStoredKeys={emptyStoredKeys}
                setHiddenIndexes={setHiddenIndexes}
                injectMiddle={
                  <>
                    <div className="mb-4 xl:flex flex-col">
                      <div className="xl:mr-4 xl:w-3/5">
                        <h5 className="mt-1 mb-1 text-gray-800 text-lg">
                          Please select relevant period
                        </h5>
                        <p className="text-sm text-gray-700 mb-4">
                          Select the stocktake period you want to upload the
                          sales for
                        </p>
                      </div>
                      <div className="flex flex-row relative w-full md:w-10/12">
                        <div
                          className={`${styles.dateField} input-container mr-4`}
                        >
                          <input
                            type="text"
                            value={formatDate(selectedPeriod.from) || ""}
                            className="cursor-pointer form-control rounded"
                            placeholder="From"
                            readOnly
                            onClick={toggleDatePicker}
                          />
                        </div>
                        <div
                          className={`${styles.dateField} input-container mr-2`}
                        >
                          <input
                            type="text"
                            className="cursor-pointer form-control rounded"
                            value={formatDate(selectedPeriod.to) || ""}
                            placeholder="To"
                            readOnly
                            onClick={toggleDatePicker}
                          />
                        </div>
                      </div>
                    </div>
                    {(!selectedPeriod.from || !selectedPeriod.to) && (
                      <p className="text-sm text-red-700 mb-4">
                        * Select a time period between two finalised stocktakes
                      </p>
                    )}
                  </>
                }
                removeFirstRow={removeFirstRow}
                setRemoveFirstRow={setRemoveFirstRow}
                formattingHasError={formattingHasError}
              />

              <EditableImportTable
                type="sale"
                verifyField={verifyField}
                onImport={onImport}
                importData={salesData}
                setImportData={setSalesData}
                keys={saleKeys}
                storedKeys={storedKeys}
                setStoredKeys={setStoredKeys}
                emptyStoredKeys={emptyStoredKeys}
                reversedStoredKeys={reversedStoredKeys}
                hiddenIndexes={hiddenIndexes}
                setHiddenIndexes={setHiddenIndexes}
                customDisableImportCondition={
                  emptyStoredKeys.length > 0 ||
                  !selectedPeriod.to ||
                  !selectedPeriod.from
                }
                removeFirstRow={removeFirstRow}
                formattingHasError={formattingHasError}
                setFormattingHasError={setFormattingHasError}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ImportSales
