import React, { useCallback, useContext, useState } from "react"
import Modal from "react-modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import * as styles from "./ConfirmModalStocktake.module.css"
import { StockTakeReport } from "services/stock-take/types"
import DateSelection from "components/forms/DateSelection"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

import {
  formatDateAsTime,
  convertToTimeZoneDate,
  convertToTimeZoneUTCString,
} from "services/helpers"
import { set } from "date-fns"

Modal.setAppElement("body")

interface ConfirmModalStocktakeProps {
  onRequestClose: () => void
  onConfirmDate: (completedDate: any) => void
  stocktake: StockTakeReport
}

const ConfirmModalStocktake = ({
  onRequestClose,
  onConfirmDate,
  stocktake,
}: ConfirmModalStocktakeProps) => {
  const { organization }: { organization: any } = useContext(GlobalStateContext)

  const [date, setDate] = useState<string | Date | undefined>(
    stocktake.createdAt
      ? new Date(
          convertToTimeZoneDate(
            stocktake.createdAt,
            organization.address.zoneId
          )
        )
      : ""
  )

  const [time, setTime] = useState<string>(
    stocktake.createdAt
      ? formatDateAsTime(
          convertToTimeZoneDate(
            stocktake.createdAt,
            organization.address.zoneId
          )
        )
      : ""
  )

  const confirm = useCallback(() => {
    onRequestClose()

    if (date && time) {
      const [hours, minutes] = time.split(":").map(Number) // "12:45" -> [12, 45]

      const convertedDateAndTime = convertToTimeZoneUTCString(
        set(new Date(date), {
          hours: hours,
          minutes: minutes,
          seconds: 0,
          milliseconds: 0,
        }),
        organization.address.zoneId
      )

      onConfirmDate(convertedDateAndTime)
    }
  }, [onRequestClose, onConfirmDate, date, time])

  return (
    <Modal isOpen onRequestClose={onRequestClose} portalClassName="confirm">
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className="p-6">
        <div className="text-center mb-3 px-2 flex flex-col items-center">
          <h1 className="text-xl sm:text-2xl mb-4 mt-3">{`Finalise ${
            stocktake.name || "Stocktake"
          }`}</h1>
          <p className="text-lg text-gray-700 mb-4">{`Finalising the stocktake will combine all areas into one single stock count report`}</p>
          <DateSelection
            value={{ from: date, to: undefined, enteredTo: undefined }}
            onChange={(date) => {
              setDate(date.from)
            }}
            timeValue={{ from: time }}
            onChangeTime={(newTime) => setTime(newTime.from)}
            mode="single"
            disable={{ after: new Date() }}
          />
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            onClick={onRequestClose}
            className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={confirm}
            className="button button--autoWidth button--primaryGreen"
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModalStocktake
