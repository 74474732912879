import React, { useState, useContext, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faFilePdf,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import {
  extractDeliveryNoteDataFromImg,
  extractDeliveryNoteDataFromPdf,
  cancelRequest,
  operatePdf,
  createPdf,
} from "services/file-processing"
import { useInterval } from "react-use"
import {
  addDeliveryNote,
  updateDeliveryNote,
} from "services/delivery-notes/delivery-notes"
import { showError, showSuccess } from "services/toast"
import { uuidv4 } from "services/helpers"
import ConfirmDetails from "components/delivery-notes/ConfirmDetails/ConfirmDetails"
import OrderSelect from "components/orders/OrderSelect/OrdersSelect"
import Loader from "components/common/Loader/Loader"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"
import DocumentCamera from "components/common/DocumentCamera/DocumentCamera"
import DocumentCameraFiles from "components/common/DocumentCameraFiles/DocumentCameraFiles"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

//@ts-ignore
import * as styles from "./DeliveryNote.module.css"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

const cx = classNames.bind(styles)

const deliveredProduct = {
  barcode: "",
  comment: null,
  dnQty: null,
  measure: null,
  category: null,
  subCategory: null,
  unit: null,
  isConfirmed: false,
  orderCaseSize: null,
  orderQtyInCase: null,
  code: null,
  name: "",
  orderQty: null,
  price: null,
  productDiscrepancies: null,
  receivedQty: null,
  receivedQtyInCase: null,
  size: null,
}

const DeliveryNote = ({ onRequestClose, onUpdate, ...otherProps }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const { user, newDeliveryNote } = useContext(GlobalStateContext)
  const [loading, setLoading] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<Array<any>>([])
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [isPolling, setPolling] = useState(false)
  const [extractedFile, setExtractedFile] = useState(null) as any | null
  const [jobId, setJobId] = useState(null)
  const [cameraEnabled, setCameraEnabled] = useState(true)
  const permissionObj = usePermissions("Delivery notes") as Permission

  const cameraDemoUsers = ["hi@rubenkuipers.design", "kati@growyze.com"]
  const scanningEnabled = false

  const [currentStep, setCurrentStep] = useState(
    cameraDemoUsers.includes(user.username) ? 1 : 3
  )

  const onImageCapture = useCallback((dataUri, originalFile) => {
    const newFiles = [
      ...selectedFiles,
      {
        dataUri: dataUri,
        file: originalFile,
        selected: true,
      },
    ]
    setSelectedFiles([...newFiles])

    // If pdf - process this file directly (single upload)
    if (originalFile.type === "application/pdf") {
      setConfirmed(true)
      return uploadFiles(newFiles)
    }
  }, [])

  const uploadFiles = useCallback(async (files) => {
    setCurrentStep(2)
    setLoading(true)

    const list = files.filter((file) => file.selected)
    const results = [] as Array<any>

    for (let i = 0; i < list.length; i++) {
      const formData = new FormData()
      const params = {
        client: "delivery-note",
      } as any
      const currentFile = list[i].file
      const type = currentFile.type
      const isLast = i === list.length - 1
      formData.append("file", currentFile)

      if (type !== "application/pdf" && isLast) {
        params.completed = true
      }

      // Add previous file ID if exists
      if (list.length > 1 && i > 0 && type !== "application/pdf") {
        params.fileId = results[i - 1].fileId
      }

      const result =
        type === "application/pdf"
          ? await operatePdf(formData, params) // if pdf operate on pdf directly
          : list.length > 1 // only create pdf for more than 1 img file
          ? await createPdf(formData, params) // create pdf
          : await extractDeliveryNoteDataFromImg(
              formData,
              newDeliveryNote.supplier.name
            ) // extract from only img directly

      if (result) {
        results.push(result)
      }

      if (result && isLast) {
        // If is a job, first get job result, otherwise parse result directly
        if (result.jobId) {
          setJobId(result.jobId)

          if (result.fileId) {
            setExtractedFile(result)
          }

          setPolling(true)
          return
        } else {
          setExtractedFile(result ? result : null)
          return parseResult(result)
        }
      }
    }
  }, [])

  const confirmSupplier = async () => {
    dispatch({
      type: "UPDATE_NEW_DELIVERY_NOTE",
      payload: {
        supplier: selectedSupplier,
      },
    })
    // setSelectDelivery(true)
  }

  const handleSupplierSelect = (supplier) => {
    setSelectedSupplier(supplier)
  }

  const parseResult = (result) => {
    if (result && !result.error && result.status !== 400) {
      if (!result.items || result.items.length === 0) {
        showError("No items found on delivery note")
        return resetState()
      }

      const DNParams = {
        ...newDeliveryNote,
        po: result.poNumber,
        extractedFile: extractedFile
          ? {
              fileId: extractedFile.fileId,
              fileName: extractedFile.fileName,
            }
          : result.fileId
          ? { fileId: result.fileId, fileName: result.fileName }
          : null,
        files: result.files,
        selectedFiles: selectedFiles,
        deliveryDate: result.deliveryDate || new Date().toISOString(),
        deliveryNoteNumber: result.deliveryNoteNumber,
        hasDNQtyDiscrepancies: result.hasDNQtyDiscrepancies,
        hasReceivedQtyDiscrepancies: result.hasReceivedQtyDiscrepancies,
        hasReceivedOrderQtyDiscrepancies:
          result.hasReceivedOrderQtyDiscrepancies,
        hasNoOrderedProducts: result.hasNoOrderedProducts,
        hasNoDeliveredProducts: result.hasNoDeliveredProducts,
        status: "DRAFT",
        products: result.items
          ? result.items.map((item) => {
              return {
                ...deliveredProduct,
                name: item.desc,
                price: item.price,
                receivedQty: item.qty,
                dnQty: item.qty,
              }
            })
          : [],
      }
      saveDeliveryNote(DNParams)
      setLoading(false)
      setCurrentStep(3)
    } else if (result && result.message) {
      resetState()
      return showError(result.message)
    }
  }

  /**
   * Interval that resolves when extraction job is finished
   *
   * @return  {Function}  Calls handleResult
   */
  useInterval(
    async () => {
      const result = await extractDeliveryNoteDataFromPdf(
        jobId,
        newDeliveryNote.supplier.name
      )
      if (result.status === "SUCCEEDED") {
        setPolling(false)
        setJobId(null)
        return parseResult(result)
      }
    },
    isPolling ? 2000 : null
  )

  const saveDeliveryNote = async (params) => {
    setLoading(true)

    const saveResult = newDeliveryNote.id
      ? await updateDeliveryNote(newDeliveryNote.id, params)
      : await addDeliveryNote(params)

    if (saveResult && saveResult.id && !saveResult.error) {
      const {
        dateOfScanning,
        deliveryNoteNumber,
        deliveryDate,
        globalDiscrepancies,
        hasNoDeliveredProducts,
        extractedFile,
        files,
        hasNoOrderedProducts,
        hasDNQtyDiscrepancies,
        hasReceivedQtyDiscrepancies,
        hasReceivedOrderQtyDiscrepancies,
        products,
        isCreatedManuallyWithoutOrder,
        message,
        supplier,
        status,
        po,
        id,
      } = saveResult

      dispatch({
        type: "UPDATE_NEW_DELIVERY_NOTE",
        payload: {
          supplier,
          dateOfScanning,
          hasNoDeliveredProducts,
          extractedFile,
          files,
          hasNoOrderedProducts,
          hasDNQtyDiscrepancies,
          hasReceivedQtyDiscrepancies,
          hasReceivedOrderQtyDiscrepancies,
          deliveryNoteNumber,
          deliveryDate,
          globalDiscrepancies,
          products,
          isCreatedManuallyWithoutOrder,
          message,
          status,
          po,
          id,
        },
      })
      setLoading(false)
      setCurrentStep(3)
      if (onUpdate) {
        onUpdate()
      }
    } else if (saveResult) {
      if (saveResult.status === 404 || saveResult.status === 400) {
        setLoading(false)
        setCurrentStep(3)
        showError(
          saveResult.errors
            ? saveResult.errors[0].defaultMessage
            : saveResult.message
            ? saveResult.message
            : "Something went wrong"
        )
        return
      }
      if (saveResult.errors && saveResult.errors.length > 0) {
        showError(
          saveResult.errors
            ? saveResult.errors[0].defaultMessage
            : "Something went wrong"
        )
      } else {
        showError(saveResult.message || "Something went wrong")
      }
    }
  }

  const handleOrderSelect = async (order) => {
    setCameraEnabled(false)
    dispatch({
      type: "RESET_NEW_DELIVERY_NOTE",
      options: { resetSupplier: false },
    })

    setLoading(true)

    const DNParams = {
      ...newDeliveryNote,
      po: order.po,
      extractedFile: null,
      deliveryDate: new Date().toISOString(),
      deliveryNoteNumber: null,
      status: "DRAFT",
      supplier: {
        id: order.supplier.id,
        name: order.supplier.name,
      },
      products: order.items
        ? order.items.map((item, index) => {
            return {
              ...deliveredProduct,
              uuid: uuidv4(),
              originalIndex: index,
              name: item.name,
              price: item.orderInCase ? item.productCase.price : item.price,
              orderQty: item.quantity,
              dnQty: item.quantity,
              receivedQty: item.quantity,
              orderQtyInCase: item.orderInCase,
              receivedQtyInCase: item.orderInCase,
            }
          })
        : [],
    }

    saveDeliveryNote(DNParams)
  }

  const toggleFile = (file, index) => {
    setSelectedFiles([
      ...selectedFiles.map((fileItem, fileIndex) => {
        if (index === fileIndex) {
          return {
            ...fileItem,
            selected: !fileItem.selected,
          }
        } else {
          return fileItem
        }
      }),
    ])
  }

  const resetState = () => {
    setLoading(false)
    setPolling(false)
    setJobId(null)
    setExtractedFile(null)
    setSelectedFiles([])
    setCurrentStep(1)
    setConfirmed(false)
    setCameraEnabled(true)
  }

  /**
   * {RK: 01-06-21 - TEMPORARY:
   * Only support adding manually
   *
   * {RK: 28-10-21 - TEMPORARY:}
   * Show scanning feature only for demo users
   *
   * @return  {[type]}  [return description]
   */
  let content = (
    <div className="bg-black w-full h-full flex items-center justify-center">
      {cameraDemoUsers.indexOf(user.username) > -1 || scanningEnabled ? (
        <>
          <DocumentCameraFiles
            selectedFiles={selectedFiles}
            onConfirm={() => {
              setConfirmed(true)
              uploadFiles(selectedFiles)
            }}
            onSelectToggle={toggleFile}
          />
          <DocumentCamera
            onCapture={onImageCapture}
            accept={`image/jpeg,image/png,image/jpg${
              !selectedFiles.length ? ",application/pdf" : ""
            }`}
            blur={currentStep === 3 || currentStep === 4}
            onAddManually={() => {
              dispatch({
                type: "RESET_NEW_DELIVERY_NOTE",
                options: { resetSupplier: false },
              })
              setSelectedFiles([])
              setCameraEnabled(false)
              setCurrentStep(3)
            }}
            cameraEnabled={cameraEnabled}
          />
        </>
      ) : (
        <div className="w-full max-w-lg px-4 text-center">
          <h2 className="text-xl text-primaryGreen mb-3">
            Scanning is currently being improved
          </h2>
          <p className="text-white opacity-75 mb-4">
            We will notify you when you can easily scan your delivery notes
            again.
          </p>
          <button
            className="button button--autoWidth button--primaryGreen"
            onClick={() => {
              dispatch({
                type: "RESET_NEW_DELIVERY_NOTE",
                options: { resetSupplier: false },
              })
              setCurrentStep(3)
            }}
            disabled={!permissionObj?.permissions.modify}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add manually
          </button>
        </div>
      )}
    </div>
  )

  /**
   * Show image preview when uploading
   *
   * @var {[type]}
   */
  if (selectedFiles.length > 0 && confirmed) {
    // Confirm screen
    content = (
      <>
        {selectedFiles[selectedFiles.length - 1].file.type ===
        "application/pdf" ? (
          <div className={styles.confirmPdf}>
            <FontAwesomeIcon icon={faFilePdf} className={styles.pdfIcon} />
            <span>{selectedFiles[selectedFiles.length - 1].file.name}</span>
          </div>
        ) : (
          <img
            alt="Delivery Note Preview"
            className={styles.confirmImg}
            src={selectedFiles[selectedFiles.length - 1].dataUri}
          />
        )}
        {currentStep === 2 && <div className={styles.scanning} />}
      </>
    )
  }

  if (
    !newDeliveryNote.supplier &&
    (cameraDemoUsers.indexOf(user.username) > -1 || scanningEnabled)
  ) {
    content = (
      <div className="h-full overflow-hidden flex lg:px-4 relative z-10 flex-col bg-white">
        <div className="flex items-center justify-between">
          <h2 className="px-4 lg:px-6 py-3 text-xl text-primaryBlue">
            Select your supplier
          </h2>
          <button
            className="text-gray-700 px-4 lg:px-6 py-3 text-xl"
            onClick={onRequestClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="flex-grow overflow-auto relative">
          <Loader isLoading={loading} />
          <SupplierSelect onSelect={handleSupplierSelect} />
        </div>
        <div className="p-4 border-t">
          <button
            disabled={loading}
            onClick={() => confirmSupplier()}
            className="button button--primaryBlue"
          >
            Confirm
          </button>
        </div>
      </div>
    )
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading && currentStep !== 3 && currentStep !== 4}
      portalClassName="deliveryNote"
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.navBar}>
          <nav className={cx("header", "transparent")}>
            <button onClick={onRequestClose}>
              <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
            </button>

            <div className={styles.headerMain}>
              <h2 className={styles.title}>Add delivery note</h2>
            </div>

            <div className={styles.placeHolder}></div>
          </nav>
        </div>

        <div
          className={cx("content", "step" + currentStep, {
            contentConfirm: !!selectedFiles.length,
          })}
        >
          {content}
          <div className={styles.scanPending}>
            <div className={styles.scanPendingAnimation} />
            <p className={styles.scanPendingText}>
              {isPolling ? "Processing the file..." : "Scanning the file..."}
            </p>
            {/* Disable cancel when polling is going on for response file */}
            {!isPolling && (
              <button
                className={styles.scanPendingButton}
                onClick={() => {
                  resetState()
                  cancelRequest()
                }}
              >
                CANCEL
              </button>
            )}
          </div>

          {(currentStep === 3 || currentStep === 4) && (
            <div
              className={styles.clickArea}
              onClick={() => {
                resetState()
              }}
            ></div>
          )}

          <div className={styles.confirmContainer}>
            <div className={styles.confirm}>
              <ConfirmDetails
                onCancel={() => {
                  resetState()
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onConfirm={() => {
                  onRequestClose()
                  showSuccess("Delivery approved!")
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onSave={() => {
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onReject={() => {
                  onRequestClose()
                  showError("Delivery note rejected!")
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onQuery={() => {
                  onRequestClose()
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onFilesChange={() => {
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
              />
            </div>
          </div>

          <div className={styles.orderSelectContainer}>
            <div className={styles.orderSelect}>
              <div className="h-full overflow-hidden flex flex-col bg-white">
                <div className="px-6 pt-4 pb-0 flex items-center flex-shrink-0">
                  <button
                    className="text-primaryBlue mr-4"
                    onClick={() => {
                      resetState()
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <h2 className="text-xl text-primaryBlue">Select an order</h2>
                </div>
                <div className="flex-grow flex flex-col overflow-hidden relative">
                  <OrderSelect onSelect={handleOrderSelect} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

DeliveryNote.propTypes = {
  onRequestClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default DeliveryNote
