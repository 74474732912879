import { get } from "./api"
import {
  convertToTimeZoneUTCString,
  removeTimezone,
  removeTimezoneEndDate,
} from "./helpers"
import { showError } from "./toast"

export const getNotifications = (params) => {
  return get("/dashboard/notifications", params)
}

export const getActivities = (params) => {
  return get("/dashboard/activities", params)
}

export const getOrdersStatistics = ({
  organizations, // Array of organization IDS
  searchBy, // Can be DATE ({from: Date, to: Date}) or string "LAST_WEEK", "TODAY", "LAST_MONTH"
}) => {
  const isDate = Boolean(searchBy?.to && searchBy?.from)

  if (isDate) {
    // GET based on date range
    // Create two separate UTC date objects, assign the values (day/month/year) to what user has selected and time it to midnight, toIsoString()
    const from = removeTimezone(searchBy.from)
    const to = removeTimezone(searchBy.to)
    return get("/orders/dashboard", {
      fromDate: from,
      toDate: to,
      organizations: organizations,
    })
  } else if (searchBy) {
    // GET based on search KEY
    return get("/orders/dashboard", {
      dateRange: searchBy.value,
      organizations: organizations,
    })
  }
}

export const getForDashboard = async ({
  route,
  searchBy,
  organizations,
  organization,
}) => {
  const availableRoutes = ["orders", "invoices", "sales", "delivery-notes"]
  const keyFound = availableRoutes.find((a) => a === route)

  let response = {}

  if (!keyFound) {
    showError("Route not accessible on: " + route)
    return new Promise(() => {})
  } else if (organizations?.length <= 0) {
    return new Promise(() => {})
  }
  const isDate = Boolean(searchBy?.to && searchBy?.from)

  if (isDate) {
    // GET based on date range
    // Create two separate UTC date objects, assign the values (day/month/year) to what user has selected and time it to midnight, toIsoString()
    let from = removeTimezone(searchBy.from)
    let to = removeTimezoneEndDate(searchBy.to)

    if (route === "sales") {
      from = convertToTimeZoneUTCString(
        searchBy.from,
        organization?.address?.zoneId
      )
      to = convertToTimeZoneUTCString(
        searchBy.to,
        organization?.address?.zoneId
      )
    }

    response = await get(`/${keyFound}/dashboard`, {
      fromDate: from,
      toDate: to,
      organizations: organizations,
    })
  } else if (searchBy) {
    // GET based on search KEY
    response = await get(`/${keyFound}/dashboard`, {
      dateRange: searchBy.value,
      organizations: organizations,
    })
  }
  return response
}
