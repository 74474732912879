import React, { useContext, useRef, useState } from "react"

//@ts-ignore
import * as styles from "./EditEvent.module.css"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import TextInput from "components/forms/TextInput"
import {
  removeEvent,
  saveEvent,
  updateEventFeaturedImage,
  updateEventFiles,
} from "services/events/events"
import EditSections from "./EditSections"
import { showSuccess, showError } from "services/toast"
import { navigate } from "gatsby"
import FeaturedImage from "components/common/FeaturedImage/FeaturedImage"
import TextArea from "components/forms/TextArea"
import FileList from "components/common/FileList/FileList"
import { GrowyzeFile, Permission } from "services/types"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import {
  convertToTimeZoneDate,
  convertToTimeZoneUTCString,
  getTimeFromDate,
  normalizeIngredientMeasures,
} from "services/helpers"
import usePermissions from "hooks/usePermissions"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { useFileOwner } from "hooks/useFileOwner"
import {
  Event,
  EventElementType,
  SectionElementOfEvent,
  SectionOfEvent,
} from "services/events/types"
import { createEvent } from "services/events/events"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"
import DateInput from "components/forms/DateInput"
import { set } from "date-fns"
import AllergenList from "components/products/AllergenList/AllergenList"
import SalesAndProfitEvent from "components/events/SalesAndProfitEvent/SalesAndProfitEvent"
import TimeInput from "components/forms/TimeInput"

export interface EditEventFormProps {
  event: Event
  getData: (id: string | undefined) => void
  isAsideView: boolean
  onSubmitCallback: (data: any) => {}
  onClose: () => void
}

const EditEventForm = ({
  event,
  getData,
  isAsideView,
  onSubmitCallback,
  onClose,
}: EditEventFormProps) => {
  const modal = useContext(ModalContext)
  const formRef = useRef<FormikProps<typeof event>>(null)
  const [featuredFile, setFeaturedFile] = useState<GrowyzeFile | null>(
    event.featuredFile ? event.featuredFile : null
  )
  const today = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  const {
    organizationPermissions,
    organization,
  }: { organization: any; organizationPermissions: any } =
    useContext(GlobalStateContext)
  const [files, setFiles] = useState<GrowyzeFile[]>(
    event.files ? event.files : []
  )
  const [fileLoading, setFileLoading] = useState(false)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission
  const filesOwner = useFileOwner(event)

  const preprocessEventElement = (
    element: SectionElementOfEvent
  ): SectionElementOfEvent => {
    if (element.type === EventElementType.INGREDIENT) {
      element.ingredient = normalizeIngredientMeasures(element.ingredient)
      return element
    } else {
      return element
    }
  }
  const preprocessEvent = (event: Event): Event => ({
    ...event,
    sections:
      event &&
      event.sections &&
      event.sections.map((section: SectionOfEvent) => ({
        name: section.name,
        elements:
          section &&
          section.elements &&
          section.elements.map(preprocessEventElement),
      })),
  })

  const submit = (event, helpers: FormikHelpers<Event>) => {
    const newEvent = event.id === undefined

    const [startHours, startMinutes] = event.startTime.split(":").map(Number) // "12:45" -> [12, 45]
    const [endHours, endMinutes] = event.endTime.split(":").map(Number) // "12:45" -> [12, 45]

    const eventData = {
      ...event,
      startDate: event.startDate
        ? convertToTimeZoneUTCString(
            set(new Date(event.startDate), {
              hours: startHours || 0,
              minutes: startMinutes || 0,
              seconds: 0,
              milliseconds: 0,
            }),
            organization.address.zoneId
          )
        : null,
      endDate: event.endDate
        ? convertToTimeZoneUTCString(
            set(new Date(event.endDate), {
              hours: endHours || 0,
              minutes: endMinutes || 0,
              seconds: 0,
              milliseconds: 0,
            }),
            organization.address.zoneId
          )
        : null,
    }

    const request = newEvent
      ? createEvent(eventData)
      : saveEvent(eventData.id, preprocessEvent(eventData))

    request
      .then((response) => {
        const { message, status } = response
        helpers.setSubmitting(false)
        if (
          [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 500].includes(
            status
          )
        ) {
          throw { message: message || "Something went wrong", status }
        } else {
          return response
        }
      })
      .then(async (eventRes) => {
        if (!eventRes) return

        if (newEvent) {
          if (files.length > 0) {
            setFileLoading(true)
            updateEventFiles(eventRes.id, { files: files }).then(() =>
              setFileLoading(false)
            )
          }

          /** Update locations for this dish */
          // if (isAllowedToManageLocations) {
          //   await saveLocations(dishRes.id)
          // }

          if (featuredFile) {
            updateEventFeaturedImage(eventRes.id, featuredFile)
          }

          if (onSubmitCallback) {
            onSubmitCallback(eventRes)
            onClose() //Closes dish modal if callback is provided
          }

          if (isAsideView) {
            getData(eventRes.id)
          } else {
            navigate("/dashboard/products/events/" + eventRes.id)
          }
        } else {
          const values = {
            ...eventRes,
            startDate: eventRes.startDate
              ? convertToTimeZoneDate(
                  eventRes.startDate,
                  organization.address.zoneId
                )
              : undefined,
            endDate: eventRes.endDate
              ? convertToTimeZoneDate(
                  eventRes.endDate,
                  organization.address.zoneId
                )
              : undefined,
          }
          helpers.setValues(values)
          setFiles(eventRes.files)
          setFeaturedFile(eventRes.featuredFile)
        }
      })
      .then((res) => {
        if (res === undefined) return
        showSuccess(event.id ? "Event saved." : "Event created.")
      })
      .catch(({ message }) => showError(message))
  }

  const updateFeaturedImg = async (file) => {
    setFeaturedFile(file)

    // If dish doesn't exist, don't try to save the featured img
    if (!event.id) {
      return
    }
    await updateEventFeaturedImage(event.id, file)
  }

  const handleFeaturedImgChange = ({ action, file }) => {
    switch (action) {
      case "file-added":
        updateFeaturedImg(file)
        break
      case "file-removed":
        updateFeaturedImg(null)
        break
      default:
        break
    }
  }

  const handleFileUploaded = async (newFiles) => {
    setFileLoading(true)

    const newFileList = [...(files || []), ...newFiles]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    // If there is no recipe yet, don't try to save the files
    if (!event.id) {
      setFileLoading(false)
      return
    }

    try {
      await updateFiles(newFileList)
      // setStats(formatRecipeData(res))
      setFileLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setFileLoading(false)
      showError(e.message || "File upload failed")
    }
  }

  const updateFiles = (files) => {
    return updateEventFiles(event.id, { files: files })
  }

  const handleFileDeleted = async (file) => {
    setFileLoading(true)
    const newFileList = [...files.filter((f) => f.fileId !== file.fileId)]

    // Make sure to save files also temporary in the object
    setFiles(newFileList)

    // If there is no recipe yet, don't try to save the files
    if (!event.id) {
      setFileLoading(false)
      return
    }

    try {
      await updateFiles(newFileList)
      // setStats(formatRecipeData(res))
      setFileLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setFileLoading(false)
      showError(e.message || "File delete failed")
    }
  }

  const onDelete = () => {
    // @ts-ignore
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete event",
      text: "Are you sure you want to delete this event?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteEvent(),
    })
  }

  const onDuplicate = async () => {
    const newEvent: Event = {
      ...event,
      posId: undefined,
      files: [],
      featuredFile: undefined,
      name: `Copy of ${event.name}`,
    }

    const currentFiles = event.files
    const currentFeaturedFile = event.featuredFile

    delete newEvent.id
    try {
      const result = await createEvent(newEvent)

      if (currentFiles && currentFiles.length > 0) {
        await updateEventFiles(result.id, { files: currentFiles })
      }
      if (currentFeaturedFile) {
        await updateEventFeaturedImage(result.id, currentFeaturedFile)
      }
      if (isAsideView) {
        getData(result.id)
      } else {
        navigate(`/dashboard/products/events/${result.id}`)
      }
      showSuccess("Event duplicated!")
    } catch (e) {
      console.log(e)
      showError("Something went wrong")
    }
  }

  const deleteEvent = async () => {
    const deleted = await removeEvent(event.id)
    if (deleted) {
      showSuccess("Event deleted!")
      if (isAsideView) {
        getData(event.id)
      } else {
        navigate("/dashboard/products/events")
      }
    }
  }

  const eventActions = [
    {
      key: "event.duplicate",
      title: "Duplicate",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    // {
    //   key: "event.print",
    //   title: "Print",
    //   type: "default",
    //   disabled: !permissionObj?.permissions.read,
    // },
    {
      key: "event.delete",
      title: "Delete",
      type: "danger",
      disabled:
        !permissionObj?.permissions.delete ||
        !organizationPermissions?.dishes?.remove,
    },
  ]

  const handleActionClick = (action) => {
    const { type } = action
    switch (type) {
      case "event.delete":
        onDelete()
        break
      case "event.duplicate":
        onDuplicate()
        break
      // case "event.print":
      //   printPage()
      //   break
      default:
        break
    }
  }

  return (
    <Formik
      initialValues={event}
      onSubmit={submit}
      innerRef={formRef}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {
        const initializeTimeField = (
          dateField: string,
          timeFieldKey: string
        ) => {
          if (!values[timeFieldKey]) {
            if (values[dateField]) {
              const time = getTimeFromDate(values[dateField])
              setFieldValue(timeFieldKey, time)
            } else {
              setFieldValue(timeFieldKey, "00:00")
            }
          }
        }
        // Initialize timefields as they're separated in the FE (in BE this is one and only startDate/endDate field)
        initializeTimeField("startDate", "startTime")
        initializeTimeField("endDate", "endTime")
        return (
          <>
            <Form className={styles.container}>
              <div className={styles.wrapper}>
                <Tabs
                  hideTabContent
                  initialTab="generalInfo"
                  className="growyze-tabs flex-shrink-0 px-2 mt-2"
                >
                  <Tab
                    tabKey="generalInfo"
                    title="General info"
                    className="flex-shrink-0"
                    dontGrow
                  >
                    <div className={styles.infoSection}>
                      <div className={styles.form}>
                        <div className="form-group">
                          <div className="input-container">
                            <TextInput
                              required={true}
                              showValidations={false}
                              name="name"
                              value={values.name || ""}
                              label="Event name *"
                              placeholder="Event name *"
                              disabled={!permissionObj?.permissions.modify}
                              type="string"
                              className="form-control form-control--first"
                            />
                          </div>
                          <div className="input-container">
                            <TextInput
                              required={true}
                              showValidations={false}
                              name="revenue"
                              label="Revenue *"
                              value={values.revenue || ""}
                              type="number"
                              disabled={!permissionObj?.permissions.modify}
                              placeholder="Revenue *"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group form-group--flex">
                            <div className="input-container input-container--left">
                              <DateInput
                                name="startDate"
                                className="form-control"
                                placeholder="Start date"
                              />
                            </div>
                            <div className="input-container input-container--right">
                              <TimeInput
                                name="startTime"
                                className="form-control"
                                placeholder="Start time"
                              />
                            </div>
                          </div>
                          <div className="form-group form-group--flex">
                            <div className="input-container input-container--left">
                              <DateInput
                                name="endDate"
                                className="form-control"
                                placeholder="End date"
                                disableBefore={
                                  values?.startDate ? values?.startDate : today
                                }
                              />
                            </div>
                            <div className="input-container input-container--right">
                              <TimeInput
                                name="endTime"
                                className="form-control"
                                placeholder="End time"
                              />
                            </div>
                          </div>
                          <div className="input-container">
                            <TextInput
                              name="coverCount"
                              required={false}
                              label="Cover number"
                              disabled={!permissionObj?.permissions.modify}
                              type="number"
                              value={values.coverCount || ""}
                              placeholder="Cover number"
                              className="form-control form-control--last"
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.formFeaturedImg}>
                        <h3 className={styles.sectionHeading}>
                          Featured image
                        </h3>
                        <FeaturedImage
                          onChange={handleFeaturedImgChange}
                          currentFile={featuredFile}
                          disabled={!permissionObj?.permissions.modify}
                          clientKey="event"
                        />
                      </div>

                      <div className="form-group self-stretch flex flex-col flex-1 w-full md:w-3/12 px-2 mb-4">
                        <AllergenList entityId={values.id} entity={values} />
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    tabKey="salesAndProfit"
                    title="Sales & Profit"
                    className="flex-shrink-0"
                    dontGrow
                  >
                    <SalesAndProfitEvent values={values} />
                  </Tab>
                </Tabs>

                <Tabs
                  initialTab="items"
                  hideTabContent
                  className="growyze-tabs flex-shrink-0 px-2"
                >
                  <Tab
                    title="Menu items"
                    tabKey="items"
                    className="flex-shrink-0"
                  >
                    <div className="px-4 lg:px-8">
                      <EditSections sections={values.sections} />
                    </div>
                  </Tab>
                  <Tab title="Files" tabKey="files" className="flex-shrink-0">
                    <div className="px-4 py-6 lg:px-8">
                      <FileList
                        files={files}
                        filesOwner={filesOwner}
                        isLoading={fileLoading}
                        clientKey="event"
                        disabled={!permissionObj?.permissions.modify}
                        onUploaded={handleFileUploaded}
                        onDeleted={handleFileDeleted}
                      />
                    </div>
                  </Tab>
                  <Tab title="Notes" tabKey="notes" className="flex-shrink-0">
                    <div className="px-4 py-6 lg:px-8 w-full">
                      <h4 className="mr-2 font-sansSemiBold font-semibold mb-4 text-gray-800">
                        Notes
                      </h4>
                      <div className="input-container">
                        <TextArea
                          showValidations={false}
                          name="notes"
                          value={values.notes || ""}
                          className={`${styles.dishDescription} form-control--first form-control--last`}
                          placeholder="Any notes you like to add to this event?"
                          label="Notes"
                          disabled={!permissionObj?.permissions.modify}
                          id="event-notes"
                          rows={10}
                          key="notes"
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className={styles.formFooter}>
                {event.id && (
                  <ActionsButton
                    options={eventActions}
                    buttonClass={styles.actionBtn}
                    dropDirection="top"
                    onActionClick={(action) => handleActionClick(action)}
                    asPortal={false}
                  />
                )}
                <button
                  className="button button--autoWidth button--primaryGreen"
                  type="submit"
                  disabled={isSubmitting || !permissionObj?.permissions.modify}
                >
                  {event.id ? "Save" : "Create"}
                </button>
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default EditEventForm
