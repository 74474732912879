import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"
import { invoiceStates } from "services/constants"
import { removeInvoice } from "services/invoices"
// import { getStorageFilename, downloadStorageFile } from "services/storage"
import { formatCurrencyValue, roundNumber } from "services/helpers"
import { isValid } from "date-fns"
// import { showError } from "services/toast"
import { faFileAlt } from "@fortawesome/pro-duotone-svg-icons"
import { faPaperclip } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import ServerImage from "components/common/ServerImage/ServerImage"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import TimeLabel from "components/common/TimeLabel/TimeLabel"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import InvoiceDetails from "components/invoices/InvoiceDetails/InvoiceDetails"
import { ModalContext } from "context/modal/ModalContext"
import { useMediaQuery } from "react-responsive"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import usePermissions from "hooks/usePermissions"

import * as styles from "./InvoiceItem.module.css"
import { showSuccess } from "services/toast"
import { removeDeliveryNote } from "services/delivery-notes/delivery-notes"
import AccountingStatusLabels from "components/integrations/Accounting/AccountingStatusLabels"

const cx = classNames.bind(styles)

const InvoiceItem = ({ invoice, onDeleted, onUpdated, providers }) => {
  const { organization, organizationPermissions } =
    useContext(GlobalStateContext)

  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions("Invoices")
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  // const [isLoading, setIsLoading] = useState(false)

  const invoiceHasDeliveryNote = invoice.deliveryNoteNumber

  const getCorrectDate = (rowData) => {
    switch (rowData.status) {
      case "APPROVED":
        return rowData.approvedDate
      case "REJECTED":
        return rowData.rejectedDate
      case "IN_QUERY":
        return rowData.inQueryDate
      default:
        return rowData.dateOfScanning
    }
  }

  const rowState = (rowData) => {
    const dnState = invoiceStates.find((oS) => oS.value === rowData.status)
    return (
      dnState && (
        <ItemStatus status={dnState}>
          <TimeLabel date={getCorrectDate(rowData)} />
        </ItemStatus>
      )
    )
  }

  const deleteInvoice = async (invoice) => {
    const deleted = await removeInvoice(invoice.id)
    if (deleted) {
      showSuccess("Invoice deleted!")
      onDeleted()
    }
  }

  const deleteDeliveryNote = async (invoice) => {
    const deleted = await removeDeliveryNote(invoice.deliveryNoteId)
    if (deleted) {
      showSuccess("Delivery note deleted!")
      onDeleted()
    }
  }

  const onEdit = (invoice) => {
    dispatch({ type: "RESET_NEW_INVOICE" })
    dispatch({ type: "UPDATE_NEW_INVOICE", payload: invoice })
    modal.showModal(InvoiceDetails, { onUpdate: () => onUpdated() })
  }

  const onDeleteInvoice = (invoice) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete invoice ${invoice.invoiceNumber || ""}`,
      text: "Are you sure you want to delete this invoice?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        deleteInvoice(invoice)
        if (invoiceHasDeliveryNote) {
          setTimeout(() => onDeleteDeliveryNote(invoice), 1000)
        }
      },
    })

  const onDeleteDeliveryNote = (invoice) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete DN ${invoice.deliveryNoteNumber}`,
      text: `Do you want to delete the associated DN ${invoice.deliveryNoteNumber} as well?`,
      confirmButtonText: "Delete",
      onConfirm: () => {
        deleteDeliveryNote(invoice)
      },
    })
  // const onDownloadFile = async () => {
  //   setIsLoading(true)
  //   const fileName = await getStorageFilename(invoice.fileId)

  //   if (!fileName) {
  //     setIsLoading(false)
  //     return showError("Something went wrong...")
  //   }

  //   downloadStorageFile(invoice.fileId, fileName)
  //   setIsLoading(false)
  // }

  const handleActionClick = (action, invoice) => {
    const { type } = action
    switch (type) {
      case "invoice.edit":
        onEdit(invoice)
        break
      case "invoice.delete":
        onDeleteInvoice(invoice)
        break
      default:
        break
    }
  }

  const totalFiles =
    (invoice.extractedFile ? 1 : 0) + (invoice.files ? invoice.files.length : 0)

  /**
   * Action used for both Edit and Re-order
   * @param  {Object} rowData current o rder
   * @return {Function}         shows action buttons
   */
  const actions = (rowData) => {
    const options = [
      {
        key: "invoice.edit",
        title: "Edit",
        type: "default",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "invoice.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]
    return (
      <div
        className="px-3 flex-shrink-0 ml-auto"
        style={{ textAlign: "right" }}
      >
        {rowData?.organizations.length &&
          rowData?.organizations[0] === organization?.id && (
            <ActionsButton
              options={options}
              onActionClick={(action) => handleActionClick(action, rowData)}
            />
          )}
      </div>
    )
  }

  const Icon = (
    <div
      className={styles.iconWrapper}
      style={{
        backgroundColor: "#E7EDFF",
        color: "#1B377D",
      }}
    >
      <FontAwesomeIcon icon={faFileAlt} />
    </div>
  )

  return (
    <div className="border -mb-px">
      <div className="flex flex-wrap items-center content-center justify-between relative">
        <AccountingStatusLabels invoice={invoice} providers={providers} />
        <div className="pl-3 flex items-center">
          <button
            onClick={(e) => {
              e.preventDefault()
              handleActionClick({ type: "invoice.edit" }, invoice)
            }}
            className={styles.imageWrapper}
            disabled={!permissionObj?.permissions.read}
          >
            <ServerImage fileId={invoice.fileId} alt={invoice.invoiceNumber}>
              {Icon}
            </ServerImage>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault()
              handleActionClick({ type: "invoice.edit" }, invoice)
            }}
            disabled={!permissionObj?.permissions.read}
            style={{ minWidth: "175px", maxWidth: "175px", textAlign: "left" }}
            className="font-sansSemiBold focus:outline-none font-semibold text-primaryBlue py-3 px-3 flex flex-col"
          >
            <span className="break-all">
              {invoice.status === "REVIEW" && (
                <span className="opacity-50">[{invoice.status}]</span>
              )}
              {invoice.invoiceNumber || "INV-??"}
            </span>

            {invoice.dateOfIssue && isValid(new Date(invoice.dateOfIssue)) && (
              <TimeLabel date={invoice.dateOfIssue} />
            )}
            <Link
              to={`/dashboard/purchases/suppliers/${invoice.supplier.id}`}
              className="lg:hidden text-gray-700 text-xs font-normal block w-full font-sans truncate"
            >
              {invoice.supplier.name}
            </Link>
          </button>
        </div>

        {organizationPermissions?.general?.isMain &&
          invoice?.organizationsNames.length > 0 && (
            <div
              className="flex-col p-3 flex"
              style={{ minWidth: "175px", maxWidth: "175px" }}
            >
              <span className="text-xs text-uppercase text-gray-700">
                Location/Area
              </span>
              <span>{invoice?.organizationsNames[0]}</span>
            </div>
          )}

        <div
          className="hidden flex-col p-3 lg:flex"
          style={{ minWidth: "175px", maxWidth: "175px" }}
        >
          <span className="text-xs text-uppercase text-gray-700">Supplier</span>
          <Link
            to={`/dashboard/purchases/suppliers/${invoice.supplier.id}`}
            className="text-primaryBlue break-words"
          >
            {invoice.supplier.name}
          </Link>
        </div>
        <div
          className="flex flex-col p-3"
          style={{ minWidth: isTabletOrMobile ? "0" : "125px" }}
        >
          <span className="text-xs text-uppercase text-gray-700">
            Total (exc VAT)
          </span>
          {invoice.supplier.currency
            ? formatCurrencyValue(invoice.totalCost)
            : roundNumber(invoice.totalCost)}
        </div>
        {rowState(invoice)}
        <div
          className="flex flex-col items-end p-3"
          style={{ minWidth: isTabletOrMobile ? "0" : "250px" }}
        >
          {invoice.deliveryNoteId ? (
            <span className={cx("invoiceDeliveryStatus", "found", "mb-2")}>
              {invoice.deliveryNoteNumber
                ? `DN: ${invoice.deliveryNoteNumber}`
                : "Delivery found"}
            </span>
          ) : (
            <span className={cx("invoiceDeliveryStatus", "notfound", "mb-2")}>
              No delivery found
            </span>
          )}
          {invoice.po ? (
            <span className={cx("invoiceDeliveryStatus", "found")}>
              Order: {`#${invoice.po}`}
            </span>
          ) : (
            <span className={cx("invoiceDeliveryStatus", "notfound")}>
              No order found
            </span>
          )}
        </div>

        {/* {invoice.fileId && (
          <div className="flex flex-col items-end p-3">
            <button onClick={onDownloadFile}>
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
              ) : (
                <FontAwesomeIcon icon={faFileAlt} size="lg" />
              )}
            </button>
          </div>
        )} */}
        {totalFiles > 0 && (
          <div className="flex justify-self-end items-center text-gray-700 text-sm font-semibold font-sansSemiBold p-3">
            <FontAwesomeIcon icon={faPaperclip} className="mr-1" /> {totalFiles}
          </div>
        )}
        {actions(invoice)}
      </div>
    </div>
  )
}

InvoiceItem.propTypes = {
  invoice: PropTypes.object,
  onDeleted: PropTypes.func,
  onUpdated: PropTypes.func,
}

export default InvoiceItem
