import { ModelYield, Portion, Recipe } from "services/recipes/types"
import { GrowyzeFile, MeasureEnum, UnitEnum } from "services/types"
import { WasteMeasureEnum } from "services/waste/types"

/**
 *
 * @export
 * @interface Cover
 */
export interface Cover {
  /**
   *
   * @type {number}
   * @memberof Cover
   */
  cost?: number
  /**
   *
   * @type {number}
   * @memberof Cover
   */
  revenue?: number
  /**
   *
   * @type {number}
   * @memberof Cover
   */
  revenueWithTax?: number
}

/**
 *
 * @export
 * @interface DishForIngredient
 */
export interface DishForIngredient {
  /**
   *
   * @type {Array<string>}
   * @memberof DishForIngredient
   */
  allergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof DishForIngredient
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof DishForIngredient
   */
  id?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DishForIngredient
   */
  mayContainAllergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof DishForIngredient
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DishForIngredient
   */
  posId?: string
  /**
   *
   * @type {number}
   * @memberof DishForIngredient
   */
  totalCost?: number
}

/**
 *
 * @export
 * @interface DishOfEvent
 */
export interface DishOfEvent {
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  addedQty?: number
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  consumedCost?: number
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  consumedQty?: number
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  cost?: number
  /**
   *
   * @type {DishForIngredient}
   * @memberof DishOfEvent
   */
  dish?: DishForIngredient
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  initialQty?: number
  /**
   *
   * @type {string}
   * @memberof DishOfEvent
   */
  measure?: MeasureEnum
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  size?: number
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  totalCost?: number
  /**
   *
   * @type {string}
   * @memberof DishOfEvent
   */
  wasteMeasure?: WasteMeasureEnum
  /**
   *
   * @type {number}
   * @memberof DishOfEvent
   */
  wasteQty?: number
}

/**
 *
 * @export
 * @interface ProductForIngredient
 */
export interface ProductForIngredient {
  /**
   *
   * @type {Array<string>}
   * @memberof ProductForIngredient
   */
  allergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  barcode?: string
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  id?: string
  /**
   *
   * @type {boolean}
   * @memberof ProductForIngredient
   */
  isDeleted?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof ProductForIngredient
   */
  mayContainAllergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  measure?: MeasureEnum
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof ProductForIngredient
   */
  price?: number
  /**
   *
   * @type {number}
   * @memberof ProductForIngredient
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  subCategory?: string
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  supplierId?: string
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  supplierName?: string
  /**
   *
   * @type {string}
   * @memberof ProductForIngredient
   */
  unit?: UnitEnum
}

/**
 *
 * @export
 * @interface IngredientOfEvent
 */
export interface IngredientOfEvent {
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  addedQty?: number
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  consumedCost?: number
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  consumedQty?: number
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  cost?: number
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  initialQty?: number
  /**
   *
   * @type {string}
   * @memberof IngredientOfEvent
   */
  measure?: MeasureEnum
  /**
   *
   * @type {ProductForIngredient}
   * @memberof IngredientOfEvent
   */
  product?: ProductForIngredient
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  size?: number
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  totalCost?: number
  /**
   *
   * @type {string}
   * @memberof IngredientOfEvent
   */
  wasteMeasure?: WasteMeasureEnum
  /**
   *
   * @type {number}
   * @memberof IngredientOfEvent
   */
  wasteQty?: number
}

/**
 *
 * @export
 * @interface OtherIngredientOfEvent
 */
export interface OtherIngredientOfEvent {
  /**
   *
   * @type {number}
   * @memberof OtherIngredientOfEvent
   */
  cost?: number
  /**
   *
   * @type {string}
   * @memberof OtherIngredientOfEvent
   */
  measure?: MeasureEnum
  /**
   *
   * @type {string}
   * @memberof OtherIngredientOfEvent
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof OtherIngredientOfEvent
   */
  size?: number
}

/**
 *
 * @export
 * @interface RecipeForIngredient
 */
export interface RecipeForIngredient {
  /**
   *
   * @type {Array<string>}
   * @memberof RecipeForIngredient
   */
  allergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof RecipeForIngredient
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof RecipeForIngredient
   */
  id?: string
  /**
   *
   * @type {Array<string>}
   * @memberof RecipeForIngredient
   */
  mayContainAllergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof RecipeForIngredient
   */
  name?: string
  /**
   *
   * @type {PortionDto}
   * @memberof RecipeForIngredient
   */
  portion?: Portion
  /**
   *
   * @type {string}
   * @memberof RecipeForIngredient
   */
  posId?: string
  /**
   *
   * @type {number}
   * @memberof RecipeForIngredient
   */
  totalCost?: number
  /**
   *
   * @type {ModelYield}
   * @memberof RecipeForIngredient
   */
  _yield?: ModelYield
}

/**
 *
 * @export
 * @interface RecipeOfEvent
 */
export interface RecipeOfEvent {
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  addedQty?: number
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  consumedCost?: number
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  consumedQty?: number
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  cost?: number
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  initialQty?: number
  /**
   *
   * @type {string}
   * @memberof RecipeOfEvent
   */
  measure?: MeasureEnum
  /**
   *
   * @type {RecipeForIngredient}
   * @memberof RecipeOfEvent
   */
  recipe?: Recipe
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  size?: number
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  totalCost?: number
  /**
   *
   * @type {string}
   * @memberof RecipeOfEvent
   */
  wasteMeasure?: WasteMeasureEnum
  /**
   *
   * @type {number}
   * @memberof RecipeOfEvent
   */
  wasteQty?: number
}

export enum EventElementType {
  DISH = <any>"DISH",
  INGREDIENT = <any>"INGREDIENT",
  OTHER_INGREDIENT = <any>"OTHER_INGREDIENT",
  RECIPE = <any>"RECIPE",
}

export enum EventStatusEnum {
  COMPLETED = <any>"COMPLETED",
  IN_PROGRESS = <any>"IN_PROGRESS",
}

/**
 *
 * @export
 * @interface SectionElementOfEvent
 */
export interface SectionElementOfEvent {
  /**
   *
   * @type {DishOfEvent}
   * @memberof SectionElementOfEvent
   */
  dish?: DishOfEvent
  /**
   *
   * @type {IngredientOfEvent}
   * @memberof SectionElementOfEvent
   */
  ingredient?: IngredientOfEvent
  /**
   *
   * @type {OtherIngredientOfEvent}
   * @memberof SectionElementOfEvent
   */
  otherIngredient?: OtherIngredientOfEvent
  /**
   *
   * @type {RecipeOfEvent}
   * @memberof SectionElementOfEvent
   */
  recipe?: RecipeOfEvent
  /**
   *
   * @type {string}
   * @memberof SectionElementOfEvent
   */
  type?: EventElementType
}

/**
 *
 * @export
 * @interface SectionOfEvent
 */
export interface SectionOfEvent {
  /**
   *
   * @type {number}
   * @memberof SectionOfEvent
   */
  consumedCost?: number
  /**
   *
   * @type {Array<SectionElementOfEvent>}
   * @memberof SectionOfEvent
   */
  elements?: Array<SectionElementOfEvent>
  /**
   *
   * @type {string}
   * @memberof SectionOfEvent
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SectionOfEvent
   */
  totalCost?: number
}

/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   *
   * @type {Array<string>}
   * @memberof Event
   */
  allergens?: Array<string>
  /**
   *
   * @type {number}
   * @memberof Event
   */
  consumedCost?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  wasteCost?: number
  /**
   *
   * @type {Cover}
   * @memberof Event
   */
  cover?: Cover
  /**
   *
   * @type {number}
   * @memberof Event
   */
  coverCount?: number
  /**
   *
   * @type {Date}
   * @memberof Event
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof Event
   */
  endDate?: Date
  /**
   *
   * @type {string}
   * @memberof Event
   */
  endTime?: string
  /**
   *
   * @type {GrowyzeFile}
   * @memberof Event
   */
  featuredFile?: GrowyzeFile
  /**
   *
   * @type {Array<GrowyzeFile>}
   * @memberof Event
   */
  files?: Array<GrowyzeFile>
  /**
   *
   * @type {string}
   * @memberof Event
   */
  id?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Event
   */
  mayContainAllergens?: Array<string>
  /**
   *
   * @type {string}
   * @memberof Event
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Event
   */
  notes?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Event
   */
  organizations?: Array<string>
  /**
   *
   * @type {string}
   * @memberof Event
   */
  posId?: string
  /**
   *
   * @type {number}
   * @memberof Event
   */
  profit?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  profitBasedOnRevenueWithTax?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  profitPercent?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  profitPercentBasedOnRevenueWithTax?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  revenue?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  revenueWithTax?: number
  /**
   *
   * @type {Array<SectionOfEvent>}
   * @memberof Event
   */
  sections?: Array<SectionOfEvent>
  /**
   *
   * @type {Date}
   * @memberof Event
   */
  startDate?: Date
  /**
   *
   * @type {string}
   * @memberof Event
   */
  startTime?: string
  /**
   *
   * @type {string}
   * @memberof Event
   */
  status?: EventStatusEnum
  /**
   *
   * @type {number}
   * @memberof Event
   */
  taxPercent?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  totalCost?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  totalCostPercent?: number
  /**
   *
   * @type {number}
   * @memberof Event
   */
  totalCostPercentBasedOnSalePriceWithTax?: number
  /**
   *
   * @type {Date}
   * @memberof Event
   */
  updatedDate?: Date
}
