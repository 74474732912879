import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { getDeliveryNotes } from "services/delivery-notes/delivery-notes"
import { searchSuppliers } from "services/suppliers/suppliers"
import {
  deliveryNoteStates,
  deliveryNotesQueryParameters,
} from "services/constants"
import { faPlus, faFileDownload } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import FilterSelect from "components/forms/FilterSelect"
import AsyncSelect from "components/forms/AsyncSelect"
import Header from "components/dashboard/Header/Header"
import DeliveryNoteItem from "components/delivery-notes/DeliveryNoteItem/DeliveryNoteItem"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import DeliveryNoteModal from "components/delivery-notes/DeliveryNote/DeliveryNote"
import { ModalContext } from "context/modal/ModalContext"
import { useMediaQuery } from "react-responsive"
import SearchInput from "components/forms/SearchInput"
import * as styles from "./DeliveryNotes.module.css"
import { navigate } from "gatsby"
import usePermissions from "hooks/usePermissions"
import { faBoxOpen } from "@fortawesome/pro-duotone-svg-icons"
import BaseList from "components/baseList/BaseList"
import usePagination from "hooks/usePagination"
import { getDeleveryNotesDummy } from "services/dummy"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import FiltersButton from "components/common/FiltersButton/FiltersButton"

const DeliveryNotes = () => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)

  const permissionObj = usePermissions("Delivery notes")
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [deliveryNotesData, setDeliveryNotesData] = useState({
    content: [],
  })
  const pagination = usePagination()
  const [status, setStatus] = useState(null)
  const previousStatus = usePrevious(status)
  const [supplier, setSupplier] = useState(null)
  const [product] = useState(null)
  const previousSupplier = usePrevious(supplier)
  const previousProduct = usePrevious(product)
  const [queryParameter, setQueryParameter] = useState(
    {
      label: "Product name",
      value: "partialProductName",
    },
    {
      label: "Product SKU",
      value: "partialProductCode",
    },
    {
      label: "Document number",
      value: "deliveryNoteNumber",
    }
  )

  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const previousPage = usePrevious(pagination.page)

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setDeliveryNotesData({ content: [] })
      return
    }

    setLoading(true)

    let params = {
      page: pagination.page,
      size: pagination.size,
      sort: "deliveryDate,desc",
      includeIsInvoiced: true,
    }

    if (supplier) {
      params.supplierId = supplier.value
      if (previousSupplier && previousSupplier.value !== supplier.value) {
        params.page = 0
      }
    }

    if (status) {
      params.status = status.value

      if (previousStatus && previousStatus.value !== status.value) {
        params.page = 0
      }
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialProductName":
          params.partialProductName = q
          break
        case "partialProductCode":
          params.partialProductCode = q
          break
        case "partialBarcode":
          params.partialBarcode = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        case "deliveryNoteNumber":
          params.deliveryNoteNumber = q
          break
        default:
          params.partialProductName = q
          break
      }
      if (previousQ !== q) {
        params.page = 0
      }
    }

    if (product) {
      params.q = product.value
      if (previousProduct && previousProduct.value !== product.value) {
        params.page = 0
      }
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getDeliveryNotes(params))
    if (result && !result.error) {
      setDeliveryNotesData({ ...deliveryNotesData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (previousStatus || status) {
      getData()
    }
  }, [status])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      getData()
    }
  }, [supplier])

  useEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  useEffect(() => {
    getData()
  }, [selectedOrganizations])

  return (
    <>
      <Helmet>
        <title>Deliveries</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Deliveries" />

        <div className={styles.subHeader}>
          <FiltersButton>
            <FilterSelect
              options={deliveryNoteStates}
              value={status}
              onChange={(val) => {
                setStatus(val)
              }}
              placeholder={isTabletOrMobile ? "Status" : "Status: all"}
              isClearable={true}
              className="w-56"
            />
            <AsyncSelect
              promise={searchSuppliers}
              placeholder={isTabletOrMobile ? "Supplier" : "All suppliers"}
              isClearable={true}
              optionLabel="name"
              optionValue="id"
              onChange={(val) => {
                setSupplier(val)
              }}
              className="w-56"
            />
            <LocationsSelector />
          </FiltersButton>

          <div className="flex items-center flex-grow relative my-2 order-last sm:order-none w-full sm:w-max">
            <SearchInput
              label="Search by name"
              placeholder={`Search by ${queryParameter.label}`}
              className={`form-control rounded text-sm md:text-base mr-4 md:mr-6 ${styles.searchInput}`}
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
            <FilterSelect
              options={deliveryNotesQueryParameters}
              value={queryParameter && queryParameter.value}
              onChange={(val) => {
                setQueryParameter(val)
              }}
              stylesOverride={{
                container: (provided) => ({
                  ...provided,
                  position: "static",
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#fff",
                  borderLeft: "none",
                  borderRadius: "0 0.25rem 0.25rem 0",
                  width: "fit-content",
                  borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  boxShadow: "none",
                  zIndex: state.isFocused ? 2 : null,
                  minHeight: 45,
                  ":hover": {
                    ...provided[":hover"],
                    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "0px",
                  width: "0px",
                }),
                menu: (provided) => ({
                  ...provided,
                  left: "0px",
                }),
              }}
            />
          </div>

          <div className="ml-auto">
            <button
              className="button my-2 ml-auto button--autoWidth button--primaryGreen"
              onClick={(e) => {
                e.preventDefault()
                dispatch({
                  type: "RESET_NEW_DELIVERY_NOTE",
                  options: { resetSupplier: true },
                })
                modal.showModal(DeliveryNoteModal, {
                  onUpdate: () => getData(),
                })
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
              <span className="hidden lg:inline">New delivery</span>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault()
                navigate("/dashboard/purchases/deliveries/export")
              }}
              className="my-2 ml-2 button text-center button--autoWidth button--primaryGreen"
              disabled={!permissionObj?.permissions.read}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <BaseList
            title="deliveries"
            id="deliveries"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faBoxOpen,
              color: "purple",
              getDummy: () => getDeleveryNotesDummy,
            }}
            data={deliveryNotesData.content}
            rowRender={(note) => (
              <DeliveryNoteItem
                key={note.id}
                note={note}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
                onMarked={() => getData()}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default DeliveryNotes
