import React, { ReactNode } from "react"
import IconEmptyStateOrders from "inline-svg/empty_state_orders.svg"
import IconEmptyStateInvoices from "inline-svg/empty_state_invoices.svg"
import IconEmptyStateBar from "inline-svg/empty_state_statuses.svg"

interface EmptyStateProps {
  iconElement?: ReactNode | void
  entityTitle?: string
  heading?: string
  iconBackgroundColor?: string
  chartType?: "bar" | "line" | "mixed" | undefined
  text?: string
}

const EmptyState = (props: EmptyStateProps) => {
  const {
    iconElement = null,
    entityTitle = "",
    iconBackgroundColor = "",
    chartType,
  } = props
  const assignedIcon = chartType ? (
    chartType === "line" ? (
      entityTitle.toLowerCase().includes("invoice") ? (
        <IconEmptyStateInvoices />
      ) : (
        <IconEmptyStateOrders />
      ) // Check if line chart, line charts have different gradient colors
    ) : chartType === "mixed" ? (
      <IconEmptyStateOrders />
    ) : (
      <IconEmptyStateBar />
    )
  ) : (
    iconElement
  )
  const text =
    props.text ??
    "Try selecting different dates or selecting more organizations"

  const heading = props.heading ?? `No ${entityTitle} found for that selection`

  return (
    <div className="flex flex-col h-full justify-center items-center">
      <div
        className="mb-2 rounded-full flex items-center justify-center"
        style={{
          backgroundColor: !chartType ? iconBackgroundColor : "",
        }}
      >
        {assignedIcon}
      </div>
      <h3 className="text-gray-600 font-sansSemiBold font-semibold text-base mb-2">
        {heading}
      </h3>
      <span className="text-gray-500 text-sm">{text}</span>
    </div>
  )
}

export default EmptyState
