import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditDetails.module.css"
import {
  convertToTimeZoneDate,
  convertToTimeZoneUTCString,
  formatDateAsTime,
} from "../../../services/helpers"
import { DeliveryNote } from "../../../services/delivery-notes/types"
import DateSelection from "components/forms/DateSelection"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { set } from "date-fns"

interface EditDetailsProps {
  note: DeliveryNote
  onSave: ({ number: string, date: Date }) => void
  isSaving: boolean
}

const EditDetails = ({ note, onSave, isSaving }: EditDetailsProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { organization }: { organization: any } = useContext(GlobalStateContext)

  const [number, setNumber] = useState(note ? note.deliveryNoteNumber : "")
  const [date, setDate] = useState<string | Date | undefined>(
    note.deliveryDate
      ? new Date(
          convertToTimeZoneDate(note.deliveryDate, organization.address.zoneId)
        )
      : ""
  )
  const [time, setTime] = useState<string>(
    note.deliveryDate
      ? formatDateAsTime(
          convertToTimeZoneDate(note.deliveryDate, organization.address.zoneId)
        )
      : ""
  )

  //
  // Handlers
  //

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setNumber(value)
  }

  const handleSave = useCallback(() => {
    if (date && time) {
      const [hours, minutes] = time.split(":").map(Number) // "12:45" -> [12, 45]

      const convertedDateAndTime = convertToTimeZoneUTCString(
        set(new Date(date), {
          hours: hours,
          minutes: minutes,
          seconds: 0,
          milliseconds: 0,
        }),
        organization.address.zoneId
      )

      onSave({ number, date: convertedDateAndTime })
    }
  }, [date, time, number])

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    window.setTimeout(() => {
      const el = inputRef.current
      if (el && typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
        // @ts-ignore
      } else if (el && typeof el.createTextRange != "undefined") {
        el.focus()
        // @ts-ignore
        const range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="px-2 w-full mb-4 sm:w-1/2 sm:mb-0">
          <label className="text-primaryBlue font-sansSemiBold mb-3">
            Delivery note number
          </label>
          <input
            ref={inputRef}
            type="text"
            className={`block border-0 px-0 form-control ${styles.input}`}
            onFocus={setCursorAtTheEnd}
            value={number || ""}
            onChange={handleInputChange}
            placeholder={`Add number or leave empty`}
          />
        </div>
        <div className="px-2 w-full sm:w-1/2">
          <label className="text-primaryBlue font-sansSemiBold mb-3">
            Delivery date
          </label>
          <DateSelection
            value={{ from: date, to: undefined, enteredTo: undefined }}
            onChange={(newDate) => setDate(newDate.from)}
            timeValue={{ from: time }}
            onChangeTime={(newTime) => setTime(newTime.from)}
            mode="single"
          />
        </div>
      </div>
      <div className={styles.footer}>
        <button
          type="button"
          onClick={handleSave}
          className="button button--primaryBlue"
          disabled={isSaving}
        >
          {isSaving && (
            <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
          )}
          Save
        </button>
      </div>
    </div>
  )
}

export default EditDetails
