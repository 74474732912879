import React, { useState, useEffect, useContext } from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import { getEvent } from "services/events/events"
import EditEventForm from "./EditEventForm"
import { Event } from "services/events/types"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { convertToTimeZoneDate } from "services/helpers"

export interface EditEventProps {
  eventId: string
  initialValues: Event
  onSubmitCallback: (data: any) => {}
  onClose: () => void
}

export default ({
  eventId,
  initialValues,
  onSubmitCallback,
  onClose,
}: EditEventProps) => {
  const [event, setEvent] = useState<Event | undefined>(undefined)
  const { organization }: { organization: any } = useContext(GlobalStateContext)
  const pageTitle: string = eventId ? "Edit Event" : "Add Event"
  const isAsideView = !!onClose

  const getEventData = (id) => {
    if (id) {
      getEvent(id).then((res) => {
        const values = {
          ...initialValues,
          ...res,
          startDate: res.startDate
            ? convertToTimeZoneDate(res.startDate, organization.address.zoneId)
            : undefined,
          endDate: res.endDate
            ? convertToTimeZoneDate(res.endDate, organization.address.zoneId)
            : undefined,
        }
        setEvent(values as unknown as Event)
      })
    } else {
      setEvent({
        ...initialValues,
        sections: [{ name: "Main", elements: [] }],
        notes: "",
        method: "",
        status: "IN_PROGRESS",
      } as unknown as Event)
    }
  }

  useEffect(() => {
    getEventData(eventId)
  }, [eventId])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isAsideView ? null : <Header back title={pageTitle} />}
      {event && (
        <EditEventForm
          event={event}
          getData={getEventData}
          isAsideView={isAsideView}
          onSubmitCallback={onSubmitCallback}
          onClose={onClose}
        />
      )}
    </>
  )
}
