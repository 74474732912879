import React from "react"
import BaseTable, { AutoResizer } from "react-base-table"
import { useMediaQuery } from "usehooks-ts"
import * as styles from "./SalesItemContent.module.css"

interface SalesItemContentProps {
  data: any
}

const columns = [
  {
    key: "category",
    title: "Category",
    dataKey: "category",
    width: 200,
    headerRenderer: ({ column }) => (
      <div className="text-gray-700 font-sans font-normal">{column.title}</div>
    ),
    cellRenderer: ({ cellData }) => (
      <div
        className="text-gray-900 font-sansSemiBold font-semibold py-1"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {cellData}
      </div>
    ),
    flexgrow: 1,
    flexshrink: 0,
  },
  {
    key: "sales",
    title: "Sales",
    dataKey: "totalSales",
    width: 100,
    headerRenderer: ({ column }) => (
      <div className="text-gray-700 font-sans font-normal">{column.title}</div>
    ),
    cellRenderer: ({ cellData }) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {cellData}
      </div>
    ),
    flexgrow: 1,
    flexshrink: 0,
  },
  {
    key: "percentageTotal",
    title: "Percentage of total sales",
    dataKey: "percentageOfTotalSales",
    width: 200,
    headerRenderer: ({ column }) => (
      <div className="text-gray-700 font-sans font-normal">{column.title}</div>
    ),
    cellRenderer: ({ cellData }) => (
      <div
        className={`font-semibold flex items-center text-xs sm:text-sm ${
          cellData === 0 || cellData === undefined || cellData === null
            ? "text-gray-500"
            : cellData > 0
            ? "text-green-500"
            : "text-red-500"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {cellData}%
      </div>
    ),
    flexgrow: 1,
    flexshrink: 0,
  },
]

const SalesItemContent = ({ data }: SalesItemContentProps) => {
  const isTabletOrMobile = useMediaQuery("{ maxWidth: 1023 }")
  return (
    <AutoResizer height={Infinity}>
      {({ width, height }) => (
        <BaseTable
          fixed={isTabletOrMobile ? true : false}
          style={{
            backgroundColor: "transparent",
          }}
          estimatedRowHeight={31}
          rowRenderer={({ cells }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {cells}
            </div>
          )}
          columns={columns}
          rowKey="category"
          data={data}
          width={width}
          maxHeight={data.length === 0 ? undefined : Infinity}
          height={data.length === 0 ? 80 : height}
          emptyRenderer={<div className={styles.noItems}>No items found</div>}
        />
      )}
    </AutoResizer>
  )
}

export default SalesItemContent
