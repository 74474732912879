import React, { useState, useContext, useEffect } from "react"
import { set } from "date-fns"
import Loader from "components/common/Loader/Loader"
import { Helmet } from "react-helmet"
import Header from "components/dashboard/Header/Header"
import DateSelection from "components/forms/DateSelection"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import IntegrationsButton from "components/integrations/IntegrationsButton/IntegrationsButton"

import * as styles from "./ExportLiveSales.module.css"
import { integration } from "services/integrations"
import { FlexiblePeriod, TimePeriod } from "services/types"
import { exportLiveSales } from "services/sales"
import { getExportFileName } from "services/export"
import { convertToTimeZoneUTCString } from "services/helpers"

const ExportLiveSales = () => {
  const { user, integrations }: { user: any; integrations: any } =
    useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState(true)
  const [activeIntegration, setActiveIntegration] = useState<string | null>(
    null
  )
  const [selectedPeriod, setSelectedPeriod] = useState<FlexiblePeriod>({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  })
  const [selectedTime, setSelectedTime] = useState<TimePeriod>({
    from: "00:00",
    to: "23:59",
  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  /** Fetch activated integrations on mount */
  useEffect(() => {
    const abortController = new AbortController()

    const active = integrations?.posIntegrations.find(
      (i: integration) => i.status === "ACTIVE"
    )
    setActiveIntegration(active ? active.type : null)
    setIsLoading(false)

    return () => {
      setIsLoading(false)
      abortController.abort()
    }
  }, [])

  const exportSalesData = async () => {
    if (!activeIntegration) {
      setError("You have no active POS integration")
      return
    }

    setLoading(true)
    setError("")

    const timeFromSplit = selectedTime.from.split(":")
    const timeToSplit = selectedTime?.to?.split(":")

    // @ts-ignore
    const dateFrom = set(selectedPeriod.from, {
      hours: timeFromSplit[0],
      minutes: timeFromSplit[1],
    })
    // @ts-ignore
    const dateTo = set(selectedPeriod.to, {
      hours: timeToSplit?.[0],
      minutes: timeToSplit?.[1],
    })

    const convertedDateFrom = convertToTimeZoneUTCString(dateFrom, user.zoneId)
    const convertedDateTo = convertToTimeZoneUTCString(dateTo, user.zoneId)

    await exportLiveSales({
      from: convertedDateFrom,
      to: convertedDateTo,
      fileName: getExportFileName("live-sales", "", "xlsx", [dateFrom, dateTo]),
    })

    setLoading(false)
  }

  return (
    <>
      <Helmet>
        <title>Export live sales</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Export live sales" />

        <div className={styles.content}>
          <Loader
            className="mt-20 absolute flex flex-col items-center left-1/2 text-center"
            style={{ transform: "translateX(-50%)" }}
            isLoading={isLoading}
          >
            Initializing..
          </Loader>
          {/* Date Picker */}
          {!isLoading ? (
            activeIntegration ? (
              <div className="mb-4 flex flex-col border rounded">
                <div className="w-full flex-col bg-gray-50 flex px-4 py-2 items-start">
                  <h5 className="mb-1 text-gray-800 text-base font-semibold font-sansSemiBold">
                    Please select relevant period
                  </h5>
                  <p className="text-sm text-gray-700">
                    Select the sales period you want to export data for
                  </p>
                </div>
                <div className="border-t flex flex-col items-start p-3 md:p-4 relative">
                  <label className="text-primaryBlue font-sansSemiBold mb-3">
                    Select period
                  </label>
                  <DateSelection
                    value={selectedPeriod}
                    onChange={setSelectedPeriod}
                    timeValue={selectedTime}
                    onChangeTime={setSelectedTime}
                  />

                  {error && (
                    <div className="text-primaryPink pb-4">{error}</div>
                  )}
                  <button
                    type="submit"
                    disabled={
                      !selectedPeriod.to || !selectedPeriod.from || loading
                    }
                    onClick={exportSalesData}
                    className="button button--autoWidth button--primaryGreen no-truncate mt-4"
                  >
                    Export Sales
                  </button>

                  <Loader
                    isLoading={loading}
                    style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
                  >
                    <div
                      style={{
                        textShadow:
                          "0 0 5px white, 0 0 10px white, 0 0 20px white, 0 0 40px white",
                      }}
                      className="text-sm"
                    >
                      <p>Your sales files are being exported.</p>
                      <p> It may take a few minutes.</p>
                      <p>Please wait.</p>
                    </div>
                  </Loader>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start">
                <h3 className="text-primaryBlue font-bold mb-4 text-lg font-sansBold">
                  You have no active POS Integration.
                </h3>
                <IntegrationsButton />
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ExportLiveSales
