import React, { useState, useEffect, useContext, useMemo } from "react"
import { format } from "date-fns"
import { ModalContext } from "context/modal/ModalContext"
import { usePrevious } from "react-use"
import Helmet from "react-helmet"

import {
  getStockBelowMinQtyReportProducts,
  updateStockBelowMinQtyReport,
  getAllStockBelowMinQtyReports,
  createBelowMinQtyReport,
} from "services/stock-take/stock-take"
import { showError } from "services/toast"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faLongArrowAltDown,
} from "@fortawesome/pro-regular-svg-icons"

import { faCartPlus, faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"

import Loader from "components/common/Loader/Loader"
import SearchInput from "components/forms/SearchInput"
import EmailListModal from "components/stock/EmailListModal/EmailListModal"

//@ts-ignore
import * as styles from "./BelowMinQtyReport.module.css"
import { useMediaQuery } from "react-responsive"
import {
  getAllItemsFromPaginatedResponse,
  groupGlobalProductsBySuppliers,
  roundNumber,
  saveOrder,
} from "services/helpers"
import CartButton from "components/orders/CartButton/CartButton"

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"

import Select from "react-select"
import PlaceOrderModal from "components/common/PlaceOrderModal/PlaceOrderModal"
import ProductsGlobalCartButton from "components/products/ProductsGlobalCartButton/ProductsGlobalCartButton"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import {
  selectedPackagingMatchesOrderedInConfig,
  itemIsOrderedAsSingleAndCase,
} from "../../../services/orders/orders"
import { ProductBelowMinQtyExtended } from "../../../services/typescript-fetch-client/api"
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import usePagination from "hooks/usePagination"

const BelowMinQtyReport = () => {
  const { user, organization, globalOrder } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const [products, setProducts] = useState<ProductBelowMinQtyExtended[]>([])
  const [report, setReport] = useState<any>(null)
  const [query, setQuery] = useState(null)
  const previousQ = usePrevious(query)
  const [loading, setLoading] = useState(true)

  const pagination = usePagination()

  const hasMultipleSuppliers =
    groupGlobalProductsBySuppliers(globalOrder.products).length > 1

  const calculateBasketQty = (inCase, product, nestedProduct) => {
    const absVarianceQty = Math.abs(product.varianceQty)

    return inCase && nestedProduct?.productCase?.size
      ? absVarianceQty / nestedProduct?.productCase?.size
      : absVarianceQty
  }

  //Reactive based on passed newProductQuantity
  const onCartButtonQuantityChange = (
    newProductQuantity: number,
    product,
    productInContext
  ) => {
    if (
      productInContext &&
      (newProductQuantity === 0 || newProductQuantity === "0")
    ) {
      dispatch({
        type: "REMOVE_FROM_GLOBAL_ORDER",
        payload: {
          productId: productInContext.id,
        },
      })
    } else if (
      !productInContext &&
      newProductQuantity !== 0 &&
      newProductQuantity !== "0"
    ) {
      const nestedProduct = product.productsForOrder.find(
        (a) => a.supplierId === product._currentSelectedSupplierId
      )

      const basketQty = calculateBasketQty(
        product._selectedCase === "multiple",
        product,
        nestedProduct
      )

      dispatch({
        type: "ADD_TO_GLOBAL_ORDER",
        payload: {
          product: { ...nestedProduct },
          _selectedCase: product._selectedCase,
          _basketQuantity: basketQty,
          // supplier: currentSupplierOption,
        },
      })
    } else if (
      productInContext &&
      productInContext._basketQuantity !== newProductQuantity
    ) {
      dispatch({
        type: "UPDATE_GLOBAL_ORDER_PRODUCT",
        payload: {
          product: productInContext,
          _basketQuantity: newProductQuantity,
          // supplier: currentSupplierOption,
        },
      })
    }
  }

  const getData = async (renegerate = false, returnAllProducts = false) => {
    setLoading(true)

    const report = await getAllStockBelowMinQtyReports()
    let stockBelowMinQtyReportId

    if (report.content.length === 0) {
      const newReport = await createBelowMinQtyReport()

      if (newReport.status === 404 || newReport.status === 403) {
        setReport(null)
        setLoading(false)
        return
      }

      stockBelowMinQtyReportId = newReport.id
      setReport(newReport)
    } else {
      stockBelowMinQtyReportId = report.content[0].id
      if (renegerate) {
        const updatedReport = await updateStockBelowMinQtyReport(
          stockBelowMinQtyReportId
        )
        setReport(updatedReport)
      } else {
        setReport(report.content[0])
      }
    }

    const params = {
      page: pagination.page,
      size: pagination.size,
      sort: "createdAt,desc",
      addProductsForOrder: true,
      partialProductName: "",
    }

    if (query) {
      params.partialProductName = query
    }

    let products

    if (returnAllProducts) {
      /**
       * If returnAllProducts param is given then iterate through all pages of the response data
       */
      products = await getAllItemsFromPaginatedResponse(
        (params) =>
          getStockBelowMinQtyReportProducts(stockBelowMinQtyReportId, params),
        params
      )
    } else {
      /**
       * Otherwise usePagination will take care of the pagination for us.
       */
      const res = await getStockBelowMinQtyReportProducts(
        stockBelowMinQtyReportId,
        params
      )

      if (res && !res.message) {
        pagination.setFromResult(res)
      }

      products = res.content
    }

    if (products && !products.message) {
      const productsWithExtraProperty = products.map((product: any) => {
        //Pick the first nearest available supplier that has an id as default
        const defaultSupplierExists = product?.productsForOrder.find(
          (curr: any) => !!curr.supplierId
        )
        const defaultSupplierId = defaultSupplierExists?.supplierId
        // const productInContext = product.productsForOrder.find(a => globalOrder.products.find(b => b.id === a.id))
        const productInContext = globalOrder.products.find((a) =>
          product.productsForOrder.find((b) => b.id === a.id)
        )
        const selectedProduct = productInContext
          ? productInContext
          : product.productsForOrder.find(
              (a) => a.supplierId === defaultSupplierId
            )

        return {
          ...product,
          _currentSelectedSupplierId: productInContext
            ? productInContext?.supplierId
            : defaultSupplierId,
          _selectedCase: productInContext
            ? productInContext?._selectedCase
            : selectedProduct?.orderedIn?.both
            ? "single"
            : selectedProduct?.productCase
            ? "multiple"
            : "single",
        }
      })

      setLoading(false)
      if (returnAllProducts) {
        /**
         * When returnAllProduts param is given then we are using getData as a helper func so just return the output
         */
        return productsWithExtraProperty
      } else {
        /**
         * Otherwise we are populating the UI so set the state
         */
        setProducts(productsWithExtraProperty)
      }
    } else {
      showError(report.message)
      setLoading(false)
    }
  }

  const changeSupplier = (productBarcode, newSupplierObject) => {
    setProducts((prevState) => {
      //This is an array of parent prdocuts containing .productsForOrder
      //Goal is to save a product from .productsForOrder into global context with _basketQuantity and _selectedCase

      const temp = [...prevState]

      const foundElement: any = temp.find(
        (curr: any) => curr.barcode === productBarcode
      )
      // const originalElement = {...foundElement};
      if (!foundElement) {
        return prevState
      }

      //This is the product that must be saved to globalOrder
      const selectedProduct = foundElement.productsForOrder.find(
        (curr: any) => curr.supplierId === newSupplierObject.supplierId
      )

      const isInGlobalStateContext = globalOrder.products.find(
        // (curr: any) => curr.id === selectedProduct.id supplierId
        (curr: any) =>
          curr.barcode === selectedProduct.barcode &&
          curr.supplierId === foundElement._currentSelectedSupplierId
      )

      foundElement._currentSelectedSupplierId = newSupplierObject.supplierId
      foundElement._selectedCase = selectedProduct?.orderedIn?.both
        ? "single"
        : selectedProduct.productCase
        ? "multiple"
        : "single"

      if (isInGlobalStateContext) {
        dispatch({
          type: "UPDATE_GLOBAL_ORDER_PRODUCT",
          payload: {
            product: isInGlobalStateContext,
            // replaceWith: matchedFromContext,
            replaceWith: {
              ...selectedProduct,
              _selectedCase: selectedProduct.productCase
                ? "multiple"
                : "single",
              _basketQuantity: isInGlobalStateContext._basketQuantity,
            },
            // _currentSelectedSupplierId: newSupplierObject.supplierId,
            // _selectedCase: selectedProduct.productCase ? "multiple" : "single"
          },
        })
      }
      return temp
    })
  }

  const changeProductCase = (
    event: React.ChangeEvent<HTMLSelectElement>,
    product: ProductBelowMinQtyExtended
  ) => {
    if (!product.productsForOrder?.length) return // do nothing if no available supplier

    const newValue = event.target.value
    const newRowData = { ...product, _selectedCase: newValue }

    const isInGlobalStateContext = globalOrder.products.find(
      (curr: any) => curr.barcode === newRowData.barcode
    )

    const currentSupplierProduct = product.productsForOrder.find(
      (curr: any) => curr.supplierId === product._currentSelectedSupplierId
    )

    const currentProduct = currentSupplierProduct
      ? {
          ...currentSupplierProduct,
          _selectedCase: newValue,
        }
      : {
          ...product.productsForOrder[0],
          _selectedCase: newValue,
        }

    if (selectedPackagingMatchesOrderedInConfig(currentProduct)) {
      const basketQty = calculateBasketQty(
        newValue === "multiple",
        product,
        currentProduct
      )

      //Update the product in global state
      isInGlobalStateContext &&
        dispatch({
          type: "UPDATE_GLOBAL_ORDER_PRODUCT",
          payload: {
            product: isInGlobalStateContext,
            _selectedCase: newValue,
            _basketQuantity: basketQty,
          },
        })
    } else {
      if (isInGlobalStateContext) {
        // Let the user know the cart item was not updated.
        showError(
          "You're not allowed to order the product in this package type"
        )
      }
    }

    setProducts((prevState) => {
      const temp = [...prevState]
      const foundElement: any = temp.find(
        (curr: any) => curr.barcode === newRowData.barcode
      )

      if (!foundElement) {
        return prevState
      }

      foundElement._selectedCase = newValue

      return temp
    })
  }

  const addAllProductsToBasket = async () => {
    const products = await getData(false, true)

    dispatch({
      type: "ADD_MULTIPLE_TO_GLOBAL_ORDER",
      payload: {
        products: products.map((globalProduct: any) => {
          const nestedProduct = globalProduct.productsForOrder.find(
            (a) => a.supplierId === globalProduct._currentSelectedSupplierId
          )

          const basketQty = calculateBasketQty(
            globalProduct._selectedCase === "multiple",
            globalProduct,
            nestedProduct
          )

          return {
            ...globalProduct.productsForOrder.find(
              (a) => a.supplierId === globalProduct._currentSelectedSupplierId
            ),
            _basketQuantity: basketQty,
            _selectedCase: globalProduct._selectedCase,
          }
        }),
      },
    })
  }

  const onRefresh = () => getData(true)

  const onSendEmailList = () => {
    modal.showModal(EmailListModal, {
      stockBelowMinQtyReportId: report?.id,
    })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (query || previousQ) {
      getData()
    }
  }, [query])

  useEffect(() => {
    getData()
  }, [pagination.page])

  const countProductsBelowMinQty = useMemo(() => {
    return products.reduce((acc, product: any) => {
      if (product.currentQty < product.minQtyInStock) {
        return acc + 1
      }
      return acc
    }, 0)
  }, [products])

  // const totalItems = roundNumber(
  //   globalOrder.products.reduce(
  //     (accumulator, current) => (accumulator += current.quantity),
  //     0
  //   )
  // )
  const totalUniqueProductsInBasket = globalOrder?.products?.length
  const totalCost = roundNumber(
    globalOrder.products.reduce((totalPrice, product) => {
      const shouldCalculateCase = product._selectedCase === "multiple"
      const pr = product

      if (!pr) {
        return (totalPrice += 0)
      }

      return (totalPrice +=
        (shouldCalculateCase ? pr.productCase.price : pr.price) *
        product._basketQuantity)
    }, 0)
  )

  return (
    <>
      <Helmet>
        <title>Below Min Stock Qty Report</title>
      </Helmet>

      <div className={styles.container}>
        <div className="flex-1 sm:mr-6">
          <div
            // className={styles.content}
            className="pb-5 border border-gray-300 rounded-lg"
          >
            <div className="flex items-center px-4 py-3 bg-gray-100 rounded-lg">
              <h2 className="text-xl">Products</h2>
              <div className="flex items-center justify-center flex-1">
                <div className="flex items-center justify-center h-full px-4 rounded-md">
                  <div className="flex justify-center space-x-4">
                    {!loading && (
                      <>
                        <div className="flex flex-col items-center sm:flex-row">
                          <div
                            className={`flex font-semibold ${
                              countProductsBelowMinQty > 0
                                ? "text-error"
                                : "text-primaryGreen"
                            }`}
                          >
                            <span className="text-2xl">
                              {countProductsBelowMinQty || 0}
                            </span>
                            {countProductsBelowMinQty > 0 && (
                              <FontAwesomeIcon
                                size="lg"
                                icon={faLongArrowAltDown}
                                className="ml-1 mt-1.5"
                              />
                            )}
                          </div>
                          <span
                            className={`${
                              countProductsBelowMinQty > 0
                                ? "text-error"
                                : "text-primaryGreen"
                            } font-semibold text-sm ml-4`}
                          >
                            products below min qty
                          </span>
                        </div>
                        {report && (
                          <div className="flex flex-col items-center sm:flex-row">
                            <span className="mr-2 text-sm font-semibold text-gray-700">
                              last updated:
                            </span>
                            <span className="font-semibold text-primaryBlue">
                              {format(
                                new Date(
                                  report.updatedAt
                                    ? report.updatedAt
                                    : report.createdAt
                                ),
                                "dd/MM/yy"
                              )}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>{/* Intentional Empty Flex Div */}</div>
            </div>
            <hr className="mb-4" />

            <div className="flex flex-col space-y-2">
              <div className={styles.headerProducts}>
                <div className="flex flex-col w-full space-y-2 2xl:flex-row 2xl:space-y-0">
                  <div className="flex flex-1 w-full my-1 mr-2 sm:self-start">
                    <SearchInput
                      label="Search by item name"
                      resetVal={null}
                      placeholder={
                        isTabletOrMobile ? "Search" : "Search by item name"
                      }
                      className="w-full rounded form-control 2xl:max-w-lg"
                      onSearchChange={(value) => {
                        setQuery(value)
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-between sm:justify-center">
                    <button
                      className="flex-1 mr-2 sm:flex-auto button button--autoWidth button--lightBlue sm:mr-5"
                      onClick={onRefresh}
                    >
                      <FontAwesomeIcon icon={faSyncAlt} className="sm:mr-2" />
                      <span className="hidden sm:inline">Refresh list</span>
                    </button>
                    {report && (
                      <button
                        className="flex-1 mr-2 sm:flex-auto button button--autoWidth button--lightBlue sm:mr-5"
                        onClick={onSendEmailList}
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </button>
                    )}
                    <button
                      onClick={() => addAllProductsToBasket(products)}
                      className="flex items-center justify-center flex-1 p-4 space-x-2 text-white transition-colors border rounded-md shadow-sm sm:flex-auto bg-primaryBlue hover:bg-opacity-70 sm:px-6 sm:py-3"
                      style={{
                        height: 45,
                      }}
                    >
                      <FontAwesomeIcon icon={faCartPlus} />
                      <span className="flex text-xs font-semibold">
                        <span className="hidden whitespace-pre sm:inline-block">
                          Select{" "}
                        </span>{" "}
                        All
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="relative flex w-full"
                style={{ minHeight: "143px" }}
              >
                {!loading ? (
                  <div className="flex flex-col w-full">
                    {products && products.length > 0 ? (
                      products.map((product: any, index: number) => {
                        const availableProductSuppliersOptions =
                          product.productsForOrder.map((curr: any) => {
                            return {
                              value: curr.supplierName,
                              label: curr.supplierName,
                              supplierId: curr.supplierId,
                              supplierName: curr.supplierName,
                            }
                          })
                        const hasAvailableSuppliers =
                          product.productsForOrder.length > 0
                        const productInContext = globalOrder?.products.find(
                          (curr: any) =>
                            curr.barcode === product.barcode &&
                            product._currentSelectedSupplierId ===
                              curr.supplierId
                        )
                        const currentSupplierProduct =
                          product.productsForOrder.find(
                            (curr: any) =>
                              curr.supplierId ===
                              product._currentSelectedSupplierId
                          )
                        const currentSupplierOption = currentSupplierProduct
                          ? {
                              supplierId: currentSupplierProduct.supplierId,
                              supplierName: currentSupplierProduct.supplierName,
                              value: currentSupplierProduct.supplierName,
                              label: currentSupplierProduct.supplierName,
                            }
                          : null
                        const productCase = product._selectedCase
                        const productPrice =
                          productCase === "multiple"
                            ? roundNumber(
                                currentSupplierProduct.productCase.price
                              )
                            : roundNumber(currentSupplierProduct?.price)

                        const currentProduct = currentSupplierProduct
                          ? {
                              ...currentSupplierProduct,
                              _selectedCase: productCase,
                            }
                          : hasAvailableSuppliers
                          ? {
                              ...product.productsForOrder[0],
                              _selectedCase: productCase,
                            }
                          : {}

                        const shouldDisableAddToCart =
                          !selectedPackagingMatchesOrderedInConfig(
                            currentProduct
                          )

                        return (
                          <div
                            className={styles.productItem}
                            key={
                              product.barcode +
                                "#" +
                                currentSupplierProduct?.id || index
                            }
                          >
                            <div className="flex flex-col justify-center flex-1 mt-1 mb-2 mr-4">
                              <span className="text-primaryBlue font-sansBold">
                                {product.name}
                              </span>
                              <div className="flex space-x-2">
                                <div className="flex items-center space-x-2">
                                  <span className={styles.qtyLabel}>
                                    Min qty
                                  </span>
                                  <span className={styles.stockLabel}>
                                    {product.minQtyInStock}
                                  </span>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <span className={styles.qtyLabel}>
                                    Current qty
                                  </span>
                                  <div className="flex">
                                    <span
                                      className={`${styles.stockLabel} mr-3`}
                                    >
                                      {roundNumber(product.currentQty)}
                                    </span>
                                    <div className="flex text-error">
                                      <span className="text-sm font-semibold">
                                        {roundNumber(product.varianceQty)}
                                      </span>
                                      <FontAwesomeIcon
                                        icon={faLongArrowAltDown}
                                        className="ml-1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col sm:flex-row">
                              <div className="flex-grow flex justify-between my-2 space-x-2 md:flex-nowrap xl:mr-12 sm:space-x-4 md:my-0">
                                <div className="flex flex-col">
                                  <span className={styles.qtyLabel}>
                                    Supplier
                                  </span>
                                  <div className="flex">
                                    <Select
                                      noOptionsMessage={() => null}
                                      placeholder={
                                        hasAvailableSuppliers
                                          ? "Suppliers"
                                          : "---"
                                      }
                                      className="w-28"
                                      options={
                                        availableProductSuppliersOptions.length >
                                        1
                                          ? availableProductSuppliersOptions
                                          : []
                                      }
                                      value={currentSupplierOption}
                                      isSearchable={false}
                                      onChange={(value) => {
                                        changeSupplier(product.barcode, value)
                                      }}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor: state.isFocused
                                            ? "grey"
                                            : "transparent",
                                          border: 0,
                                          boxShadow: "none",
                                        }),
                                        dropdownIndicator: (baseStyles) => ({
                                          ...baseStyles,
                                          display:
                                            availableProductSuppliersOptions.length >
                                            1
                                              ? "block"
                                              : "none",
                                        }),
                                        indicatorSeparator: (base) => ({
                                          ...base,
                                          display: "none",
                                        }),
                                        menu: (base) => ({
                                          ...base,
                                          fontSize: "13px",
                                          marginTop: 0,
                                        }),
                                        valueContainer: (base) => ({
                                          ...base,
                                          display: "inline-block",
                                          padding: 0,
                                          fontSize: 13,
                                          height: "auto",
                                          width: "auto",
                                          overflow: "visible",
                                          // whiteSpace: "pre-wrap",
                                          // textDecoration: 'underline',
                                          // border: "1px solid red",
                                        }),
                                        singleValue: (base) => ({
                                          ...base,
                                          textDecoration:
                                            availableProductSuppliersOptions.length >
                                            1
                                              ? "underline"
                                              : "none",
                                          fontWeight:
                                            availableProductSuppliersOptions.length >
                                            1
                                              ? "bold"
                                              : "regular",
                                          whiteSpace: "pre-line",
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col">
                                  <span className={styles.qtyLabel}>Pack</span>
                                  <div className="flex items-center w-auto sm:w-36">
                                    <ProductIcon
                                      product={product}
                                      inCase={
                                        product._selectedCase === "multiple"
                                      }
                                    ></ProductIcon>
                                    {currentSupplierProduct?.productCase &&
                                    itemIsOrderedAsSingleAndCase(
                                      currentProduct
                                    ) ? ( // only display package select if product has case config and also can be ordered as both single and case
                                      <select
                                        className="w-auto pl-2 pr-6 text-sm font-semibold bg-transparent border-0 font-sansSemiBold text-primaryBlue"
                                        onChange={(event) => {
                                          changeProductCase(event, product)
                                        }}
                                      >
                                        <option
                                          value="single"
                                          selected={
                                            productInContext
                                              ? productInContext?._selectedCase ===
                                                "single"
                                              : product._selectedCase ===
                                                "single"
                                          }
                                        >
                                          {product.size} {product.measure}
                                        </option>
                                        <option
                                          value="multiple"
                                          selected={
                                            productInContext
                                              ? productInContext?._selectedCase ===
                                                "multiple"
                                              : product._selectedCase ===
                                                "multiple"
                                          }
                                        >
                                          {
                                            currentSupplierProduct?.productCase
                                              ?.size
                                          }{" "}
                                          x {product.size} {product.measure}
                                        </option>
                                      </select>
                                    ) : (
                                      <span className="w-auto pl-2 pr-6 text-sm font-semibold bg-transparent border-0 font-sansSemiBold text-primaryBlue">
                                        {product._selectedCase ===
                                        "multiple" ? (
                                          <>
                                            {
                                              currentSupplierProduct
                                                ?.productCase?.size
                                            }{" "}
                                            x {product.size} {product.measure}
                                          </>
                                        ) : (
                                          <>
                                            {product.size} {product.measure}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-col">
                                  <span className={styles.qtyLabel}>Price</span>
                                  <div className="flex mr-4 w-14">
                                    {productPrice ? (
                                      <span>{productPrice}</span>
                                    ) : (
                                      <span className="inline-block mt-2 text-sm font-thin leading-none text-gray-600">
                                        ---
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center w-28">
                                {hasAvailableSuppliers && (
                                  <CartButton
                                    initialValue={Math.ceil(
                                      Math.abs(product.varianceQty)
                                    )}
                                    allowFloat
                                    quantity={productInContext?._basketQuantity}
                                    onChange={(newQuantity) =>
                                      onCartButtonQuantityChange(
                                        newQuantity,
                                        product,
                                        productInContext
                                      )
                                    }
                                    disabled={shouldDisableAddToCart}
                                    tooltip={
                                      shouldDisableAddToCart
                                        ? "You're not allowed to order the product in this package type"
                                        : undefined
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <span className="px-4 mt-3 font-semibold text-primaryBlue">
                        There are no items with less than min qty required
                      </span>
                    )}
                    {products.length > 0 && (
                      <div className="flex flex-col justify-between px-4 mt-3 sm:mt-0 sm:flex-row md:hidden">
                        <button
                          className="font-semibold text-primaryPink w-max self-center"
                          onClick={() => {
                            dispatch({ type: "CLEAR_GLOBAL_ORDER_PRODUCT" })
                          }}
                        >
                          Cancel Selection
                        </button>

                        <div className="flex items-center justify-center my-4 space-x-4">
                          <span className="text-gray-500">
                            <span className="font-semibold text-primaryBlue">
                              {totalUniqueProductsInBasket} products
                            </span>{" "}
                            selected
                          </span>
                          <span className="text-gray-500">
                            <span className="font-semibold text-primaryBlue">
                              {totalCost} cost
                            </span>{" "}
                            total
                          </span>
                          <button
                            disabled={!globalOrder.products.length}
                            onClick={() =>
                              modal.showModal(PlaceOrderModal, {
                                title: `Create draft order${
                                  hasMultipleSuppliers ? "s" : ""
                                }`,
                                text: "This action will place orders in DRAFT status for all the items that you have selected, for each supplier",
                                askToNotifySupplier: false,
                                onConfirm: () =>
                                  saveOrder(
                                    "DRAFT",
                                    true,
                                    groupGlobalProductsBySuppliers(
                                      globalOrder.products
                                    ),
                                    user,
                                    organization
                                  ),
                              })
                            }
                            className="button button--autoWidth flex justify-center items-center space-x-2"
                          >
                            <FontAwesomeIcon icon={faShoppingCart} />
                            <span>
                              Create draft order
                              {hasMultipleSuppliers && "s"}
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader isLoading={loading}>
                    Loading the below stock quantity report...
                  </Loader>
                )}
              </div>
            </div>
            <div></div>
          </div>
          {pagination.component}
          <ProductsGlobalCartButton />
        </div>
      </div>
    </>
  )
}

export default BelowMinQtyReport
