import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

import * as styles from "./EditDetails.module.css"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import {
  convertToTimeZoneDate,
  convertToTimeZoneUTCString,
  formatDateAsTime,
} from "services/helpers"
import DateSelection from "components/forms/DateSelection"
import { StockTakeReport } from "services/stock-take/types"
import { set } from "date-fns"

interface EditStocktakeDetailsProps {
  stocktake: StockTakeReport
  onSave: (data: { name: string; date: string }) => void
  isSaving: boolean
}

const EditDetails = ({
  stocktake,
  onSave,
  isSaving,
}: EditStocktakeDetailsProps) => {
  const inputRef = useRef(null)
  const [name, setName] = useState(stocktake.name ? stocktake.name : "")

  const { organization }: { organization: any } = useContext(GlobalStateContext)

  const [date, setDate] = useState<string | Date | undefined>(
    stocktake.completedAt
      ? new Date(
          convertToTimeZoneDate(
            stocktake.completedAt,
            organization.address.zoneId
          )
        )
      : ""
  )

  const [time, setTime] = useState<string>(
    stocktake.completedAt
      ? formatDateAsTime(
          convertToTimeZoneDate(
            stocktake.completedAt,
            organization.address.zoneId
          )
        )
      : ""
  )

  //
  // Handlers
  //

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setName(value)
  }

  const handleSave = useCallback(() => {
    if (name && date && time) {
      const [hours, minutes] = time.split(":").map(Number) // "12:45" -> [12, 45]

      const convertedDateAndTime = convertToTimeZoneUTCString(
        set(new Date(date), {
          hours: hours,
          minutes: minutes,
          seconds: 0,
          milliseconds: 0,
        }),
        organization.address.zoneId
      )

      onSave({ name, date: convertedDateAndTime })
    }
  }, [name, date, time])

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  const setCursorAtTheEnd = useCallback(() => {
    window.setTimeout(() => {
      const el = inputRef.current
      if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length
      } else if (typeof el.createTextRange != "undefined") {
        el.focus()
        const range = el.createTextRange()
        range.collapse(false)
        range.select()
      }
    }, 1)
  }, [])

  //
  // Renders
  //

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="px-2 w-full sm:w-1/2">
          <label className="text-primaryBlue font-sansSemiBold mb-3">
            Stocktake name
          </label>
          <input
            ref={inputRef}
            type="text"
            className={`block border-0 px-0 form-control ${styles.input}`}
            onFocus={setCursorAtTheEnd}
            value={name || ""}
            onChange={handleInputChange}
            placeholder={`Add number or leave empty`}
          />
        </div>
        <div className="px-2 w-full sm:w-1/2">
          <label className="text-primaryBlue font-sansSemiBold mb-3">
            Date stocktake fininalised
          </label>
          <DateSelection
            value={{ from: date, to: undefined, enteredTo: undefined }}
            onChange={(date) => {
              setDate(date.from)
            }}
            timeValue={{ from: time }}
            onChangeTime={(newTime) => setTime(newTime.from)}
            mode="single"
            disable={{ after: new Date() }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <button
          onClick={handleSave}
          className="button button--primaryBlue"
          disabled={isSaving}
        >
          {isSaving && (
            <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
          )}
          Save
        </button>
      </div>
    </div>
  )
}

export default EditDetails
