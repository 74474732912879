import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/pro-regular-svg-icons"
import usePermissions from "hooks/usePermissions"
import { navigate } from "@reach/router"
import { Permission } from "services/types"
import IntegrationsButton from "../IntegrationsButton/IntegrationsButton"
import { integrationConfig } from "services/constants"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { integration } from "services/integrations"

const PosImportButton = () => {
  const { integrations } = useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState(true)
  const [activeIntegration, setActiveIntegration] = useState<string | null>(
    null
  )
  const permissionObj = usePermissions("Sales") as Permission

  /** Fetch activated integrations on mount */
  useEffect(() => {
    const abortController = new AbortController()

    const active = integrations?.posIntegrations.find(
      (integration: integration) => integration.status === "ACTIVE"
    )
    setActiveIntegration(active ? active.type : null)
    setIsLoading(false)

    return () => {
      setIsLoading(false)
      abortController.abort()
    }
  }, [])

  /** For GOEPOS there is no ned to import as this data is being imported automatically */
  const isAutomaticIntegration =
    activeIntegration && activeIntegration === "GOEPOS"

  return isLoading || isAutomaticIntegration ? null : activeIntegration ? (
    <button
      onClick={() => navigate("/dashboard/sales/pos-import")}
      disabled={!permissionObj?.permissions.modify}
      className="button button--autoWidth button--white"
    >
      <span className="flex items-center h-full flex-shrink-0">
        <img
          src={integrationConfig[activeIntegration].logo}
          className="w-5 mr-2"
        />
        <span className="capitalize">{activeIntegration.toLowerCase()}</span>
        <FontAwesomeIcon icon={faSync} size="sm" className="ml-2" />
      </span>
    </button>
  ) : (
    <IntegrationsButton />
  )
}

export default PosImportButton
