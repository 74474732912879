/* eslint-disable */
import React, { useState, useContext, useCallback, useEffect } from "react"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import Modal from "react-modal"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faPencil,
  faPlus,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons"
import { faLock, faHistory } from "@fortawesome/pro-solid-svg-icons"
import { faList, faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import {
  faRocket,
  faExclamationTriangle,
} from "@fortawesome/pro-duotone-svg-icons"
import { showError, showSuccess } from "services/toast"
import { roundNumber, uuidv4, convertToGMT } from "services/helpers"
import { useDebounce } from "react-use"
import classNames from "classnames/bind"
import { updateOnboardingSteps } from "services/onboarding"
import {
  createStockTake,
  createSubStockTake,
  updateStockTake,
  updateSubStockTake,
  getStockTakeProducts,
  getSubStockTakeProducts,
  addStockTakeProducts,
  addSubStockTakeProducts,
  deleteStockTakeProduct,
  deleteSubStockTakeProduct,
  updateStockTakeProduct,
  updateSubStockTakeProduct,
  getRecipesFromMainReport,
  getRecipesFromSubStockTakeReport,
  addStockTakeRecipes,
  addSubStockTakeRecipes,
  updateRecipeInMainReport,
  updateRecipeInSubStockTakeReport,
  deleteRecipeFromMainReport,
  deleteRecipeFromSubStockTakeReport,
  completeStockTake,
  completeSubStockTake,
  deleteStockTake,
  deleteSubStockTake,
  mergeStockTake,
  getStockTake,
  getSubStockTake,
  getStockTakeSheet,
} from "services/stock-take/stock-take"
import StockTakeHeader from "components/stock/StockTakeHeader/StockTakeHeader"
import StockTakeGroup from "components/stock/StockTakeGroup/StockTakeGroup"
import StockTakeContent from "components/stock/StockTakeContent/StockTakeContent"
import ConfirmModalStocktake from "components/stock/ConfirmModalStocktake/ConfirmModalStocktake"
import OptionsPopup from "components/common/OptionsPopup/OptionsPopup"
import EditName from "components/stock/EditName/EditName"
import EditDetails from "components/stock/EditDetails/EditDetails"

import * as styles from "./StockTake.module.css"
import { navigate } from "gatsby"
import { isToday } from "date-fns"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { productBarcodeRegexWithSpaces } from "services/products/products"
import ReportModal from "../ReportModal/ReportModal"

const cx = classNames.bind(styles)

const StockTake = ({ onRequestClose, ...otherProps }) => {
  const modal = useContext(ModalContext)
  const stockTakesPermissionObj = usePermissions("Stocktakes") as Permission
  const discrepancyReportsPermissionObj = usePermissions(
    "Stocktakes"
  ) as Permission
  const [loading, setLoading] = useState(false)
  const { newStockTake }: { newStockTake: any } = useContext(GlobalStateContext)
  const [selectedStockTake, setSelectedStockTake] = useState(null) as any
  const [query, setQuery] = useState("")
  const [stockTakeToEdit, setStockTakeToEdit] = useState(false) as any
  const [popupTitle, setPopupTitle] = useState("")
  const [syncing, setSyncing] = useState(false)
  const [newReport, setNewReport] = useState(false) as any
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 999,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })

  const [stockTakeSheet, setStockTakeSheet] = useState<any>(null)
  const [openStockSheetSelect, setOpenStockSheetSelect] = useState(false)
  const [onExit, setOnExit] = useState(null) as any
  const [exitModelId, setExitModelId] = useState("")

  const { onboardingSteps } = useContext(GlobalStateContext)

  useEffect(() => {
    // Reset product list page to zero
    setPaginationData({
      ...paginationData,
      page: 0,
    })
  }, [])

  useEffect(() => {
    if (selectedStockTake) {
      const isParent = selectedStockTake.key === newStockTake.key
      if (isParent) {
        setSelectedStockTake(newStockTake)
      } else {
        setSelectedStockTake(
          newStockTake.subStockTakeReports.find(
            (st: any) => st.key === selectedStockTake.key
          )
        )
      }
    } else {
      if (newStockTake.id) {
        // If stocktake already exists show overview of substocktakes,
        // don't select any subreport yet on first loading
        setSelectedStockTake(null)
      } else {
        // If stocktake exists, select first one
        setSelectedStockTake(newStockTake.subStockTakeReports[0])
      }
    }
  }, [newStockTake])

  useEffect(() => {
    const getData = async () => {
      if (selectedStockTake) {
        const isParent = selectedStockTake.subStockTakeReports

        if (isParent) {
          getUpdatedProductList(newStockTake.id)
          getUpdatedRecipeList(newStockTake.id)
        } else {
          // Else get substock report products
          getUpdatedProductList(newStockTake.id, selectedStockTake.id)
          getUpdatedRecipeList(newStockTake.id, selectedStockTake.id)
        }

        const stockTakeData = isParent
          ? await getStockTake(selectedStockTake.id)
          : await getSubStockTake(newStockTake.id, selectedStockTake.id)

        if (stockTakeData.stockTakeTemplateId) {
          const stockTakeSheetData = await getStockTakeSheet(
            stockTakeData.stockTakeTemplateId
          )
          if (stockTakeSheetData.status !== 404) {
            setStockTakeSheet(stockTakeSheetData)
          }
        }
      }
    }
    getData()
  }, [selectedStockTake?.id])

  useEffect(() => {
    if (!syncing) {
      if (onExit !== null) {
        updateSaveInProgressModel()
      }
    }
  }, [syncing])

  const startSyncing = () => {
    setSyncing(true)
  }

  const checkSyncBeforeleave = (exitFn) => {
    if (syncing) {
      const id = modal.showModal(ConfirmModal, {
        type: "danger",
        title: `Saving in progress`,
        text: "Are you sure you want close before saving is finshed?",
        confirmButtonText: "Yes, Leave",
        onConfirm: () => {
          setExitModelId(""), setOnExit(null), exitFn()
        },
        onCancel: () => setOnExit(null),
      })
      setExitModelId(id)
      setOnExit(() => exitFn)
    } else {
      exitFn()
    }
  }

  const updateSaveInProgressModel = () => {
    modal.updateModal(exitModelId, ConfirmModal, {
      type: "danger",
      title: `Saving complete`,
      text: "You can safely continue now",
      confirmButtonText: "Continue",
      showCancel: false,
      onConfirm: () => {
        onExit(), setExitModelId(""), setOnExit(null)
      },
    })
  }

  const onRequestCloseSelf = () => {
    checkSyncBeforeleave(() => {
      emitUpdate()
      onRequestClose()
    })
  }

  const hideOptions = useCallback(() => {
    setStockTakeToEdit(false)
    setNewReport(false)
    // Wait for animation to be done
    setTimeout(() => {
      setPopupTitle("")
    }, 300)
  }, [])

  const [,] = useDebounce(
    () => {
      saveFullStockTake()
    },
    2500,
    [newStockTake]
  )

  const saveFullStockTake = async () => {
    const stocktake = newStockTake
    if (stocktake.unsynced) {
      // sync main stocktake
      await saveStockTake(stocktake, false)
    }

    if (stocktake.id && stocktake.id == selectedStockTake?.id) {
      await syncProductAndRecipeList(stocktake.id)
    }

    // Only sync subs if parent is saved
    if (stocktake.id) {
      for (let i = 0; i < stocktake.subStockTakeReports.length; i++) {
        const subST: any = stocktake.subStockTakeReports[i]
        if (subST.unsynced) {
          await saveStockTake(subST, true)
        }
        if (subST.id && subST.id == selectedStockTake?.id) {
          await syncProductAndRecipeList(subST.id, true)
        }
      }
    }
  }

  const dispatch = useContext(GlobalDispatchContext)

  const onEditOptions = (stocktake) => {
    setStockTakeToEdit(stocktake)
    setPopupTitle("Edit stocktake report")
  }

  const closeStockSheetSelect = () => {
    setOpenStockSheetSelect(false)
    emitUpdate()
  }

  const completeAllStockTakesMessage = () => (
    <div className={cx("message", "primary")}>
      <FontAwesomeIcon icon={faRocket} />
      <div>
        <h3 className={styles.messageTitle}>You&apos;re on your way!</h3>
        <p>Make sure to complete all groups when you&apos;re finished</p>
      </div>
    </div>
  )

  const completeParentStockTakeMessage = () => (
    <div className={cx("message", "success")}>
      <div>
        <h3 className={styles.messageTitle}>Almost done!</h3>
        <p>All groups are completed. Do you want to finalise this report?</p>
      </div>
      <div className="flex justify-between">
        <button
          className="button button--primaryGreen button--autoWidth"
          disabled={!stockTakesPermissionObj?.permissions.modify}
          onClick={() => finaliseStockTakeConfirm(newStockTake)}
        >
          <FontAwesomeIcon icon={faCheck} className="mr-2" />
          Finalise
        </button>
      </div>
    </div>
  )

  const discrepancyReportMessage = () => (
    <div className={cx("message", "warning")}>
      <div>
        <h3 className={styles.messageTitle}>You&apos;re done!</h3>
        <p>Do a check on your losses/profits and run a discrepancy report</p>
      </div>
      <div className="flex">
        <button
          className="button button--orange button--autoWidth"
          disabled={!discrepancyReportsPermissionObj?.permissions.modify}
          onClick={() => onCreateDiscrepancyReport(newStockTake)}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          Create discrepancy report
        </button>
      </div>
    </div>
  )

  const discrepancyReportCreatedMessage = () => {
    const hasDiscrepancies = newStockTake.totalDiscrepancyAmount !== 0
    return (
      <div
        className={cx("message", {
          warning: hasDiscrepancies,
          success: !hasDiscrepancies,
        })}
      >
        <div>
          <h3 className={styles.messageTitle}>
            {hasDiscrepancies ? "You have discrepancies!" : "All good!"}
          </h3>
          {hasDiscrepancies ? (
            <p>
              The stock discrepancy value for this period is:
              <br />
              <strong>
                {roundNumber(newStockTake.totalDiscrepancyAmount)}
              </strong>
            </p>
          ) : (
            <p>
              Based on the report you generated earlier, you don&apos;t have any
              discrepancies, this is your discrepancy value:
              <br />
              <strong>
                {roundNumber(newStockTake.totalDiscrepancyAmount)}
              </strong>
            </p>
          )}
        </div>
        <button
          className={cx("button button--autoWidth", {
            "button--orange": hasDiscrepancies,
            "button--primaryGreen": !hasDiscrepancies,
          })}
          disabled={!discrepancyReportsPermissionObj?.permissions.read}
          onClick={() => {
            onRequestCloseSelf()
            navigate(
              `/dashboard/stock/stocktake/discrepancy-report/${newStockTake.discrepancyReportId}`
            )
          }}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          View report
        </button>
      </div>
    )
  }

  const showMessages = () => {
    const hasUnCompletedSubStockTakes =
      newStockTake.subStockTakeReports &&
      newStockTake.subStockTakeReports.filter((st) => st.status !== "COMPLETED")
        .length > 0

    const hasProducts =
      newStockTake.totalAmount > 0 ||
      newStockTake.subStockTakeReports.filter((st) => st.products.length > 0)
        .length > 0

    const parentCompleted = newStockTake.status === "COMPLETED"

    if (hasProducts && hasUnCompletedSubStockTakes) {
      return completeAllStockTakesMessage()
    }
    if (!parentCompleted && hasProducts && !hasUnCompletedSubStockTakes) {
      return completeParentStockTakeMessage()
    }
    if (parentCompleted && !newStockTake.discrepancyReportId) {
      return discrepancyReportMessage()
    }

    if (newStockTake.discrepancyReportId) {
      return discrepancyReportCreatedMessage()
    }
  }

  const onEditFinalisedStocktake = ({ name, date }) => {
    const isParent = stockTakeToEdit.subStockTakeReports
    dispatch({
      type: isParent
        ? "UPDATE_NEW_STOCKTAKE"
        : "UPDATE_NEW_STOCKTAKE_SUBREPORT",
      payload: {
        stockTakeData: {
          name: name,
          completedAt: date,
          unsynced: true,
        },
        subStockTakeKey: stockTakeToEdit.key,
      },
    })
    hideOptions()
  }

  const onEditStocktakeInProgress = (name) => {
    const isParent = stockTakeToEdit.subStockTakeReports
    dispatch({
      type: isParent
        ? "UPDATE_NEW_STOCKTAKE"
        : "UPDATE_NEW_STOCKTAKE_SUBREPORT",
      payload: {
        stockTakeData: {
          name,
          unsynced: true,
        },
        subStockTakeKey: stockTakeToEdit.key,
      },
    })
    hideOptions()
  }

  const addReport = (name) => {
    const report = { ...newReport, name: name }
    setNewReport(report)
    dispatch({
      type: "CREATE_NEW_STOCKTAKE_SUBREPORT",
      payload: {
        stockTakeData: report,
      },
    })
    hideOptions()
  }

  const getUnsyncedItems = (
    stockTake,
    type = "products",
    actions = ["add", "update", "remove"]
  ) => {
    return stockTake[type]
      ? stockTake[type].filter(
          (item) =>
            actions.filter((a) => item?.unsynced?.[a] === true).length > 0
        )
      : []
  }

  const hasUnsyncedProducts = (stockTake) => {
    return getUnsyncedItems(stockTake, "products").length > 0
  }

  const hasUnsyncedRecipes = (stockTake) => {
    return getUnsyncedItems(stockTake, "recipes").length > 0
  }

  const hasUnsavedChanges = () => {
    const unsyncedParentReport =
      !newStockTake.id ||
      newStockTake.unsynced ||
      hasUnsyncedProducts(newStockTake) ||
      hasUnsyncedRecipes(newStockTake)
    let unSyncedChildReports = false

    for (let i = 0; i < newStockTake.subStockTakeReports.length; i++) {
      const subST = newStockTake.subStockTakeReports[i]
      if (
        !subST.createdAt ||
        subST.unsynced ||
        hasUnsyncedProducts(subST) ||
        hasUnsyncedRecipes(subST)
      ) {
        unSyncedChildReports = true
      }
    }

    return unsyncedParentReport || unSyncedChildReports
  }

  const emitUpdate = () => {
    if (otherProps.onUpdate && stockTakesPermissionObj?.permissions.modify) {
      otherProps.onUpdate()
    }
  }

  const onUpdate = (stockTakeId, isSub) => {
    if (!stockTakesPermissionObj?.permissions.modify) {
      return
    }
    syncProductAndRecipeList(stockTakeId, isSub)
  }

  const afterSave = (stockTake, result, isSub) => {
    if (result && result.createdAt && result.status !== 500) {
      setLoading(false)
      emitUpdate()
      // disable this call as it was causing duplicated sync product requests
      // onUpdate(stockTake.id, isSub)
      updateState(result, stockTake.key, isSub)
    } else {
      setLoading(false)
    }
  }

  const saveStockTake = async (stockTake, isSub = false) => {
    setLoading(true)

    let params = {
      name: stockTake.name,
      note: stockTake.note,
      completedAt: stockTake.completedAt,
      stockTakeTemplateId: stockTake.stockTakeTemplateId
        ? stockTake.stockTakeTemplateId
        : null,
    }
    let result

    if (stockTake.id) {
      result = isSub
        ? await updateSubStockTake(newStockTake.id, stockTake.id, params)
        : await updateStockTake(stockTake.id, params)
      return afterSave(stockTake, result, isSub)
    } else if (!stockTake.id && !isSub) {
      // First time also save subStockTake directly (is always first (index = 0))
      const parent = await createStockTake(params)

      // Save parent up front
      afterSave(stockTake, parent, isSub)

      // Save subreport directly
      const subStockParams = {
        name: stockTake.subStockTakeReports[0].name,
        note: stockTake.subStockTakeReports[0].note,
      }
      result = await createSubStockTake(parent.id, subStockParams)
      return afterSave(stockTake.subStockTakeReports[0], result, true)
    } else {
      result = isSub
        ? await createSubStockTake(newStockTake.id, params)
        : await createStockTake(params)

      return afterSave(stockTake, result, isSub)
    }
  }

  const updateState = (res, key, isSub = false) => {
    let data = {
      id: res.id,
      name: res.name,
      createdAt: res.createdAt,
      unsynced: false,
      note: res.note,
      status: res.status,
      stockTakeTemplateId: res.stockTakeTemplateId,
    }

    return dispatch({
      type: isSub ? "UPDATE_NEW_STOCKTAKE_SUBREPORT" : "UPDATE_NEW_STOCKTAKE",
      payload: isSub
        ? {
            stockTakeData: data,
            subStockTakeKey: key,
          }
        : {
            stockTakeData: data,
          },
    })
  }

  const unsyncedByActions = (arr, actions = ["add", "update", "remove"]) => {
    return arr.filter(
      (item) => actions.filter((a) => item?.unsynced?.[a] === true).length > 0
    )
  }

  const getMappedProducts = (products) => {
    return products.map((p) => getMappedProduct(p))
  }
  const getMappedProduct = (product) => {
    const prod = {
      barcode: product.barcode,
      category: product.category,
      description: product.description,
      measure: product.measure,
      price: product.price,
      quantity: product.quantity,
      size: product.size,
      subCategory: product.subCategory,
      unit: product.unit,
    }
    if (product.countedInCase) {
      prod["countedInCase"] = product.countedInCase
      prod["caseSize"] = product.caseSize
    }
    return prod
  }

  const getMappedRecipes = (recipes) => {
    return recipes.map((r) => getMappedRecipe(r))
  }

  const getMappedRecipe = (recipe) => {
    return {
      qty: recipe.totalQty,
      recipeId: recipe.recipe?.id,
    }
  }

  const addNewSubStockTake = () => {
    const report = {
      name: `New ${newStockTake.subStockTakeReports.length + 1}`,
      status: "IN_PROGRESS",
      createdAt: new Date().toISOString(),
      key: uuidv4(),
      unsynced: true,
      products: [],
      recipes: [],
    }
    setNewReport(report)
    setPopupTitle("Add new")
  }

  const getUpdatedProductList = async (stockTakeId, subStockTakeId = null) => {
    let params = {
      page: paginationData.page,
      size: paginationData.size,
    }
    const newProductList = subStockTakeId
      ? await getSubStockTakeProducts(stockTakeId, subStockTakeId, params)
      : await getStockTakeProducts(stockTakeId, params)
    if (
      newProductList &&
      newProductList.content &&
      (newProductList.status !== 400 || newProductList.status !== 500)
    ) {
      const newList = newProductList.content.map((p) => {
        return { ...p, id: uuidv4() }
      })

      setPaginationData({
        ...paginationData,
        size: newProductList.size,
        totalPages: newProductList.totalPages,
        totalElements: newProductList.totalElements,
        numberOfElements: newProductList.numberOfElements,
      })
      // Only dispatch if there are items, otherwise you will get in an infinite loop
      if (newList.length) {
        dispatch({
          type: subStockTakeId
            ? "UPDATE_NEW_STOCKTAKE_SUBREPORT_PRODUCTS"
            : "UPDATE_NEW_STOCKTAKE_PRODUCTS",
          payload: {
            products: newList,
            subStockTakeId,
          },
        })
      }
      setLoading(false)
    } else {
      showError("Something went wrong")
      setLoading(false)
    }
  }

  const getUpdatedRecipeList = async (stockTakeId, subStockTakeId = null) => {
    let params = {
      page: paginationData.page,
      size: paginationData.size,
    }
    const newRecipeList = subStockTakeId
      ? await getRecipesFromSubStockTakeReport(
          stockTakeId,
          subStockTakeId,
          params
        )
      : await getRecipesFromMainReport(stockTakeId, params)
    if (
      newRecipeList &&
      newRecipeList.content &&
      (newRecipeList.status !== 400 || newRecipeList.status !== 500)
    ) {
      const newList = newRecipeList.content.map((r) => {
        return { ...r, id: uuidv4() }
      })

      setPaginationData({
        ...paginationData,
        size: newRecipeList.size,
        totalPages: newRecipeList.totalPages,
        totalElements: newRecipeList.totalElements,
        numberOfElements: newRecipeList.numberOfElements,
      })
      // Only dispatch if there are items, otherwise you will get in an infinite loop
      if (newList.length) {
        dispatch({
          type: subStockTakeId
            ? "UPDATE_NEW_STOCKTAKE_SUBREPORT_RECIPES"
            : "UPDATE_NEW_STOCKTAKE_RECIPES",
          payload: {
            recipes: newList,
            subStockTakeId,
          },
        })
      }
      setLoading(false)
    } else {
      showError("Something went wrong")
      setLoading(false)
    }
  }

  const getUnsynced = (stockTakeId, isSub = false) => {
    let unSyncedProducts, unSyncedRecipes

    if (isSub) {
      const subReportIndex = newStockTake.subStockTakeReports.findIndex(
        (r) => r.id == stockTakeId
      )
      const subStockTake = newStockTake.subStockTakeReports[subReportIndex]
      unSyncedProducts = [...getUnsyncedItems(subStockTake, "products")]
      unSyncedRecipes = [...getUnsyncedItems(subStockTake, "recipes")]
    } else {
      unSyncedProducts = getUnsyncedItems(newStockTake, "products")
      unSyncedRecipes = getUnsyncedItems(newStockTake, "recipes")
    }
    return { unSyncedProducts, unSyncedRecipes }
  }

  const funcMap = (func): Function => {
    const map = {
      syncAddProducts: syncAddProducts,
      syncUpdateProducts: syncUpdateProducts,
      syncRemoveProducts: syncRemoveProducts,
      syncAddRecipes: syncAddRecipes,
      syncUpdateRecipes: syncUpdateRecipes,
      syncRemoveRecipes: syncRemoveRecipes,
    }
    return map[func] as Function
  }

  const syncProductAndRecipeList = async (stockTakeId, isSub = false) => {
    // ID is required!
    if (!stockTakeId) return

    const unSyncedObj = getUnsynced(stockTakeId, isSub)

    let procesUnSyncesItems
    let processFunction

    const types = ["Products", "Recipes"]
    // do adding as last to prevent (ids from mixing up)
    // after adding the list gets updated and the ids are changed
    const actions = ["Update", "Remove", "Add"]
    for (const t in types) {
      const type = types[t]
      if (unSyncedObj[`unSynced${type}`]?.length) {
        for (const a in actions) {
          const action = actions[a]
          const unSyncesItems = unsyncedByActions(
            unSyncedObj[`unSynced${type}`],
            [action.toLowerCase()]
          )
          if (unSyncesItems.length) {
            procesUnSyncesItems = unSyncesItems
            processFunction = funcMap(`sync${action}${type}`)
            await processFunction(procesUnSyncesItems, stockTakeId, isSub)
          }
        }
      }
    }
    setSyncing(false)
    setLoading(false)
  }

  const syncAddProducts = async (products, stockTakeId, isSub) => {
    const productsUpdated = !isSub
      ? await addStockTakeProducts(stockTakeId, products)
      : await addSubStockTakeProducts(
          newStockTake.id,
          stockTakeId,
          getMappedProducts(products)
        )

    // always update (needs new prices & possible failures)
    isSub
      ? await getUpdatedProductList(newStockTake.id, stockTakeId)
      : await getUpdatedProductList(stockTakeId)

    // Update local state with new products here
    if ([400, 500].includes(productsUpdated?.status)) {
      return showError("Something went wrong")
    }
  }

  const syncAddRecipes = async (recipes, stockTakeId, isSub) => {
    const params = getMappedRecipes(recipes)

    if (!params.length) return
    const recipesUpdated = isSub
      ? await addSubStockTakeRecipes(newStockTake.id, stockTakeId, params)
      : await addStockTakeRecipes(stockTakeId, params)

    // always update (needs new prices & possible failures)
    isSub
      ? await getUpdatedRecipeList(newStockTake.id, stockTakeId)
      : await getUpdatedRecipeList(stockTakeId)
    // Update local state with new recipes here
    if ([400, 500].includes(recipesUpdated?.status)) {
      return showError("Something went wrong")
    }
  }

  const validBarcode = (barcode) => {
    return productBarcodeRegexWithSpaces.test(barcode)
  }

  const syncUpdateProducts = async (products, stockTakeId, isSub) => {
    for (const product of products) {
      if (!validBarcode(product.barcode)) {
        showError(
          "Barcode is not valid for: '" +
            product.description +
            "'.Please change barcode: '" +
            product.barcode +
            "' before continueing"
        )
      } else {
        const params = getMappedProduct(product)
        const response = !isSub
          ? await updateStockTakeProduct(stockTakeId, product.barcode, params)
          : await updateSubStockTakeProduct(
              newStockTake.id,
              stockTakeId,
              product.barcode,
              params
            )

        dispatch({
          type: "UPDATE_NEW_STOCKTAKE_PRODUCT",
          payload: {
            product: {
              ...product,
              unsynced: { ...product.unsynced, update: false },
            },
            subStockTakeId: isSub ? stockTakeId : false,
          },
        })

        // Update local state with new products here
        if ([400, 500].includes(response?.status)) {
          showError("Something went wrong")
        }
      }
    }
  }

  const syncUpdateRecipes = async (recipes, stockTakeId, isSub) => {
    for (const recipe of recipes) {
      const params = getMappedRecipe(recipe)
      delete params.recipeId
      const response = !isSub
        ? await updateRecipeInMainReport(stockTakeId, recipe.recipe.id, params)
        : await updateRecipeInSubStockTakeReport(
            newStockTake.id,
            stockTakeId,
            recipe.recipe.id,
            params
          )

      await dispatch({
        type: "UPDATE_NEW_STOCKTAKE_RECIPE",
        payload: {
          recipe: {
            ...recipe,
            unsynced: { ...recipe.unsynced, update: false },
          },
          subStockTakeId: isSub ? stockTakeId : false,
        },
      })

      // Update local state with new products here
      if ([400, 500].includes(response?.status)) {
        return showError(response.message)
      }
    }
  }

  const syncRemoveProducts = async (products, stockTakeId, isSub) => {
    for (const product of products) {
      const response = !isSub
        ? await deleteStockTakeProduct(newStockTake.id, product.barcode)
        : await deleteSubStockTakeProduct(
            newStockTake.id,
            stockTakeId,
            product.barcode
          )

      const success = response === true // 204 is retured as true delete

      dispatch({
        type: "REMOVE_NEW_STOCKTAKE_PRODUCT_RESULT",
        payload: {
          productId: product.id,
          subStockTakeId: isSub ? stockTakeId : false,
          success,
        },
      })

      if (!success) {
        showError(response.message)
      }
    }
  }

  const syncRemoveRecipes = async (recipes, stockTakeId, isSub) => {
    for (const recipe of recipes) {
      const response = !isSub
        ? await deleteRecipeFromMainReport(newStockTake.id, recipe.recipe.id)
        : await deleteRecipeFromSubStockTakeReport(
            newStockTake.id,
            stockTakeId,
            recipe.recipe.id
          )

      const success = response === true // 204 is retured as true delete

      dispatch({
        type: "REMOVE_NEW_STOCKTAKE_RECIPE_RESULT",
        payload: {
          recipeId: recipe.id,
          subStockTakeId: isSub ? stockTakeId : false,
          success,
        },
      })

      if (!success) {
        showError(response.message)
      }
    }
  }

  const onCreateDiscrepancyReport = (stocktake) => {
    modal.showModal(ReportModal, {
      endpoint: "/stock-take/discrepancy-report",
      stockTakeReportId: stocktake.id,
      iconConfig: {
        mainIcon: {
          icon: faExclamationTriangle,
          color: "#f2691c",
          backgroundColor: "#feefdd",
        },
        subIcon: { icon: faSyncAlt, color: "#35ccc3" },
      },
      showPeriodSelect: false,
      title: "Create a Stock Discrepancy report",
      onCloseParentModal: onRequestCloseSelf(),
      onCreate: (reportId: string) =>
        navigate(`/dashboard/stock/stocktake/discrepancy-report/${reportId}`),
      loadingText: "Creating a Stock Discrepancy Report...",
    })
  }

  const fireFinalise = async (stocktake, completedAt) => {
    let formattedDate
    if (isToday(completedAt)) {
      formattedDate = convertToGMT(new Date())
    } else {
      formattedDate = convertToGMT(completedAt)
    }
    const finalised = await mergeStockTake(stocktake.id, formattedDate)

    if (finalised && !finalised.message) {
      showSuccess("Stock take finalised!")
      dispatch({
        type: "FINALIZE_NEW_STOCKTAKE",
        payload: {
          totalAmount: finalised.totalAmount,
          totalAmountProducts: finalised.totalAmountProducts,
          totalAmountRecipes: finalised.totalAmountRecipes,
          completedAt,
        },
      })

      const params = {
        ...onboardingSteps,
        hasAddedStocktake: true,
      }

      const updated = await updateOnboardingSteps(params)

      if (updated && !updated.message) {
        dispatch({
          type: "UPDATE_ONBOARDING_STEPS",
          payload: { onboardingSteps: updated },
        })
      }

      emitUpdate()
    } else {
      showError("Finalise failed, please try again later")
    }
  }

  const finaliseStockTakeConfirm = (stocktake) => {
    return modal.showModal(ConfirmModalStocktake, {
      stocktake,
      onConfirmDate: (completedDate) => {
        fireFinalise(stocktake, completedDate)
      },
    })
  }

  const fireComplete = async (stocktake) => {
    const isParentReport = stocktake.subStockTakeReports
    setLoading(true)
    const completed = isParentReport
      ? await completeStockTake(stocktake.id)
      : await completeSubStockTake(newStockTake.id, stocktake.id)
    if (
      completed &&
      completed.status !== 500 &&
      completed.status !== 400 &&
      !completed.message // temp fix for missing status code (should be 400)
    ) {
      dispatch({
        type: isParentReport
          ? "UPDATE_NEW_STOCKTAKE"
          : "UPDATE_NEW_STOCKTAKE_SUBREPORT",
        payload: {
          stockTakeData: {
            status: "COMPLETED",
            unsynced: false,
            completedAt: new Date().toISOString(),
          },
          subStockTakeKey: stocktake.key,
        },
      })
      showSuccess("Completed!")
      emitUpdate()
      setLoading(false)
    } else {
      setLoading(false)
      showError("Completing failed, please try again later")
    }
  }

  const completeStockTakeConfirm = (stocktake) =>
    modal.showModal(ConfirmModal, {
      type: "success",
      title: `Complete ${stocktake.name}`,
      text: `Are you sure you want to complete ${stocktake.name}? This cannot be undone.`,
      confirmButtonText: "Complete",
      onConfirm: () => fireComplete(stocktake),
    })

  const fireDelete = async (stocktake) => {
    const isParentReport = stocktake.subStockTakeReports
    setLoading(true)
    const deleted = isParentReport
      ? await deleteStockTake(stocktake.id)
      : await deleteSubStockTake(newStockTake.id, stocktake.id)

    if (
      deleted &&
      deleted.status !== 500 &&
      deleted.status !== 400 &&
      !deleted.message
    ) {
      if (isParentReport) {
        onRequestCloseSelf()
        dispatch({ type: "RESET_NEW_STOCKTAKE" })
        setLoading(false)
        showError("Deleted!")
        return
      }

      dispatch({
        type: "REMOVE_NEW_STOCKTAKE_SUBREPORT",
        payload: {
          subStockTakeKey: stocktake.key,
        },
      })
      emitUpdate()
      setLoading(false)
      showError("Deleted!")
    } else {
      setLoading(false)
      showError("Deleting failed, please try again later")
    }
  }

  const deleteStockTakeConfirm = (stocktake) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${stocktake.name}`,
      text: `Are you sure you want to delete ${stocktake.name}? This cannot be undone.`,
      confirmButtonText: "Delete",
      onConfirm: () => fireDelete(stocktake),
    })

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestCloseSelf}
      portalClassName="stocktake"
      {...otherProps}
    >
      <StockTakeHeader
        stocktake={newStockTake}
        selectedStockTake={selectedStockTake}
        allSaved={!hasUnsavedChanges()}
        isSaving={loading}
        onEdit={() => onEditOptions(newStockTake)}
        onSave={() => saveFullStockTake()}
        onFinalise={() => finaliseStockTakeConfirm(newStockTake)}
        onDelete={() => deleteStockTakeConfirm(newStockTake)}
        onSearch={setQuery}
      />
      {!selectedStockTake ? (
        <div className={styles.wrapper}>
          <div className={styles.overviewContainer}>
            {showMessages()}
            {/* Show main report + products on top as first group when COMPLETED */}
            {newStockTake.status === "COMPLETED" && (
              <StockTakeGroup
                stocktake={newStockTake}
                onSelect={() => setSelectedStockTake(newStockTake)}
                onEdit={() => setSelectedStockTake(newStockTake)}
                onComplete={() => completeStockTakeConfirm(newStockTake)}
                onDelete={() => deleteStockTakeConfirm(newStockTake)}
              />
            )}
            {newStockTake.status === "COMPLETED" && (
              <h3 className="py-4 pl-4 border-l ml-4 lg:ml-6 text-sm text-gray-700 font-semibold font-sansSemiBold uppercase">
                <FontAwesomeIcon icon={faHistory} className="mr-2" /> History
              </h3>
            )}
            {newStockTake.subStockTakeReports.map((st) => (
              <StockTakeGroup
                key={st.key}
                stocktake={{ ...st, parentId: newStockTake.id }}
                isLocked={newStockTake.status === "COMPLETED"}
                onSelect={() => setSelectedStockTake(st)}
                onEdit={() => setSelectedStockTake(st)}
                onComplete={() => completeStockTakeConfirm(st)}
                onDelete={() => deleteStockTakeConfirm(st)}
              />
            ))}
            {newStockTake.status !== "COMPLETED" && (
              <button
                className="button button--autoWidth button--primaryGreen mt-4"
                onClick={addNewSubStockTake}
                disabled={!stockTakesPermissionObj?.permissions.modify}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add new area
              </button>
            )}
          </div>
          <footer className={styles.footer}>
            <button
              className={styles.cancelButton}
              onClick={onRequestCloseSelf}
            >
              Close
            </button>
          </footer>
        </div>
      ) : (
        <>
          <div className="flex items-center pl-2 border-t bg-gray-100 justify-between">
            <div>
              <button
                onClick={() => {
                  checkSyncBeforeleave(() => {
                    setSelectedStockTake(null)
                    setStockTakeSheet(null)
                    setQuery("")
                  })
                }}
                className="pl-2 lg:pl-4 p3-4"
              >
                <h2 className="text-sm lg:text-md">
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
                  {selectedStockTake.name}
                  {selectedStockTake.status === "COMPLETED" &&
                    !selectedStockTake.subStockTakeReports && (
                      <FontAwesomeIcon
                        icon={faLock}
                        className="ml-2 text-sm text-primaryBlueLighter"
                      />
                    )}
                </h2>
              </button>
              {selectedStockTake.status !== "COMPLETED" && (
                <button
                  onClick={() => onEditOptions(selectedStockTake)}
                  disabled={!stockTakesPermissionObj?.permissions.modify}
                  className={cx("button--autoWidth", "editButton")}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
              )}
            </div>
            {!stockTakeSheet && selectedStockTake.products.length > 0 ? (
              <button
                onClick={() => {
                  setOpenStockSheetSelect(true)
                }}
                disabled={!stockTakesPermissionObj?.permissions.modify}
                type="button"
                className={styles.sheetButton}
              >
                <div className={styles.thumbnail}>
                  <FontAwesomeIcon icon={faList} size="xs" />
                  <span className={styles.thumbnailBadge}>
                    <span>
                      <FontAwesomeIcon icon={faSyncAlt} size="xs" />
                    </span>
                  </span>
                </div>
                Use stocksheet
              </button>
            ) : (
              stockTakeSheet && (
                <div className={styles.sheetButton}>
                  <div className={styles.thumbnail}>
                    <FontAwesomeIcon icon={faList} size="xs" />
                    <span className={styles.thumbnailBadge}>
                      <span>
                        <FontAwesomeIcon icon={faSyncAlt} size="xs" />
                      </span>
                    </span>
                  </div>
                  {stockTakeSheet.name}
                </div>
              )
            )}
          </div>

          <StockTakeContent
            stocktake={selectedStockTake}
            startSyncing={startSyncing}
            query={query}
            onUpdate={onUpdate}
            onClose={() => {
              closeStockSheetSelect()
              onRequestCloseSelf()
            }}
            isLoading={loading}
            stockTakeSheet={stockTakeSheet}
            setStockTakeSheet={setStockTakeSheet}
            onCloseStockSheetSelect={() => closeStockSheetSelect()}
            onSearch={setQuery}
            openStockSheetSelect={openStockSheetSelect}
          />
        </>
      )}
      <OptionsPopup
        active={stockTakeToEdit !== false || newReport !== false}
        title={popupTitle}
        activeCallback={hideOptions}
      >
        {stockTakeToEdit && stockTakeToEdit.status === "COMPLETED" && (
          <EditDetails
            stocktake={stockTakeToEdit}
            isSaving={loading}
            onSave={onEditFinalisedStocktake}
          />
        )}
        {stockTakeToEdit && stockTakeToEdit.status === "IN_PROGRESS" && (
          <EditName
            currentName={stockTakeToEdit.name}
            isSaving={loading}
            onSave={onEditStocktakeInProgress}
          />
        )}
        {newReport && (
          <EditName
            currentName={newReport.name}
            isSaving={loading}
            onSave={addReport}
          />
        )}
      </OptionsPopup>
    </Modal>
  )
}

export default StockTake
