import React from "react"
import {
  InvoicedProduct,
  getCaseQty,
  getCasePrice,
  getUnitPrice,
  getUnitQty,
} from "services/invoices"
import { roundNumber } from "services/helpers"
import ProductIcon from "components/common/ProductIcon/ProductIcon"

interface InvoicedProductItemProps {
  item: InvoicedProduct
  layout?: "horizontal" | "vertical"
}
const InvoicedProductItem: React.FunctionComponent<
  InvoicedProductItemProps
> = ({ item, layout = "vertical" }: InvoicedProductItemProps) => {
  const getQty = (invoiceQty, item) => {
    if (item.invoiceQtyInCase) {
      return getCaseQty(invoiceQty, item.invoiceQtyInCase, item)
    } else {
      return getUnitQty(invoiceQty, item.invoiceQtyInCase, item)
    }
  }
  const getPrice = (invoicePrice, item) => {
    if (item.invoiceQtyInCase) {
      return getCasePrice(invoicePrice, item.invoiceQtyInCase, item)
    } else {
      return getUnitPrice(invoicePrice, item.invoiceQtyInCase, item)
    }
  }

  return (
    <div
      className={`w-full flex flex-grow py-2 px-2 ${
        layout === "horizontal" ? "items-center flex-wrap" : "flex-col"
      }`}
    >
      <span
        className={`text-sm text-primaryBlue font-sansSemiBold ${
          layout === "horizontal" ? "w-full md:w-1/2" : "w-full"
        }`}
      >
        {item.description}
      </span>
      <span
        className={`flex flex-row flex-nowrap text-sm items-center text-bodyDefault ${
          layout === "horizontal" ? "w-full md:w-1/2" : "w-full"
        }`}
      >
        <ProductIcon
          product={item}
          inCase={item.invoiceQtyInCase}
          color="text-current"
        />
        <span>{getQty(item.invoiceQty, item)}</span>
        <span className="mx-1 text-gray-400">x</span>
        <span className="">{getPrice(item.invoicePrice, item)}</span>
        <span className="mx-1 text-gray-400">=</span>
        <span>{roundNumber(item.invoiceQty * item.invoicePrice, 3)}</span>
      </span>
    </div>
  )
}

export default InvoicedProductItem
