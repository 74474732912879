import { faCircle } from "@fortawesome/pro-duotone-svg-icons"
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { roundNumber } from "services/helpers"
import { useState } from "react"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import SalesItemContent from "../../sales/SalesItemContent/SalesItemContent"

interface ChartTogglePanelProps {
  data: any[]
  setDatasets: (prevProps) => void
  labels: any[]
  setLabels: (prevProps) => void
  renderType: "organizations" | "status"
  dataTypeName: string
}

const Difference = ({ percentage, differenceIsPositive }) => {
  return (
    <div className="text-base text-gray-600 flex flex-col text-balance">
      <span className="text-xs md:text-sm">
        Var<span className="text-xs ml-1">(to prev period)</span>
      </span>
      <span
        className={`font-semibold flex items-center text-xs sm:text-sm ${
          percentage === 0 || percentage === undefined || percentage === null
            ? "text-gray-500"
            : percentage > 0
            ? "text-green-500"
            : "text-red-500"
        }`}
      >
        {typeof percentage === "number" && percentage !== 0 && (
          <FontAwesomeIcon
            className="mr-1"
            icon={faArrowUp}
            rotation={differenceIsPositive ? undefined : 180} // Not a fan of se  tting undefined but thats what interface expects
          />
        )}
        {percentage !== undefined
          ? Math.abs(roundNumber(percentage)) + "%"
          : "No data"}
      </span>
    </div>
  )
}

const ChartTogglePanel = (props: ChartTogglePanelProps) => {
  const {
    data,
    labels,
    renderType = "organizations",
    dataTypeName = "",
  } = props
  const [collapse, setCollapse] = useState(false)

  const renderContent = () => {
    if (renderType === "organizations") {
      return (
        <ul className="h-full flex flex-col">
          {data.map((a, index) => {
            if (dataTypeName === "Live sales") {
              const percentage = a?._backend?.percentage
              const totalSales = a?._backend?.totalSales
              const differenceIsPositive = percentage > 0
              const inlineKey =
                a._backend?.orgId || a.key + a._borderColorOriginal || index

              return (
                <div
                  key={"org-" + inlineKey}
                  className={`border-t border-b -mb-px first:rounded-t last:rounded-b ${
                    collapse ? "collapsed border-gray-300" : ""
                  }`}
                  style={{
                    backgroundColor: collapse ? "#F8F8FF" : "#FFFFFF",
                  }}
                >
                  <li>
                    <div className="flex border-t border-b h-20 items-center">
                      <div
                        className={`flex w-auto ${
                          collapse ? "" : "-ml-3"
                        } items-center`}
                      >
                        <button
                          type="button"
                          className="button--smaller button--autoWidth outline-none focus:outline-none text-sm"
                          onClick={(e) => {
                            e.preventDefault()
                            setCollapse(!collapse)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            rotation={collapse ? 180 : undefined}
                          />
                        </button>
                      </div>
                      <div className="flex items-center w-1/3 flex-shrink-0 overflow-hidden">
                        <OrganizationColoredLine
                          borderColor={a._borderColorOriginal}
                          label={a.label}
                        />
                      </div>
                      <div className="ml-2 flex space-x-2 relative w-full  justify-around">
                        <AllSales
                          dataTypeName={dataTypeName}
                          value={totalSales}
                          isSales={true}
                        />
                        <Difference
                          differenceIsPositive={differenceIsPositive}
                          percentage={percentage}
                        />
                      </div>
                    </div>
                    {collapse && (
                      <div className="w-full px-3 orderDetails">
                        <SalesItemContent data={a._backend.salesByCategories} />
                      </div>
                    )}
                  </li>
                </div>
              )
            } else {
              const percentage = a?._backend?.totalCostPercentage
              const differenceIsPositive = percentage > 0
              const inlineKey =
                a._backend?.orgId || a.key + a._borderColorOriginal
              return (
                <li key={"org-" + inlineKey} className="pr-4">
                  <div className="flex border-t border-b h-20 items-center">
                    <div className="flex items-center w-1/3 flex-shrink-0 overflow-hidden">
                      <OrganizationColoredLine
                        borderColor={a._borderColorOriginal}
                        label={a.label}
                      />
                    </div>
                    <div className="ml-2 flex space-x-2 justify-between w-full">
                      <AllValues
                        dataTypeName={dataTypeName}
                        value={a._backend.totalCost}
                      />
                      <ThisPeriod
                        dataTypeName={dataTypeName}
                        value={a._backend.totalCount}
                      />
                      <Difference
                        differenceIsPositive={differenceIsPositive}
                        percentage={percentage}
                      />
                    </div>
                  </div>
                </li>
              )
            }
          })}
        </ul>
      )
    } else if (renderType === "status") {
      return (
        <ul>
          {labels.map((a, labelIndex) => {
            const inlineKey = a.value.label
            return (
              <li key={"status-" + inlineKey}>
                <div className="flex border-t border-b items-center justify-between py-4">
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full relative pr-2">
                    <div className="sm:w-1/3 flex-shrink-0 mb-3 sm:mb-0">
                      <p className="text-sm sm:text-base font-semibold">
                        {a.value.label}
                      </p>
                    </div>
                    <ul className="flex w-full justify-between sm:ml-0">
                      {props.data.map((d, i) => (
                        <li
                          key={i}
                          className="border-l-4 flex flex-col pl-2 pr-1 flex-1 text-gray-600 text-sm flex-shrink-0"
                          style={{ borderColor: d.borderColor }}
                        >
                          <span>{d.label}</span>
                          <span className="font-semibold font-sansSemiBold">
                            {d.data[labelIndex]}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )
    } else {
      return <div>Geen data beschikbaar</div>
    }
  }
  return <div>{renderContent()}</div>
}

const AllSales = ({ dataTypeName = "", value, isSales = false }) => (
  <div className="text-xs md:text-sm text-gray-600 flex flex-col md:w-20 w-14 text-balance">
    {isSales ? (
      <>
        <span>
          {dataTypeName ? <span className="capitalize">Total</span> : "Number"}
        </span>
        <span className="font-semibold font-sansSemiBold">{value ?? "-"}</span>
      </>
    ) : (
      <>
        <span>{dataTypeName ? "Number" : "This period"}</span>
        <span className="font-semibold font-sansSemiBold">{value ?? "-"}</span>
      </>
    )}
  </div>
)

const ThisPeriod = ({ dataTypeName = "", value, isSales = false }) => {
  return (
    <div className="text-xs md:text-sm text-gray-600 flex flex-col md:w-20 w-14 text-balance">
      <span>{dataTypeName ? `Total ${dataTypeName}` : "Number"}</span>
      <span className="font-semibold font-sansSemiBold">{value ?? "-"}</span>
    </div>
  )
}

const AllValues = ({ value }) => (
  <div className="text-xs md:text-sm text-gray-600 flex flex-col md:w-20 w-14 flex-wrap text-balance">
    <span>Value</span>
    <span className="font-semibold font-sansSemiBold">
      {value ? roundNumber(value, 2) : "-"}
    </span>
  </div>
)

const OrganizationColoredLine = ({ label, borderColor }) => {
  return (
    <div className="sm:ml-4 flex flex-col w-full">
      <span className="text-sm font-semibold">{label}</span>
      <div className="relative flex items-center mt-2">
        <FontAwesomeIcon
          icon={faCircle}
          className="text-xs"
          style={{
            color: borderColor,
          }}
        />
        <div
          style={{
            height: 2,
            background: `linear-gradient(to right, ${borderColor}, ${borderColor} 60%, white 100%)`,
          }}
          className=" w-28 bg-black"
        ></div>
      </div>
    </div>
  )
}
export default ChartTogglePanel
