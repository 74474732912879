import React from "react"

interface TabContentProps {
  children: any
  tabKey: string
  className?: string
  dontGrow?: boolean
}

const TabContent = (props: TabContentProps) => {
  const { children, className = null, tabKey, dontGrow } = props

  const attrs = {
    role: "tabpanel",
    "aria-labelledby": `tab-${tabKey}`,
    id: `${tabKey}-panel`,
    tabIndex: 0,
    className: `${
      className ? className : ""
    } self-stretch overflow-hidden flex flex-col
    ${dontGrow ? "" : "flex-grow "}
    `,
  }
  return <div {...attrs}>{children}</div>
}

export default TabContent
