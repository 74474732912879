import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import { useContext, useMemo } from "react"
import { get, post, remove, patch } from "./api"
import { InvoicedProduct } from "./invoices"
import { SageTaxRate } from "./sage"
import { redirectToXeroAuthorization, XeroTaxRate } from "./xero"
import initalAccounting from "context/global/InitialContext"
import { showError } from "./toast"

/*----------------------------------------------------------------------------*/
/* interfaces */
/*----------------------------------------------------------------------------*/

export interface AccountingSupplier {
  email?: string
  id: string
  name: string
}

export interface AccountingLineItem {
  account: AccountingAccount | undefined
  description: string
  netCost: number
  taxRate?: XeroTaxRate | SageTaxRate | null
  taxAmount?: number | undefined
  trackingCategory?: any
  // taxRate?: AccountingTaxRate
}

export interface AccountingAccount {
  code: string
  description?: string
  id: string
  name: string
  type: string
}

export interface AccountingTaxRate {
  name: string
  taxRate?: number
  percentage?: number
  taxType?: string
  id?: string
  displayedAs?: string
}

export interface AccountingEditParams {
  account: any | null
  supplier: any | null
  description: string
  netCost: number | undefined
  totalVat: number | undefined
  lineItems: AccountingLineItem[]
  taxRate?: XeroTaxRate | SageTaxRate | null
  // taxRate?: AccountingTaxRate
}

export interface AccountingPublishParams {
  account: AccountingAccount
  accountingSupplier: AccountingSupplier
  description?: string
  grossTotalCost?: number
  invoiceDate?: string
  invoiceNumber: string
  dueDate?: string
  netCost?: number
  totalVat?: number
  taxRate?: XeroTaxRate | SageTaxRate | null
  category?: any
  // taxRate?: AccountingTaxRate
  lineItems?: AccountingLineItem[]
}

export interface ExtendedAccountingLineItem extends AccountingLineItem {
  items: InvoicedProduct[]
}

export interface AccountingProvider {
  title: string
  type: string
  logo: string
  key: string
  id: string | null
  categoryLabel: string
  categoryPerLine: boolean
  categoryOptionLabelRenderFn: Function
  taxPerLine: boolean
  taxPercentagField: string
  taxOptionLabelRenderFn: Function
  extractErrorMsgFn: Function
  defaultRoundingAccount: string
}
export interface AccountingProviders {
  [key: string]: AccountingProvider
}

/*----------------------------------------------------------------------------*/
/* constants */
/*----------------------------------------------------------------------------*/

export const publishableInvoiceStates = ["REVIEW", "DRAFT"]

export const accountingProviders: AccountingProviders = {
  xero: {
    key: "xero",
    title: "Xero",
    type: "XERO",
    logo: "/images/integrations/xero-icon.svg",
    id: null,
    categoryLabel: "Location",
    categoryPerLine: true,
    categoryOptionLabelRenderFn: (opt) => opt.name,
    taxPerLine: true,
    taxPercentagField: "taxRate",
    taxOptionLabelRenderFn: (opt) =>
      `${opt.name} - ${opt.taxType || ""}${
        opt.taxRate !== null ? " - " + opt.taxRate + "%" : ""
      }`,
    extractErrorMsgFn: (result) => result.message,
    defaultRoundingAccount: "860",
  } as AccountingProvider,
  sage: {
    key: "sage",
    title: "Sage",
    type: "SAGE",
    logo: "/images/integrations/sage.svg",
    id: null,
    categoryLabel: "Cat.",
    categoryPerLine: false,
    categoryOptionLabelRenderFn: () => "not implemented yet",
    taxPerLine: true,
    taxPercentagField: "percentage",
    taxOptionLabelRenderFn: (opt) => `${opt.displayedAs}`,
    extractErrorMsgFn: (result) => {
      return result.message.split('$message":"')[1].split('","')[0]
    },
    defaultRoundingAccount: "860", // <- this should be changed
  } as AccountingProvider,
}

export const roundingCorrectionLabel = "Rounding correction"

/*----------------------------------------------------------------------------*/
/* Api calls */
/*----------------------------------------------------------------------------*/

export const getAccountingClientId = (provider) => {
  //   return Promise.resolve("B772389903EB4BF9B2C6C1A5BACA5D64")
  return get(`/${provider}-integrations/client-id`, {}, { plain: true })
}

export const redirectToAccountingAuthorization = (
  provider,
  clientId: string
) => {
  switch (provider) {
    case "sage":
      //redirectToSageAuthorization(clientId)
      break
    case "xero":
      redirectToXeroAuthorization(clientId)
      break
    default:
      break
  }
}

export const createAccountingIntegration = (provider, params) => {
  return post(`/${provider}-integrations`, params)
}

export const getAccountingIntegration = (
  provider,
  integrationId: string,
  config?: any
) => {
  return get(`/${provider}-integrations/${integrationId}`, {}, config)
}

export const getAccountingIntegrations = (provider) => {
  return get(`/${provider}-integrations`)
}

export const getAccountingInvoicedProductDefaults = (invoiceId) => {
  return get(`/invoices/${invoiceId}/products/accounting`)
}

export const getAccountingConnections = (provider, params) => {
  return get(`/${provider}-integrations/connections`, params)
}

export const updateAccountingConnection = (
  provider: string,
  integrationId: string,
  connectionId: string
) => {
  return patch(
    `/${provider}-integrations/${integrationId}/connection/${connectionId}`
  )
}

export const removeAccountingIntegration = (provider, integrationId) => {
  return remove(`/${provider}-integrations/${integrationId}`)
}

export const getAccountingAccounts = (provider, params: any, options?: any) => {
  return get(`/${provider}-integrations/accounts`, params, options)
}

export const getAccountingSuppliers = (
  provider,
  params: any,
  options?: any
) => {
  return get(`/${provider}-integrations/suppliers`, params, options)
}

export const getAccountingTaxRates = (provider, params: any, options?: any) => {
  return get(`/${provider}-integrations/tax-rates`, params, options)
}

export const getAccountingTrackingCategories = (
  provider,
  params: any,
  options?: any
) => {
  return get(`/${provider}-integrations/tracking-categories`, params, options)
}

/*----------------------------------------------------------------------------*/
/* hooks */
/*----------------------------------------------------------------------------*/

const mapAccountingProviders = (integrations) => {
  let numActive = 0
  Object.keys(accountingProviders).forEach(function (key) {
    accountingProviders[key].id = integrations.accountingIntegrations.find(
      (int) => int.type === accountingProviders[key].type
    )?.id
    if (accountingProviders[key].id) {
      numActive++
    }
  })
  return { providers: accountingProviders, numActive: numActive }
}

export const useAccountingProviders = () => {
  const { integrations }: any = useContext(GlobalStateContext)
  const memoAccountingProviders = useMemo(
    () => mapAccountingProviders(integrations),
    [accountingProviders]
  )
  return memoAccountingProviders
}

export const useAccountingRelationsRetriever = (provider) => {
  const providerKey = provider.key
  const dispatch = useContext(GlobalDispatchContext)
  const { accounting } = useContext(GlobalStateContext)
  const { accounts, suppliers, taxRates, trackingCategories, taxRateZero } =
    accounting[provider.key] || initalAccounting

  const abortController = useMemo(() => {
    return new AbortController()
  }, [])

  const setAccountingDispatch = (field, value) => {
    // BE API returns a rawError string when authentication fails
    const setValue = value?.rawError ? [] : value
    dispatch({
      type: "ACCOUNTING",
      payload: {
        provider: provider,
        field: field,
        value: setValue,
      },
    })
  }

  const formatCategories = (categories: any) => {
    return categories.map((category: any) => {
      category.label = category.name
      category.options = category.options.map((subCategory: any) => {
        return {
          name: subCategory.name,
          value: subCategory.id,
          parent_id: category.id,
          parent_name: category.name,
        }
      })
      return category
    })
  }

  const throwIfRawErrors = (result: any) => {
    if (result?.rawError) {
      throw new Error(result.rawError)
    }
  }

  const get = async (include) => {
    try {
      const params = {
        [providerKey + "IntegrationId"]: provider.id,
      }
      const getAccounts = async () => {
        if (!accounts) {
          const accountRes = await getAccountingAccounts(providerKey, params, {
            signal: abortController.signal,
          })
          setAccountingDispatch("accounts", accountRes)
        }
      }

      const getSuppliers = async () => {
        if (!suppliers) {
          const supplierRes = await getAccountingSuppliers(
            providerKey,
            params,
            {
              signal: abortController.signal,
            }
          )
          throwIfRawErrors(supplierRes)
          setAccountingDispatch("suppliers", supplierRes)
        }
      }

      const getTaxRates = async () => {
        let setTaxRateZero = taxRateZero
        if (!taxRates) {
          const taxRatesRes = await getAccountingTaxRates(providerKey, params, {
            signal: abortController.signal,
          })
          throwIfRawErrors(taxRatesRes)

          if (taxRatesRes) {
            setAccountingDispatch(
              "taxRates",
              taxRatesRes?.rawError ? [] : taxRatesRes
            )
          }

          // find tax rate zero
          if (!taxRatesRes?.rawError) {
            const zeroTaxRates = taxRatesRes?.filter(
              (rate) => rate[provider.taxPercentagField] === 0
            )
            if (zeroTaxRates.length > 0) {
              setTaxRateZero = zeroTaxRates[0]
              setAccountingDispatch("taxRateZero", setTaxRateZero)
            }
          }
        }
      }

      const getTrackingCategories = async () => {
        if (!trackingCategories && provider.categoryPerLine) {
          const trackingCategoryRes = await getAccountingTrackingCategories(
            providerKey,
            params,
            {
              signal: abortController.signal,
            }
          )
          setAccountingDispatch(
            "trackingCategories",
            formatCategories(trackingCategoryRes)
          )
        }
      }

      const getList: any = []
      if (include.includes("accounts")) getList.push(getAccounts())
      if (include.includes("suppliers")) getList.push(getSuppliers())
      if (include.includes("taxRates")) getList.push(getTaxRates())
      if (include.includes("trackingCategories"))
        getList.push(getTrackingCategories())

      await Promise.all(getList)
      return true
    } catch (e) {
      if (new String(e).includes("aborted")) {
        //silence aboard
      } else if (new String(e).includes("AuthenticationUnsuccessful")) {
        showError("Could not Authenticate with " + provider.title)
      } else {
        console.log(e)
        abortController.abort()
      }
      return false
    }
  }

  return {
    abort: () => abortController.abort(),
    get: get,
  }
}
