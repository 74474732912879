import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import EmptyState from "components/common/EmptyState/EmptyState"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import React, { useContext, useEffect, useState } from "react"
import { defaultColors, getOrganizationColor } from "services/chartHelpers"
import { stockTakeStates } from "services/constants"
import { convertTimeStampFormat, roundNumber } from "services/helpers"
import { getStockTakes } from "services/stock-take/stock-take"
import StocktakeHistoryChart from "../StocktakeHistoryChart"

const DashboardRecentStocktakes = () => {
  const { organizations, organization, selectedOrganizations } =
    useContext(GlobalStateContext)

  const [organisationsObject, setOrganisationsObject] = useState<null | {}>(
    null
  )
  const [recentStocktakeData, setRecentStocktakeData] = useState<null | {}>(
    null
  )
  const [orgValueChart, setOrgValueChart] = useState<string>("")

  useEffect(() => {
    setOrganisationsObject(getOrganizationObject(organizations))
  }, [])

  useEffect(() => {
    getRecentStocktakes()
  }, [selectedOrganizations])

  const getOrganizationObject = (organizations) => {
    const organisationsObject = {}

    organizations.map((org) => {
      organisationsObject[org.value] = org
    })

    return organisationsObject
  }

  const setRecentStocktakeReport = (content) => {
    if (!content) return

    const temp = {}
    const oneYearAgo = new Date().setFullYear(new Date().getFullYear() - 1)

    content.map((report) => {
      const orgId = report?.stockTakeReport?.organizations[0]

      if (
        temp[orgId] == undefined &&
        new Date(report?.stockTakeReport?.createdAt).getTime() > oneYearAgo &&
        report?.stockTakeReport?.productsCount != 0 &&
        report?.stockTakeReport?.totalAmount != 0
      ) {
        temp[orgId] = report
      }
      if (Object.keys(temp).length == selectedOrganizations.length) {
        return
      }
    })

    setRecentStocktakeData(temp)
  }

  const getRecentStocktakes = async () => {
    if (selectedOrganizations?.length === 0) {
      setRecentStocktakeData(null)
      return
    }

    const response = await getStockTakes({
      status: "COMPLETED",
      sort: "createdAt,desc",
      size: 99,
      organizations: selectedOrganizations,
    })

    setRecentStocktakeReport(response?.content)
  }

  return (
    <div className="w-full my-4 space-y-6">
      <h2 className="font-bold font-sansBold text-lg">
        Recent finalised stocktakes
      </h2>

      {!orgValueChart && (
        <div className="rounded-md">
          {recentStocktakeData === null ||
          (recentStocktakeData &&
            Object.keys(recentStocktakeData).length === 0) ? (
            <div className="border p-4 rounded-md flex flex-col justify-center items-center h-full">
              <EmptyState
                entityTitle={"recent finalised stocktake"}
                chartType={"line"}
                iconBackgroundColor={""}
                text={"Try selecting more organizations"}
              />
            </div>
          ) : (
            <>
              {recentStocktakeData &&
                Object.keys(recentStocktakeData)?.map((orgValue) => {
                  const discrepancy =
                    recentStocktakeData[orgValue]?.totalDiscrepancyAmount

                  return (
                    <div
                      key={orgValue}
                      className="border p-4 flex justify-between items-center flex-wrap gap-x-4 gap-y-4 rounded-md"
                    >
                      <div className="flex items-center space-x-4 w-full sm:w-1/2 md:w-2/5">
                        <div className="flex items-center">
                          <div className="rounded-full bg-primaryGreenVeryLight w-12 h-12 flex items-center justify-center text-white text-xl">
                            <FontAwesomeIcon
                              icon={faSyncAlt}
                              className="text-primaryGreen"
                            />
                          </div>
                          <div
                            className="rounded-full bg-black w-3 h-3 relative top-3.5 right-3"
                            style={{
                              backgroundColor: getOrganizationColor(
                                defaultColors,
                                organizations,
                                orgValue
                              ),
                            }}
                          />
                        </div>

                        <div className="space-y-2">
                          <p className="text-base font-semibold">
                            {organisationsObject &&
                              organisationsObject[orgValue]?.label}
                          </p>
                          <p className="text-xs text-gray-600">
                            Created:{" "}
                            {convertTimeStampFormat(
                              recentStocktakeData &&
                                recentStocktakeData[orgValue]?.stockTakeReport
                                  .createdAt,
                              organization?.address.zoneId
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="flex w-full sm:w-1/2 md:w-1/5 m-0 items-center">
                        <ItemStatus
                          // @ts-ignore
                          status={stockTakeStates.find(
                            (x) =>
                              x.value ===
                              recentStocktakeData[orgValue]?.stockTakeReport
                                .status
                          )}
                        />
                      </div>

                      <div className="flex space-x-4 md:space-x-16 text-xs w-full sm:w-1/2 md:w-1/5">
                        <div className="space-y-2">
                          <p className="text-gray-600 uppercase">Value</p>
                          <p className="text-primaryBlue font-semibold">
                            {recentStocktakeData[orgValue]?.stockTakeReport
                              ?.totalAmount
                              ? roundNumber(
                                  recentStocktakeData[orgValue]?.stockTakeReport
                                    ?.totalAmount
                                )
                              : 0}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <p className="text-gray-600 uppercase">Discrepancy</p>
                          <p
                            className={
                              "font-semibold " +
                              (discrepancy < 0
                                ? "text-error"
                                : "text-primaryBlue")
                            }
                          >
                            {discrepancy % 1 === 0
                              ? discrepancy
                              : discrepancy.toFixed(2)}
                          </p>
                        </div>
                      </div>

                      <button
                        className="button--autoWidth border font-sansSemiBold text-primaryBlue font-semibold px-3 py-2.5 hover:border-gray-300 rounded-md hover:shadow-sm space-x-2 w-full sm:w-1/2 md:w-1/5"
                        onClick={() => setOrgValueChart(orgValue)}
                      >
                        More insights
                      </button>
                    </div>
                  )
                })}
            </>
          )}
        </div>
      )}

      {orgValueChart && (
        <div className="border rounded-md p-4">
          <div className="flex justify-between items-center pb-4">
            <div className="flex items-center space-x-2">
              <div className="flex items-center">
                <div className="rounded-full bg-primaryGreenVeryLight w-12 h-12 flex items-center justify-center text-white text-xl">
                  <FontAwesomeIcon
                    icon={faSyncAlt}
                    className="text-primaryGreen"
                  />
                </div>
                <div
                  className="rounded-full bg-black w-3 h-3 relative top-3.5 right-3"
                  style={{
                    backgroundColor: getOrganizationColor(
                      defaultColors,
                      organizations,
                      orgValueChart
                    ),
                  }}
                />
              </div>
              <h1>
                Stocktake history:{" "}
                {organisationsObject &&
                  organisationsObject[orgValueChart]?.label}
              </h1>
            </div>

            <button
              className="button button--primaryBlue button--autoWidth no-truncate"
              onClick={() => setOrgValueChart("")}
            >
              <FontAwesomeIcon icon={faPlus} transform={{ rotate: 45 }} />
            </button>
          </div>

          <StocktakeHistoryChart orgValue={orgValueChart} />
        </div>
      )}
    </div>
  )
}

export default DashboardRecentStocktakes
