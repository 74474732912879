import { productMeasures, productUnits } from "services/constants"
import { handleError, standardiseMeasure } from "services/helpers"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewProductSelect from "../NewProductSelect/NewProductSelect"
import Modal from "react-modal"
import { AsideContext } from "context/aside/AsideContext"
import React, { useContext, useEffect } from "react"
import { ModalContext } from "context/modal/ModalContext"
import { Discrepancy, DiscrepancyType } from "./Discrepancies"
import { getProducts } from "services/products/products"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import { showError } from "services/toast"
import * as styles from "./Discrepancies.module.css"
import { GatewayProductControllerApiFactory } from "services/typescript-fetch-client"
import { getBasePath } from "services/api"
import { getParams } from "../../../services/api"
import { ExtendedProduct } from "services/typescript-fetch-client"
import { extractNumberFromString } from "../../../services/helpers"

const findValueFromDropdown = (values, string: string) =>
  values
    // Sort items so longer labels are matched first
    // e.g. "kg" is matched before "g"
    .sort((v1, v2) => v2.label.length - v1.label.length)
    .find((unit) => string.toUpperCase().includes(unit.label.toUpperCase()))
    ?.value

const findSizeFromInvoiceDiscrepancyUnitString = (
  str: string,
  findPackSize?: boolean
) => {
  const splitVals = str.toUpperCase().split("X")

  if (findPackSize) {
    if (splitVals[0]) {
      return extractNumberFromString(splitVals[0])
    }
    return
  }

  if (splitVals[1]) {
    return extractNumberFromString(splitVals[1])
  }

  if (splitVals[0]) {
    return extractNumberFromString(splitVals[0])
  }
}

export default ({
  instance,
  discrepancy,
  handleDiscrepancy,
}: {
  instance: any
  discrepancy: Discrepancy | undefined
  handleDiscrepancy: (instance: any, item: any, formattedData: any) => void
}) => {
  const aside = useContext(AsideContext)
  const modal = useContext(ModalContext)

  const controller = GatewayProductControllerApiFactory(
    undefined,
    undefined,
    getBasePath()
  )

  useEffect(() => {
    if (discrepancy !== undefined) {
      if (discrepancy?.type === DiscrepancyType.MISSING_PRODUCT) {
        handleAddItem(discrepancy)
      } else if (discrepancy?.type === DiscrepancyType.MISSING_SKU_CODE) {
        const isMissingSKU = true
        handleAddItem(discrepancy, isMissingSKU)
      } else if (discrepancy?.type === DiscrepancyType.CASE_SIZE_UNKNOWN) {
        handleEditCaseSizeUnknowItem(discrepancy)
      } else if (
        discrepancy?.type === DiscrepancyType.MISSING_UNIT ||
        discrepancy.type === DiscrepancyType.PRICE_UNKNOWN ||
        discrepancy.type === DiscrepancyType.QTY_UNKNOWN
      ) {
        handleMissingFields(discrepancy)
      } else {
        showError(
          "This discrepancy type cannot be handled automatically, please handle it by adding the product manually."
        )
      }
    }
  }, [discrepancy])

  const checkIfProductInCase = (discrepancy) => {
    const caseStrings = ["CASE", "X", "PACK", "CS", "CARTON", "TRAY"]
    return !!caseStrings.find((caseString) =>
      discrepancy?.lineItem?.unit?.toUpperCase().includes(caseString)
    )
  }

  const handleMissingFields = async (discrepancy) => {
    const item = await getProduct(discrepancy)
    if (item) {
      return finalizeDiscrepancy(
        { ...item, quantity: 0 },
        discrepancy,
        checkIfProductInCase(discrepancy)
      )
    }
  }

  const getProduct = async (discrepancy) => {
    const result = await getProducts({
      supplierId: instance.supplier.id,
      partialCode: discrepancy.lineItem.code,
    })
    const item = result?.content?.find(
      (product) => product.code === discrepancy.lineItem.code
    )
    if (item) {
      return item
    } else {
      modal.showModal(ConfirmModal as any, {
        title: "Missing item",
        text: "The following item with the below SKU/Product code cannot be found. Do you want to find it manually?",
        confirmButtonText: "Add",
        onConfirm: () => {
          handleAddItem(discrepancy)
        },
      })
      return undefined
    }
  }

  const handleEditCaseSizeUnknowItem = async (discrepancy) => {
    const initVals = await getProduct(discrepancy)

    if (initVals) {
      // If the item already has case (e.g. it has been entered after the discrepancy is created)
      // Finalize the discrepancy directly
      if (initVals.productCase) {
        finalizeDiscrepancy(initVals, discrepancy, true)
      } else {
        // Init the productCase object, so the case UI is expanded
        const price = Number(discrepancy.lineItem.price)
        initVals.productCase = { price }
        aside.showAside(EditProduct as any, {
          initialValues: initVals,
          supplierData: instance.supplier,
          headerText: "Edit product (specify case size)",
          onSubmitCallback: (item) =>
            finalizeDiscrepancy(item, discrepancy, true),
        })
      }
    }
  }

  const handleCreateItem = (item) => {
    const initVals = { ...item.lineItem }
    const unitString = standardiseMeasure(item.lineItem.unit)
    initVals.unit = findValueFromDropdown(productUnits, unitString)
    initVals.measure = findValueFromDropdown(productMeasures, unitString)
    initVals.size = findSizeFromInvoiceDiscrepancyUnitString(unitString)

    if (!initVals.code) {
      initVals.code = item.lineItem.name
    }

    // If the item has case, fill the price specified as case price
    if (checkIfProductInCase(item)) {
      initVals.productCase = initVals.productCase || {}
      initVals.productCase.price = initVals.price
      initVals.productCase.size = findSizeFromInvoiceDiscrepancyUnitString(
        unitString,
        true
      )
      initVals.orderedIn = {
        single: false,
        pack: false,
        both: true,
      }
      delete initVals.unit
      delete initVals.price
    }
    //Allow the user to edit the product before submitting
    aside.showAside(EditProduct as any, {
      headerText: "Create product",
      initialValues: initVals,
      supplierData: instance.supplier,
      createProduct: true,
      onSubmitCallback: (data) =>
        finalizeDiscrepancy(data, item, checkIfProductInCase(discrepancy)),
    })
  }
  const updateItemSkuIfNeeded = (
    item: ExtendedProduct,
    discrepancyInfo
  ): Promise<ExtendedProduct> =>
    new Promise((resolve) => {
      const code = discrepancyInfo.lineItem.code
      // If the item in the discrepancy has a SKU code, update the SKU code of the
      // item from the DB so it is the same SKU
      if (code && code != item.code) {
        modal.showModal(ConfirmModal, {
          type: "warning",
          title: "Replace item SKU code",
          text: (
            <div className="text-left">
              <p>
                The item you selected has a different SKU code than the one
                listed in the discrepancy. Are you sure you want to replace the
                SKU code of the item you selected with the one from the
                document?
              </p>
              <p>
                <b> Current SKU:</b> {item.code}
              </p>
              <p>
                <b> New SKU:</b> {code}
              </p>
            </div>
          ),
          confirmButtonText: "Replace",
          continueButtonText: "Add to invoice w/o SKU update",
          showContinue: true,
          onConfirm: () => {
            controller
              .updateProductUsingPUT({ ...item, code }, item.id, getParams())
              .then(resolve)
          },
          onContinue: () => resolve(item),
        })
      } else {
        resolve(item)
      }
    })
  const handleUpdateItem = (item, discrepancy, isMissingSKU) =>
    updateItemSkuIfNeeded(item, discrepancy)
      .then((item) =>
        finalizeDiscrepancy(
          item,
          discrepancy,
          checkIfProductInCase(discrepancy),
          isMissingSKU
        )
      )
      .catch(handleError)

  const handleAddItem = (discrepancyInfo: any, isMissingSKU?: boolean) => {
    modal.showModal((onRequestClose) => (
      <Modal isOpen portalClassName="addProductFromDiscrepancy">
        <div className={styles.discrepancyModal}>
          <div className="px-6 pt-4 pb-0 flex items-center">
            <button
              className="text-primaryBlue mr-4"
              onClick={onRequestClose.onRequestClose}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <h2 className="text-xl text-primaryBlue">
              Find &quot;{discrepancyInfo.lineItem.name}&quot;{" "}
              <div className="text-sm flex ">
                <div className="mr-4">
                  {discrepancyInfo.lineItem.code
                    ? `SKU: ${discrepancyInfo.lineItem.code}`
                    : ""}
                </div>
                {discrepancyInfo.lineItem.unit
                  ? `Unit: ${discrepancyInfo.lineItem.unit}`
                  : ""}
              </div>
            </h2>
          </div>
          <div
            style={{ minHeight: "500px" }}
            className="flex-grow flex flex-col overflow-auto relative"
          >
            <NewProductSelect
              onSelect={(item) => {
                onRequestClose.onRequestClose()
                handleUpdateItem(item, discrepancyInfo, isMissingSKU)
              }}
              onClose={onRequestClose.onRequestClose}
              selectedSupplier={instance.supplier}
              searchBySKU
              searchBySupplier
              searchByCategories
              searchByPackaging
              createNewProduct={() => {
                onRequestClose.onRequestClose()
                handleCreateItem(discrepancyInfo)
              }}
              multiSelect={false}
            />
          </div>
        </div>
      </Modal>
    ))
  }

  const finalizeDiscrepancy = async (
    itemInfo: ExtendedProduct,
    discrepancy,
    inCase = false,
    isMissingSKU = false
  ) => {
    const quantity = Number(discrepancy.lineItem.qty)

    const catalogPrice =
      itemInfo.packaging === "single"
        ? itemInfo.price
        : itemInfo.productCase.price

    const price =
      isMissingSKU || !discrepancy.lineItem.price
        ? catalogPrice
        : Number(discrepancy.lineItem.price)

    // Check if the item is in case so we can fill the row info appropriately
    if (inCase) {
      if (itemInfo.productCase?.size != undefined) {
        return handleDiscrepancy(instance, discrepancy, {
          ...itemInfo,
          quantity,
          packaging: "multiple",
          productCase: { ...itemInfo.productCase, price },
        })
        // If the product is in case, but the user didn't enter case size, allow to edit
      } else {
        // Init the productCase object, so the case UI is expanded
        const initVals = {
          ...itemInfo,
          productCase: {
            ...itemInfo.productCase,
            price,
          },
        }
        //Allow the user to edit the product before submitting
        aside.showAside(EditProduct as any, {
          headerText: "Edit product (specify case size)",
          initialValues: initVals,
          supplierData: instance.supplier,
          onSubmitCallback: (editProductData) => {
            if (!editProductData.productCase?.size) {
              return showError(
                `The ${
                  "invoiceNumber" in instance ? "invoiced" : "ordered"
                } item is packaged in a case but no case size is specified for this product. Please try again and specify a case size.`
              )
            } else {
              return handleDiscrepancy(instance, discrepancy, {
                ...editProductData,
                quantity,
                packaging: "multiple",
                productCase: { ...editProductData.productCase, price },
              })
            }
          },
        })
      }
    } else {
      return handleDiscrepancy(instance, discrepancy, {
        ...itemInfo,
        quantity,
        packaging: "single",
        price,
      })
    }
  }
  return <></>
}
