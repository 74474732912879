import { faPencil, faPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextInput from "components/forms/TextInput"
import React, { useState } from "react"
import * as styles from "./EditElements.module.css"

const decreaseFullQuantity = (prevQty) => {
  return prevQty > 0 ? Number(prevQty) - 1 : 0
}

const increaseFullQuantity = (prevQty) => {
  return Number(prevQty ?? 0) + 1
}

const QuantityInput = ({
  label,
  elementClass,
  elementPath,
  elementProps,
  elementValueKey,
  onChange,
  disabled = false,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  return (
    <>
      <span
        className={` text-right mb-1  ${
          editMode
            ? "text-sm mb-1 text-gray-700"
            : "text-xs text-gray-600 uppercase font-semibold font-sansSemiBold"
        }`}
      >
        {label}
      </span>
      <button
        type="button"
        onClick={() => setEditMode(!editMode)}
        className={`${editMode ? "hidden" : ""} p-1`}
      >
        {!elementProps[elementValueKey] ? (
          <FontAwesomeIcon icon={faPlus} className={elementClass} size="lg" />
        ) : (
          <span className="flex items-center">
            <span className="mr-2 border-b font-sansSemiBold font-semibold border-dashed border-primaryBlueLighter">
              {elementProps[elementValueKey]}
            </span>
            <FontAwesomeIcon icon={faPencil} className="text-primaryGray" />
          </span>
        )}
      </button>
      <div
        className={`${styles.inputCol} ${!editMode ? styles.hideControl : ""}`}
      >
        <button
          className={styles.roundButton}
          type="button"
          onClick={() => {
            const newQty = decreaseFullQuantity(elementProps[elementValueKey])
            onChange(newQty)
          }}
          disabled={disabled}
        >
          -
        </button>
        <TextInput
          name={`${elementPath}.${elementValueKey}`}
          label={elementValueKey}
          type="number"
          step="any"
          disabled={disabled}
          value={
            elementProps[elementValueKey] !== null
              ? elementProps[elementValueKey]
              : ""
          }
          showValidations={false}
          placeholder="XX"
          className={styles.textInput}
        />
        <button
          className={styles.roundButton}
          type="button"
          onClick={() => {
            const newQty = increaseFullQuantity(elementProps[elementValueKey])
            onChange(newQty)
          }}
          disabled={disabled}
        >
          +
        </button>
      </div>
    </>
  )
}

export default QuantityInput
