import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showSuccess, showError } from "services/toast"
import { faArrowLeft, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import {
  Invoice,
  InvoicedProduct,
  publishToAccounting,
  AccountingAccount,
} from "services/invoices"
import AccountingPublishFormHeader from "./AccountingPublishFormHeader"
import AccountingPublishForm from "./AccountingPublishForm"
import AccountingPublishSummary from "./AccountingPublishSummary"
import EditDetails from "components/invoices/EditDetails/EditDetails"

import * as styles from "./AccountingPublishForm.module.css"
import classNames from "classnames/bind"
import { groupBy, roundNumber } from "services/helpers"
import {
  ExtendedAccountingLineItem,
  AccountingEditParams,
  AccountingProvider,
  roundingCorrectionLabel,
} from "services/accounting"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/modal/ModalContext"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import InitialContext from "context/global/InitialContext"
import OptionsPopup from "components/common/OptionsPopup/OptionsPopup"
import AccountingBulkChanges from "./AccountingBulkChanges"

const cx = classNames.bind(styles)

interface AccountingPublishContainerProps {
  provider: AccountingProvider
  editEnabled: boolean
  currentStep: number
  invoice: Invoice
  onPublish(invoice: any, provider: AccountingProvider): void
  onEditInvoice?: Function
  onCancel(): void
  onRefresh(provider: AccountingProvider): void
  onSaveDetails(params: any): void
  onSaveInvoicedProducts(params: InvoicedProduct[])
}

const AccountingPublishContainer: React.FunctionComponent<
  AccountingPublishContainerProps
> = ({
  provider,
  editEnabled = true,
  currentStep,
  invoice,
  onPublish,
  onCancel,
  onRefresh,
  onSaveDetails,
  onSaveInvoicedProducts,
}: AccountingPublishContainerProps) => {
  const [step, setStep] = useState(currentStep !== null ? currentStep : 0)
  const [loading, setLoading] = useState<boolean>(false)
  const [allFilled, setAllFilled] = useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [option, setOption] = useState<any>(null)
  const providerKey = provider.key
  const modal = useContext(ModalContext)

  const [AccountingParams, setAccountingParams] =
    useState<AccountingEditParams>({
      //account: invoice[providerKey + "Invoice"]?.account || null,
      account: null,
      supplier:
        invoice[providerKey + "Invoice"]?.[providerKey + "Supplier"] || null,
      description: invoice[providerKey + "Invoice"]?.description || "",
      lineItems: [],
      netCost: invoice[providerKey + "Invoice"]
        ? roundNumber(invoice[providerKey + "Invoice"].netCost, 2)
        : roundNumber(invoice.totalCost, 2),
      totalVat: invoice[providerKey + "Invoice"]
        ? invoice[providerKey + "Invoice"].totalVat
        : invoice.totalVat,
      taxRate: invoice[providerKey + "Invoice"]?.taxRate || null,
    })
  const [taxDiscrepancy, setTaxDiscrepancy] = useState<number>(0)
  const [editDetails, setEditDetails] = useState<boolean>(false)
  const [showRoundingFix, setShowRoundingFix] = useState<boolean>(false)
  const [applyRoundingFix, setApplyRoundingFix] = useState<boolean>(false)
  const [invoicedProducts, setInvoicedProducts] = useState<
    InvoicedProduct[] | []
  >(invoice.products)

  const [groupedLineItems, setGroupedLineItems] = useState<
    ExtendedAccountingLineItem[]
  >([])

  const { accounting } = useContext(GlobalStateContext)
  const { accounts, taxRates, trackingCategories } =
    accounting[provider.key] || InitialContext.accounting

  /* line items need to be grouped by account & by tax-rate */
  /* otherwise the invoices in the accounting programmet get really long */

  const resetInvoicedProducts = () => {
    onRefresh(provider)
  }

  const mapgroupedLineItems = (items: InvoicedProduct[]) => {
    // const defaultAccount = AccountingParams.account
    // const defaultTaxRate = defaultAccount?.taxRate
    const mappedItems = items.map((item) => {
      // Add Account & TaxRate as groupKey on top level to be able to make a groupBy
      return {
        ...item,
        groupKey:
          item[providerKey]?.account?.name +
          "|" +
          (item[providerKey]?.taxRate?.name || "") +
          "|" +
          (item[providerKey]?.trackingCategory?.name || ""),
      }
    })
    // Group items by account & tax-rate
    const res = groupBy("groupKey", mappedItems)
    const accountsLength = Object.keys(res).length

    let tmpGroupedLineItems: ExtendedAccountingLineItem[] = Object.keys(
      res
    ).map((groupKey) => {
      const lineItem = res[groupKey]
      const accountLength = lineItem.length
      const itemDescription = `${lineItem[0].description}${
        accountLength > 1
          ? accountLength === 2
            ? " (+ " + (accountLength - 1) + " item)"
            : " (+ " + (accountLength - 1) + " items)"
          : ""
      }`

      return {
        account: lineItem[0][providerKey]?.account,
        // When there is only 1 account selected and the 'general' description is set, set this as a description for each line item.
        description:
          accountsLength <= 1 && AccountingParams.description
            ? AccountingParams.description
            : itemDescription,
        netCost: roundNumber(
          lineItem?.reduce((acc, curr) => {
            acc += curr.invoicedTotalCost
            return acc
          }, 0) || 0,
          2
        ),
        taxRate: provider.taxPerLine ? lineItem[0][providerKey]?.taxRate : null,
        trackingCategory: provider.categoryPerLine
          ? lineItem[0][providerKey]?.trackingCategory
          : null,
        items: lineItem,
      }
    })

    if (applyRoundingFix) {
      tmpGroupedLineItems = applyRoundingFixToLineItems(tmpGroupedLineItems)
    }
    setGroupedLineItems(tmpGroupedLineItems)
    setAccountingParams({
      ...AccountingParams,
      lineItems: mapLineItem(tmpGroupedLineItems),
    })
  }

  const applyRoundingFixToLineItems = (tmpGroupedLineItems) => {
    const roundedTaxDiscrepancy = roundNumber(taxDiscrepancy, 2)
    const correctionLineIndex =
      detectLastLineItemWithoutZeroTax(tmpGroupedLineItems)
    if (correctionLineIndex === -1) {
      // option 1) as sugested by xero
      // correction on net amount, not tax amount found.
      tmpGroupedLineItems.push({
        account: getRoundingAccount(),
        description: roundingCorrectionLabel,
        netCost: roundedTaxDiscrepancy,
        taxRate: null,
        trackingCategory: null,
        items: [],
      })
    } else {
      // option 2) add addional line
      // copy detect line and and apply rounding fix

      const roundedTaxDiscrepancy = roundNumber(taxDiscrepancy, 2)
      const copiedLine = structuredClone(
        tmpGroupedLineItems[correctionLineIndex]
      )
      tmpGroupedLineItems[correctionLineIndex].netCost -= roundedTaxDiscrepancy
      copiedLine.account = getRoundingAccount()
      copiedLine.netCost = roundedTaxDiscrepancy
      copiedLine.taxRate = null
      copiedLine.taxAmount = roundedTaxDiscrepancy
      copiedLine.description = roundingCorrectionLabel
      copiedLine.items = []

      tmpGroupedLineItems.push(copiedLine)

      // option 3) force the taxAmount for last line.
      // this fix forces the taxAmount for the last line the discrepancy
      // Maybe later
      /*
      const alterLine = tmpGroupedLineItems[correctionLineIndex]

      alterLine.taxAmount = roundNumber(
        roundNumber((alterLine.netCost / 100) * alterLine.taxRate.taxRate, 2) +
          roundedTaxDiscrepancy,
        2
      )
      alterLine.description += ` - (${roundingCorrectionLabel})`

      tmpGroupedLineItems[correctionLineIndex] = alterLine
      */
    }

    return tmpGroupedLineItems
  }

  const detectLastLineItemWithoutZeroTax = (lineItems) => {
    for (let i = lineItems.length - 1; i >= 0; i--) {
      if (lineItems[i].taxRate?.lineItems !== 0) {
        return i
      }
    }
    showError(
      "Could not detect line item for tax correction, applying correction on net amount."
    )
    return -1
  }

  const mapLineItem = (lineItems) => {
    return lineItems.map((item) => {
      return {
        account: item.account,
        description: item.description,
        netCost: item.netCost,
        taxRate: provider.taxPerLine ? item?.taxRate : null,
        taxAmount: item?.taxAmount ?? null,
        trackingCategory: provider.categoryPerLine
          ? {
              id: item?.trackingCategory?.parent_id,
              name: item?.trackingCategory?.parent_name,
              options: [
                {
                  name: item?.trackingCategory?.name,
                  id: item?.trackingCategory?.value,
                },
              ],
            }
          : null,
      }
    })
  }

  const getRoundingAccount = () => {
    let roundingAccount = accounts.filter(
      (acc) => acc.code === provider.defaultRoundingAccount
    )?.[0]
    if (!roundingAccount) {
      roundingAccount = accounts.filter((acc) => {
        return acc.name.includes("Rounding")
      })?.[0]
    }
    if (!roundingAccount) {
      showError("Rounding account was not found.")
    }
    return roundingAccount
  }

  const getTotalTax = (items) => {
    return items.reduce((acc, item) => {
      const taxRate =
        (provider.taxPerLine ? item[providerKey]?.taxRate?.taxRate : 0) || 0
      const lineTax = (item.invoicedTotalCost / 100) * taxRate
      return (acc += lineTax)
    }, 0)
  }

  const checkTotalTax = (items) => {
    const totalTax = getTotalTax(items)
    const discrepancy = roundNumber(
      (AccountingParams.totalVat || 0) - totalTax,
      2
    )
    setTaxDiscrepancy(discrepancy)
    setShowRoundingFix(Math.abs(discrepancy) < 1)
  }

  const publish = async () => {
    setLoading(true)
    const params = {
      //account: AccountingParams.account,
      account: null,
      [providerKey + "Supplier"]: AccountingParams.supplier,
      description: AccountingParams.description || "",
      grossTotalCost: invoice.grossTotalCost,
      invoiceDate: invoice.dateOfIssue,
      invoiceNumber: invoice.invoiceNumber,
      dueDate: invoice.dueDate || invoice.dateOfIssue,
      netCost: AccountingParams.netCost,
      totalVat: AccountingParams.totalVat,
      lineItems: AccountingParams.lineItems,
      taxRate: AccountingParams.taxRate,
    }

    try {
      await onSaveInvoicedProducts(invoicedProducts)
      const published = await publishToAccounting(
        providerKey,
        invoice.id,
        params
      )

      if (
        published === true ||
        (published.status >= 200 && published.status < 300)
      ) {
        showSuccess(`Invoice publised to ${providerKey}!`)
        onPublish(params, provider)
      } else {
        if (published.message) {
          const message = provider.extractErrorMsgFn(published)
          modal.showModal(ConfirmModal, {
            type: "danger",
            title: provider.title + " error:",
            text: message,
            showCancel: false,
            confirmButtonText: "Continue",
            onConfirm: () => void 0,
          })
        } else {
          showError(
            `Could not publish invoice to ${providerKey}, try again later`
          )
        }
      }
    } catch (e) {
      showError(`Could not publish invoice to ${providerKey}, try again later`)
    }
    setLoading(false)
  }

  const handleInvoiceItemChange = (
    val: AccountingAccount,
    field: string,
    itemIndex: number
  ) => {
    const newArray = invoicedProducts
    newArray[itemIndex][providerKey] = {
      ...newArray[itemIndex][providerKey],
      [field]: val,
    }
    setInvoicedProducts([...newArray])
    setHasChanges(true)
  }

  const handleInvoiceItemDefaults = (defaults: any) => {
    const newArray = invoicedProducts.map((product) => {
      const productDefaults = defaults?.[product.barcode]

      if (productDefaults) {
        if (!product[providerKey]) {
          product[providerKey] = {
            account: null,
            taxRate: null,
            trackingCategory: null,
          }
        }
        if (productDefaults.account && !product[providerKey]?.account) {
          product[providerKey].account = productDefaults.account
        }

        if (productDefaults.taxRate && !product[providerKey]?.taxRate) {
          product[providerKey].taxRate = productDefaults.taxRate
        }
      }
      return product
    })

    setInvoicedProducts([...newArray])
  }

  const checkAllFilled = () => {
    const allFilled = invoicedProducts.every((p) => {
      return p[providerKey]?.account && p[providerKey]?.taxRate
    })
    setAllFilled(allFilled)
  }

  const setDefault = (obj, key, value, force) => {
    if (obj == null) {
      obj = {}
    }
    if (value != null && (obj?.[key] == null || force)) {
      obj[key] = value
    }
    return obj
  }

  const setAccountingDefaults = (setTo: any) => {
    const force = setTo.applyTo === "all"
    const newInvoicedProducts = invoicedProducts.map((p) => {
      let obj = p[providerKey]
      obj = setDefault(obj, "account", setTo.account, force)
      obj = setDefault(obj, "trackingCategory", setTo.trackingCategory, force)
      if (provider.taxPerLine) {
        obj = setDefault(obj, "taxRate", setTo.taxRate, force)
      }
      p[providerKey] = obj
      return p
    })
    setInvoicedProducts([...newInvoicedProducts])
    setHasChanges(true)
    setOption(null)
  }

  const showBulkChange = () => {
    setOption({
      title: "Apply bulk changes to invoiced items",
      component: (
        <AccountingBulkChanges
          provider={provider}
          accounts={accounts}
          taxRates={taxRates}
          trackingCategories={trackingCategories}
          setAccountingDefaults={setAccountingDefaults}
        />
      ),
    })
  }

  useEffect(() => {
    // Make sure the local state of the Accounting accounts per line item
    // is not lost when update comes from parent context
    const mappedProducts = invoice.products.map(
      (p: InvoicedProduct, index: number) => {
        return {
          ...p,
          [providerKey]: invoicedProducts[index]?.[providerKey],
        }
      }
    )
    setInvoicedProducts(mappedProducts)
  }, [invoice.products])

  useEffect(() => {
    if (invoice[providerKey + "Invoice"]) {
      setGroupedLineItems(invoice[providerKey + "Invoice"].lineItems)
    } else {
      checkAllFilled()
      checkTotalTax(invoicedProducts)
      mapgroupedLineItems(invoicedProducts)
    }
  }, [invoicedProducts, AccountingParams.description, applyRoundingFix])

  useEffect(() => {
    checkTotalTax(invoicedProducts)
  }, [AccountingParams.totalVat])

  return (
    <>
      <div className="flex flex-col items-start overflow-hidden flex-grow h-full relative">
        <form className="flex flex-col flex-grow items-start overflow-hidden w-full">
          {editEnabled && <AccountingPublishFormHeader step={step} />}
          <div className="flex items-start flex-wrap w-full flex-grow overflow-auto border-t">
            {step === 0 ? (
              <AccountingPublishForm
                provider={provider}
                AccountingParams={AccountingParams}
                setAccountingParams={setAccountingParams}
                onEditDetails={() => setEditDetails(true)}
                invoicedProducts={invoicedProducts}
                resetInvoicedProducts={resetInvoicedProducts}
                handleInvoiceItemDefaults={handleInvoiceItemDefaults}
                handleInvoiceItemChange={handleInvoiceItemChange}
                setAccountingDefaults={setAccountingDefaults}
                invoice={invoice}
                hasChanges={hasChanges}
                allFilled={allFilled}
                taxDiscrepancy={taxDiscrepancy}
                showBulkChange={showBulkChange}
                showRoundingFix={showRoundingFix}
                applyRoundingFix={applyRoundingFix}
                setApplyRoundingFix={setApplyRoundingFix}
              />
            ) : (
              <AccountingPublishSummary
                provider={provider}
                invoice={invoice}
                AccountingParams={AccountingParams}
                groupedLineItems={groupedLineItems}
              />
            )}
          </div>
          <div className="px-6 md:px-4 py-4 w-full flex items-center flex-shrink-0 border-t">
            <button
              onClick={onCancel}
              className={`${
                !editEnabled && "button button--paleBlue button--autoWidth"
              } text-gray-700 px-2 font-sansSemiBold font-semibold mr-auto`}
              type="button"
            >
              {editEnabled ? "Cancel" : "Close"}
            </button>
            {step === 1 && editEnabled && (
              <button
                onClick={() => setStep(0)}
                className="button button--paleBlue button--autoWidth mr-4"
                type="button"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Previous
              </button>
            )}
            {editEnabled && (
              <button
                onClick={() => (step === 0 ? setStep(1) : publish())}
                disabled={
                  loading ||
                  !AccountingParams.supplier ||
                  !AccountingParams.netCost ||
                  !invoice.invoiceNumber
                  //|| taxDiscrepancy != 0
                }
                className="button button--primaryGreen button--autoWidth"
                type="button"
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    spin
                    className="mr-2"
                  />
                )}
                {step === 0 ? "Save & Next" : "Publish"}
              </button>
            )}
          </div>
        </form>
      </div>
      {editDetails && (
        <div className={cx("editDetailsWrapper", { active: editDetails })}>
          <div className="flex items-center justify-center w-full p-2 border-b">
            <h2 className="text-primaryBlue font-semibold font-sansSemiBold text-lg">
              Edit details
            </h2>
          </div>
          <div className="overflow-auto flex-grow">
            <EditDetails
              object={{
                number: invoice.invoiceNumber,
                date: invoice.dateOfIssue,
                dueDate: invoice.dueDate,
              }}
              isSaving={loading}
              onSave={(params) => {
                onSaveDetails(params)
                setEditDetails(false)
              }}
              labels={{
                number: "Invoice number",
                date: "Issue date",
                dueDate: "Due date",
              }}
            />
          </div>
        </div>
      )}
      <div
        className={cx("editDetailsOverlay", { active: editDetails })}
        onClick={() => setEditDetails(false)}
      ></div>
      {option && (
        <OptionsPopup
          active={option != null}
          title={option?.title}
          overflowVisible={true}
          activeCallback={() => setOption(null)}
          headerClasses="text-primaryBlue text-xl font-bold-important"
        >
          {option?.component}
        </OptionsPopup>
      )}
    </>
  )
}

export default AccountingPublishContainer
