import React, { useContext, useEffect, useState } from "react"
import Modal from "react-modal"
import { showError, showSuccess } from "services/toast"
import {
  createReport,
  getStockTakes,
  updateReportById,
} from "services/stock-take/stock-take"
import { getSales } from "services/sales"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import Loader from "components/common/Loader/Loader"
import AsyncSelect from "components/forms/AsyncSelect"
import CategorySelect from "components/common/CategorySelect/CategorySelect"
import {
  convertToTimeZoneDate,
  convertToTimeZoneUTCString,
  formatDateAsTime,
  formatReportSelectOptionLabel,
} from "services/helpers"
import { Category, FlexiblePeriod, IconProps, TimePeriod } from "services/types"
import {
  applyReportFiltersToCategories,
  formatCategoriesAsReportFilterCategories,
} from "services/reportHelpers"
import useCategories from "hooks/useCategories"
import Checkbox from "components/common/Checkbox/Checkbox"
import { useUpdateEffect } from "react-use"
import FormDropdown from "components/forms/FormDropdown"
import ItemIcon from "components/common/OverviewItem/ItemIcon/ItemIcon"

import {
  header,
  content,
  footer,
  cancelButton,
  //@ts-ignore
} from "./ReportModal.module.css"
import ReactTooltip from "react-tooltip"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import DateSelection from "components/forms/DateSelection"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { set } from "date-fns"

Modal.setAppElement("body")

interface ReportModalProps {
  endpoint:
    | "/gp-reports"
    | "/stock-take/cogs-reports"
    | "/stock-take/discrepancy-report"
  title?: string
  iconConfig: {
    mainIcon: IconProps
    subIcon?: IconProps
  }
  loadingText?: string
  currentReportId?: string
  stockTakeReportId?: string
  isUpdate?: boolean
  selectPeriodFrom?: boolean
  showPeriodSelect?: boolean
  periodTooltip?: string
  onCloseParentModal?: () => void
  onRequestClose?: () => void
  onCreate?: (createdReportId: string) => void
  onUpdate?: () => void
  currentReportData?: any
  insights?: false // wether this report is being generated from the 'Insights' section
  [key: string]: any
}

const ReportModal = ({
  endpoint,
  title,
  iconConfig,
  loadingText,
  stockTakeReportId,
  currentReportId,
  isUpdate = false,
  showPeriodSelect = true,
  selectPeriodFrom = false,
  periodTooltip,
  onCloseParentModal,
  onRequestClose,
  onCreate,
  onUpdate,
  currentReportData,
  insights,
  ...otherProps
}: ReportModalProps) => {
  const {
    openingStockTakeReportId,
    sales,
    filterCategories,
    theoretical,
    periodFrom,
    periodTo,
  } = currentReportData || {}

  const { organization }: { organization: any } = useContext(GlobalStateContext)

  const [loading, setLoading] = useState(false)
  const [selectedOpeningStocktake, setSelectedOpeningStocktake] = useState<{
    label: string | undefined
    value: any
  } | null>(null)
  const [selectedClosingStocktake, setSelectedClosingStocktake] = useState<{
    label: string | undefined
    value: any
  } | null>(null)
  const [selectedSalesFile, setSelectedSalesFile] = useState<{
    label: string | undefined
    value: any
  } | null>(null)

  const parsedPeriodFrom = periodFrom
    ? convertToTimeZoneDate(periodFrom, organization.address.zoneId)
    : undefined
  const parsedPeriodTo = periodTo
    ? convertToTimeZoneDate(periodTo, organization.address.zoneId)
    : undefined

  const [selectedPeriod, setSelectedPeriod] = useState<FlexiblePeriod>({
    from: parsedPeriodFrom ? new Date(parsedPeriodFrom) : undefined,
    to: parsedPeriodTo ? new Date(parsedPeriodTo) : undefined,
    enteredTo: parsedPeriodTo ? new Date(parsedPeriodTo) : undefined,
  })
  const [selectedTime, setSelectedTime] = useState<TimePeriod>({
    from: parsedPeriodFrom ? formatDateAsTime(parsedPeriodFrom) : "00:00",
    to: parsedPeriodTo ? formatDateAsTime(parsedPeriodTo) : "23:59",
  })

  const [includeTheoretical, setIncludeTheoretical] = useState(
    theoretical !== undefined && theoretical !== null ? theoretical : true
  )

  const defaultCategories = useCategories().categories
  const [categories, setCategories] = useState<Category[]>(defaultCategories)

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      setCategories(
        applyReportFiltersToCategories(
          defaultCategories,
          filterCategories,
          filterCategories ? filterCategories.includeUncategorised : true,
          filterCategories ? false : true
        )
      )
    }
    return () => {
      isMounted = false
    }
  }, [defaultCategories, filterCategories])

  const fetchFinalisedStockTakes = async (
    inputValue,
    forSelectValue: "openingStockTake" | "closingStockTake"
  ) => {
    setLoading(true)

    const params = {
      size: 999,
      sort: "completedAt,desc",
      status: "COMPLETED",
      partialName: inputValue ? inputValue : null,
    }
    const result = await getStockTakes(params)

    if (forSelectValue === "openingStockTake" && openingStockTakeReportId) {
      const openingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === openingStockTakeReportId
      )

      if (openingStockTake) {
        setSelectedOpeningStocktake({
          label: formatReportSelectOptionLabel(openingStockTake, "stocktake"),
          value: openingStockTake,
        })
      }
    }

    if (forSelectValue === "closingStockTake" && stockTakeReportId) {
      const closingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === stockTakeReportId
      )
      if (closingStockTake) {
        setSelectedClosingStocktake({
          label: formatReportSelectOptionLabel(closingStockTake, "stocktake"),
          value: closingStockTake,
        })
      }
    }

    setLoading(false)

    return result
  }

  const fetchSalesFiles = async () => {
    setLoading(true)

    const params = {
      size: 999,
      sort: "createdAt,desc",
      live: false,
    }
    const result = await getSales(params)

    const salesFile = result.content.find((sf) => sf.id === sales?.id)

    if (salesFile) {
      setSelectedSalesFile({
        label: formatReportSelectOptionLabel(salesFile, "sales"),
        value: salesFile,
      })
    }
    setLoading(false)
    return result
  }

  const getConvertedDateAndTime = (date, time) => {
    const [hours, minutes] = time.split(":").map(Number) // "12:45" -> [12, 45]
    const converted = convertToTimeZoneUTCString(
      set(new Date(date), {
        hours: hours,
        minutes: minutes,
        seconds: 0,
        milliseconds: 0,
      }),
      organization.address.zoneId
    )
    return converted
  }

  const onSaveReport = async () => {
    setLoading(true)
    // If a category is selected return the mainCategories object for filterCategories, else send null to get all products in stocktake.
    const filteredCategories =
      formatCategoriesAsReportFilterCategories(categories)

    const bodyParams = {
      filterCategories:
        filteredCategories?.mainCategories &&
        filteredCategories?.mainCategories.length > 0
          ? filteredCategories
          : undefined,
    }

    const queryParams: any = {
      currentReportId: null,
      stockTakeReportId: null,
      openingStockTakeReportId: null,
      salesId: null,
      insights: insights,
      theoretical: includeTheoretical,
      startDate: selectedPeriod.from
        ? getConvertedDateAndTime(selectedPeriod.from, selectedTime.from)
        : undefined,
      endDate: selectedPeriod.to
        ? getConvertedDateAndTime(selectedPeriod.enteredTo, selectedTime.to)
        : undefined,
    }

    if (currentReportId) {
      queryParams.currentReportId = currentReportId
    }
    // When a report is being generated based on an existing stocktake, the stocktakeReportId is passed in as a prop
    if (!insights && stockTakeReportId) {
      queryParams.stockTakeReportId = stockTakeReportId
    } else if (selectedClosingStocktake) {
      // Else, when a report is being generated from the 'Insights' section, the reportId can be selected manually
      queryParams.stockTakeReportId =
        selectedClosingStocktake.value.stockTakeReport.id
    }

    if (selectedOpeningStocktake) {
      queryParams.openingStockTakeReportId =
        selectedOpeningStocktake.value.stockTakeReport.id
    }

    if (selectedSalesFile) {
      queryParams.salesId = selectedSalesFile.value.id
    }

    const result = isUpdate
      ? await updateReportById(
          endpoint,
          queryParams.currentReportId,
          queryParams.stockTakeReportId,
          queryParams.openingStockTakeReportId,
          queryParams.salesId,
          queryParams.insights,
          queryParams.theoretical,
          queryParams.startDate,
          queryParams.endDate,
          bodyParams
        )
      : await createReport(
          endpoint,
          queryParams.stockTakeReportId,
          queryParams.openingStockTakeReportId,
          queryParams.salesId,
          queryParams.insights,
          queryParams.theoretical,
          queryParams.startDate,
          queryParams.endDate,
          bodyParams
        )

    setLoading(false)

    if (result.status >= 300) {
      return showError(result.message)
    } else if (result) {
      setLoading(false)
      showSuccess("Report generated!", { position: "top-center" })

      if (onCloseParentModal) {
        onCloseParentModal()
      }

      onRequestClose && onRequestClose()

      // Optional callback on new report
      !isUpdate && onCreate && onCreate(result.id)

      // Optional callback on update report
      isUpdate && onUpdate && onUpdate()
    }
  }

  /**
   * Determines whether the theoretical report should be generated.
   * If the selected closing stocktake is undefined and the stocktake report ID is undefined,
   * then the theoretical report should be generated.
   * @returns {boolean} Whether the theoretical report should be generated.
   */
  const forceTheoretical =
    !selectedClosingStocktake && selectedPeriod.enteredTo && insights

  useUpdateEffect(() => {
    // if (selectedClosingStocktake && selectedPeriod.enteredTo) {
    //   setSelectedPeriod({
    //     ...selectedPeriod,
    //     to: undefined,
    //     enteredTo: undefined,
    //   })
    // }
    if (!theoretical && forceTheoretical) {
      setIncludeTheoretical(true)
    }
  }, [selectedClosingStocktake, selectedPeriod.enteredTo])

  // useUpdateEffect(() => {
  //   // if (selectedClosingStocktake && selectedPeriod.enteredTo) {
  //   //   setSelectedClosingStocktake(null)
  //   // }
  //   if (!theoretical && forceTheoretical) {
  //     setIncludeTheoretical(true)
  //   }
  // }, [selectedPeriod.enteredTo])

  const buttonText = isUpdate ? "Update report" : "Create report"

  const buttonIcon = !isUpdate && (
    <FontAwesomeIcon icon={faPlus} className="mr-2" />
  )

  const categoryCount = categories
    ? categories.reduce((acc, item) => {
        return acc + item.subCategories.filter((subCat) => subCat.active).length
      }, 0)
    : 0

  const createReportDisabled =
    !selectedOpeningStocktake ||
    (!stockTakeReportId && !selectedClosingStocktake && !selectedPeriod.to)

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      portalClassName="selectOpeningStockTake"
      {...otherProps}
    >
      <ReactTooltip
        id={"field_label_tooltip"}
        type="light"
        place="top"
        effect="float"
        border={true}
        borderColor="#e2e8f0"
      />
      <div className="flex flex-col h-full">
        <div className={header}>
          <ItemIcon {...iconConfig} />
          <h2 className="text-lg">{title}</h2>
        </div>

        <div className={content}>
          <div className="my-1 px-2 w-full">
            <Loader
              isLoading={loading}
              style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
            >
              {loadingText}
            </Loader>

            <div className="flex flex-wrap items-start gap-x-6 rounded">
              <div className="flex-col flex-grow pb-4">
                <h2 className="my-2">
                  {openingStockTakeReportId
                    ? "Edit opening stocktake"
                    : "Select opening stocktake"}
                  <span
                    className="ml-1 text-primaryBlueLighter"
                    data-for={"field_label_tooltip"}
                    data-tip={`Select for opening stock quantities`}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </h2>
                <AsyncSelect
                  name="Opening stocktake"
                  label="Opening stocktake"
                  className="w-full"
                  id="openingStocktake"
                  value={selectedOpeningStocktake}
                  promise={(inputValue) =>
                    fetchFinalisedStockTakes(inputValue, "openingStockTake")
                  }
                  defaultOptions
                  placeholder="Opening stocktake"
                  isClearable={true}
                  optionLabel={(option) =>
                    formatReportSelectOptionLabel(option, "stocktake")
                  }
                  onChange={(stocktake) => {
                    setSelectedOpeningStocktake(stocktake)
                  }}
                />
              </div>
              {/* Closing stocktake can only be changed from 'Insights' section, when stockTakeReportId is not passed in from the outside */}
              {(insights || !stockTakeReportId) && (
                <div className="flex-col flex-grow pb-4">
                  <h2 className="my-2">
                    {openingStockTakeReportId
                      ? "Edit closing stocktake"
                      : "Select closing stocktake"}
                    <span
                      className="ml-1 text-primaryBlueLighter"
                      data-for={"field_label_tooltip"}
                      data-tip={`Select for closing stock quantities`}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                  </h2>
                  <AsyncSelect
                    name="Closing stocktake"
                    label="Closing stocktake"
                    className="w-full"
                    id="closingStocktake"
                    value={selectedClosingStocktake}
                    promise={(inputValue) =>
                      fetchFinalisedStockTakes(inputValue, "closingStockTake")
                    }
                    placeholder="Closing stocktake"
                    defaultOptions
                    isClearable={true}
                    optionLabel={(option) =>
                      formatReportSelectOptionLabel(option, "stocktake")
                    }
                    onChange={(stocktake) => {
                      setSelectedClosingStocktake(stocktake)
                    }}
                  />
                </div>
              )}
            </div>

            {showPeriodSelect && (
              <>
                <h2 className="my-2">
                  {selectPeriodFrom ? "Select a period" : "End date & time"}{" "}
                  <span className="text-red-700">*</span>
                  {periodTooltip && (
                    <span
                      className="ml-1 text-primaryBlueLighter"
                      data-for={"field_label_tooltip"}
                      data-tip={periodTooltip}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                  )}
                </h2>

                <DateSelection
                  mode={selectPeriodFrom ? "range" : "to"}
                  placeholders={{
                    fromDate: selectPeriodFrom ? "From date" : "End date",
                    fromTime: selectPeriodFrom ? "From time" : "End time",
                  }}
                  value={selectedPeriod}
                  onChange={setSelectedPeriod}
                  timeValue={selectedTime}
                  onChangeTime={setSelectedTime}
                />
              </>
            )}

            <div className="mt-4 mb-6">
              <Checkbox
                name="includeTheoretical"
                disabled={forceTheoretical as boolean}
                label="Include theoretical stock"
                isChecked={includeTheoretical}
                infoMessage={
                  forceTheoretical
                    ? "All expected closing qty in this report are theoretical as no closing stocktake has been selected"
                    : "Include theoretical stock that isn't counted yet in the report"
                }
                onSelect={() => setIncludeTheoretical((prev) => !prev)}
              />
            </div>

            <h2 className="mb-2 mt-4">
              {openingStockTakeReportId
                ? "Edit sales file"
                : "Select sales file (optional)"}
            </h2>
            <AsyncSelect
              name="Sales file"
              label="Sales file"
              className="w-full"
              defaultOptions
              cacheOptions
              value={selectedSalesFile}
              promise={fetchSalesFiles}
              placeholder="Sales file"
              isClearable={true}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "sales")
              }
              onChange={(sf) => {
                setSelectedSalesFile(sf)
              }}
            />
          </div>

          <FormDropdown
            noBorder
            buttonTextSuffix={
              <span className="mb-2 ml-3 flex flex-col items-start">
                <span className="underline hover:no-underline text-primaryBlue font-semibold font-sansSemiBold">
                  Edit product categories
                </span>
                <span className="font-normal font-sans text-sm text-gray-600">
                  {categoryCount} categories selected
                </span>
              </span>
            }
            fullWidth={true}
          >
            <CategorySelect
              categories={categories}
              onSelectCategories={(selectedCategories) =>
                setCategories(selectedCategories)
              }
            />
          </FormDropdown>
        </div>
        <div className={footer}>
          <button
            type="button"
            className={cancelButton}
            onClick={onRequestClose}
          >
            Close
          </button>
          <button
            type="button"
            className="button ml-auto button--autoWidth button--primaryGreen"
            onClick={onSaveReport}
            disabled={createReportDisabled}
          >
            <span>{buttonIcon}</span>
            <span>{buttonText}</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ReportModal
