import React, { useContext } from "react"
import { format } from "date-fns" // <- isToday
import { AsideContext } from "context/aside/AsideContext"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import {
  ExtendedFlexiblePeriod,
  FlexiblePeriod,
  TimePeriod,
} from "services/types"
import { convertToTimeZoneUTCString } from "services/helpers"
import { Matcher } from "react-day-picker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-light-svg-icons"

export interface DateSelectionProps {
  value: FlexiblePeriod
  onChange(data: ExtendedFlexiblePeriod): void
  timeValue?: TimePeriod
  onChangeTime?: (data: TimePeriod) => void
  highlightDays?: Array<Date>
  highlightDaysLabels?: Array<String>
  mode?: "single" | "range" | "to"
  disable?: Matcher
  placeholders?: {
    fromDate?: string
    toDate?: string
    fromTime?: string
    toTime?: string
  }
}

const DateSelection = ({
  value,
  onChange,
  timeValue,
  onChangeTime,
  highlightDays,
  highlightDaysLabels,
  mode = "range",
  disable,
  placeholders,
}: DateSelectionProps) => {
  const aside = useContext(AsideContext)
  const { user }: { user: any } = useContext(GlobalStateContext)

  const timeEnabled = !!(timeValue && onChangeTime)

  const inputPlaceholders = {
    fromDate: placeholders?.fromDate || "From date",
    fromTime: placeholders?.fromTime || "From time",
    toDate: placeholders?.toDate || "To date",
    toTime: placeholders?.toTime || "To time",
  }

  const toggleDatePicker = () => {
    // @ts-ignore
    aside.showAside(AsideCalendar, {
      selectedPeriod: value,
      zIndex: 2000,
      headerText: "Select a period",
      onConfirm: (data: FlexiblePeriod) => {
        const dataToBroadcast: ExtendedFlexiblePeriod = {
          ...data,
          inUserTimezone: {
            from: data.from
              ? convertToTimeZoneUTCString(data.from, user.zoneId)
              : undefined,
            to: data.to
              ? convertToTimeZoneUTCString(data.to, user.zoneId)
              : undefined,
            enteredTo: data.enteredTo
              ? convertToTimeZoneUTCString(data.enteredTo, user.zoneId)
              : undefined,
          },
        }
        onChange(dataToBroadcast)
        aside.hideAside()
      },
      highlightDays,
      highlightDaysLabels,
      mode,
      disable,
    })
  }

  /**
   * Returns a string representation of the given date in the format "dd/MM/yyyy".
   * If the date is falsy, returns an empty string.
   * @param {Date | null | undefined} date The date to format
   * @return {string} The formatted date string
   */
  const formatDate = (date) => {
    if (!date) return
    return format(new Date(date), "dd/MM/yyyy")
  }

  return (
    <div className="flex flex-row gap-x-6 relative w-full">
      {mode !== "to" && (
        <div className="flex w-auto md:w-1/2 flex-grow flex-col">
          <div className="inline-flex relative input-container items-center w-full">
            <input
              type="text"
              value={value.from ? formatDate(value.from) : ""}
              className="cursor-pointer form-control rounded"
              placeholder={inputPlaceholders.fromDate}
              readOnly
              onClick={toggleDatePicker}
            />
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ zIndex: 2 }}
              className="absolute right-4 text-gray-700"
            />
          </div>
          {timeEnabled && (
            <div className="input-container">
              <input
                type="time"
                name="timeFrom"
                className="cursor-pointer form-control rounded mt-2"
                placeholder={inputPlaceholders.fromTime}
                value={timeValue.from}
                onChange={(e) =>
                  onChangeTime({
                    ...timeValue,
                    from: e.target.value,
                  })
                }
              />
            </div>
          )}
        </div>
      )}
      {(mode === "range" || mode === "to") && (
        <div className="flex w-auto md:w-1/2 input-container flex-grow flex-col">
          <div className="inline-flex relative input-container items-center w-full">
            <input
              type="text"
              className="cursor-pointer form-control rounded"
              value={value.to ? formatDate(value.to) : ""}
              placeholder={inputPlaceholders.toDate}
              readOnly
              onClick={toggleDatePicker}
            />
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ zIndex: 2 }}
              className="absolute right-4 text-gray-700"
            />
          </div>
          {timeEnabled && (
            <div className="input-container">
              <input
                type="time"
                name="timeTo"
                className="cursor-pointer form-control rounded mt-2"
                placeholder={inputPlaceholders.toTime}
                value={timeValue.to}
                onChange={(e) =>
                  onChangeTime({
                    ...timeValue,
                    to: e.target.value,
                  })
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DateSelection
