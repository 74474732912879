import RadioGroup from "components/forms/RadioGroup"
import React, { useState } from "react"
import Select from "react-select"

interface AccountingBulkChangesProps {
  provider: any
  accounts: any
  taxRates: any
  trackingCategories: any
  setAccountingDefaults: Function
}

const AccountingBulkChanges = (props: AccountingBulkChangesProps) => {
  const {
    provider,
    accounts,
    taxRates,
    trackingCategories,
    setAccountingDefaults,
  } = props

  const [defaults, setDefaults] = useState({
    applyTo: "empty",
    account: null,
    taxRate: null,
    trackingCategory: null,
  })

  return (
    <div className="p-10 w-full">
      <h3 className="text font-bold text-primaryBlue pb-2">
        Where do you want to apply these changes?{" "}
      </h3>
      <div className="pb-4">
        <RadioGroup
          name="applyTo"
          baseClassName="radioGroup-simple"
          className="radioGroup-simple"
          options={[
            { value: "empty", label: "Apply to empty fields only" },
            { value: "all", label: "Overwrite all items" },
          ]}
          value={defaults.applyTo}
          onChange={(val) => setDefaults({ ...defaults, applyTo: val })}
        />
      </div>
      <div className="flex flex-col md:flex-row w-full gap-4">
        <div className="w-full md:w-1/3">
          <h3 className="text-primaryBlue pb-4">Accounting account</h3>
          <Select
            name="account"
            options={accounts}
            placeholder={"Account"}
            getOptionLabel={(opt) => `${opt.code} - ${opt.name}`}
            getOptionValue={(opt) => opt}
            menuPlacement="top"
            isClearable={true}
            value={defaults.account}
            onChange={(val) => {
              setDefaults({ ...defaults, account: val, taxRate: val.taxRate })
            }}
          />
        </div>
        {provider.taxPerLine && (
          <div className="w-full md:w-1/3">
            <h3 className="text-primaryBlue pb-4">Tax Rate</h3>
            <Select
              name="taxRate"
              options={taxRates}
              placeholder={"Tax Rate"}
              getOptionLabel={provider.taxOptionLabelRenderFn}
              getOptionValue={(opt) => opt}
              menuPlacement="top"
              isClearable={true}
              value={defaults.taxRate}
              onChange={(val) => {
                setDefaults({ ...defaults, taxRate: val })
              }}
            />
          </div>
        )}

        {provider.categoryPerLine && (
          <div className="w-full md:w-1/3">
            <h3 className="text-primaryBlue pb-4">Location</h3>
            <Select
              name="category"
              options={trackingCategories}
              placeholder={provider.categoryLabel + " (optional)"}
              getOptionLabel={provider.categoryOptionLabelRenderFn}
              getOptionValue={(opt) => opt}
              menuPlacement="top"
              isClearable={true}
              value={defaults.trackingCategory}
              onChange={(val) => {
                setDefaults({ ...defaults, trackingCategory: val })
              }}
            />
          </div>
        )}
      </div>
      <div className="w-full mt-8">
        <button
          className="button button--primaryBlue"
          onClick={() => setAccountingDefaults(defaults)}
        >
          Apply changes
        </button>
      </div>
    </div>
  )
}

export default AccountingBulkChanges
