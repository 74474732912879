// import { Link } from "gatsby"
import React from "react"
import * as styles from "./OptionsPopup.module.css"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

type OptionsPopupProps = {
  active: boolean
  title?: string | React.ReactNode
  children: any
  activeCallback: Function
  fullHeight?: boolean
  renderInactive?: boolean
  overflowVisible?: boolean
  headerClasses?: string
}

const OptionsPopup = ({
  children,
  active,
  activeCallback,
  title = "",
  fullHeight = false,
  renderInactive = false,
  overflowVisible = false,
  headerClasses = "",
}: OptionsPopupProps) => {
  return (
    <div
      className={cx("container", {
        display: active,
        overflowVisible: overflowVisible,
      })}
    >
      <button className={styles.bg} onClick={() => activeCallback()} />
      <div
        className={cx(styles.content, {
          [styles.contentFullHeight]: fullHeight,
        })}
      >
        {title && (
          <div className={`${styles.header} ${headerClasses}`}>{title}</div>
        )}
        {(renderInactive || active) && <>{children}</>}
      </div>
    </div>
  )
}

export default OptionsPopup
